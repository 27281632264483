<template>
    <div class="clients-page__clients sort-section mb-5 mb-lg-6">
        <div class="clients-page__clients-list sort-section__content_1">
            <div class=" sort-section__tabs mb-3 mb-lg-6">
                <button class="sort-section__tab " :class="{'active' : activeTag == ''}" @click="setActiveTag('')"
                        type="button">{{all}}
                </button>

                <button class="sort-section__tab" v-for="tag in tags" :key="tag.id" type="button"
                        @click="setActiveTag(tag)" :class="{'active' : activeTag == tag.name}">{{tag.name}}
                </button>

                <div class="clients-page__reviews-btn">
                    <button class=" cta-btn cta-btn--red" @click="setReview()" :class="{'active' : review}">
                        {{btn_filter_review}}
                        <span class="icon-message"></span>
                    </button>
                </div>

            </div>
            <isotope ref="cpt" id="root_isotope" class="isoDefault" :list="result.data" :options='getOption()'
                     @filter="filterOption=arguments[0]" @sort="sortOption=arguments[0]">

                <a v-for="(element, index) in result.data" @click="selected=element" :key="index" :href="element.url"
                   class="client-card sort-section__card seo-client">
                    <span class="client-card__inner">
                        <img style="max-width: 60%" v-lazy="element.image" alt="" class="client-card__img lazy-load">
                        <span class="client-card__bottom">
                            <span class="client-card__name">
                                {{element.name}}
                            </span>
                            <span class="client-card__hashtags" >
                                <button type="button" v-for="t in element.tags" :key="t.id" class="red-dark text-uppercase"
                                        @click.prevent="setActiveTag(t.name)">#{{t.name}}
                                </button>
                            </span>
                        </span>
                    </span>
                </a>
            </isotope>
        </div>
    </div>
</template>

<script>
    var count = 0;

    import Vue from 'vue'
    import isotope from 'vueisotope'

    export default {
        props: [
            'all', 'btn_filter_review'
        ],
        components: {
            isotope
        },
        data() {
            return {
                review: false,
                tags: [],
                activeTag: '',
                result: {
                    data: [],
                    total: 0,
                    last_page: 0,
                    current_page: 0,
                    loading: false
                },
                selected: null,
                sortOption: null,
                filterOption: null,
                filterText: ""
            }
        },
        watch: {
            activeTag() {
                Object.assign(this.$data.result, this.$options.data().result)
                this.loadData();
            },
            review() {
                Object.assign(this.$data.result, this.$options.data().result)
                this.loadData();
            }
        },
        created() {
            axios.get('/clients/get-tags')
                .then(({data}) => this.tags = data.data)
                .then(() => this.loadData())

            bus.$on('clickBntMore', () => {
                this.loadData()
            });
        },

        methods: {
            setReview() {
                this.review = !this.review;
            },
            setActiveTag(tag) {
                this.activeTag = tag.name || tag;
            },
            loadData() {
                if (this.result.loading) return;

                this.result.loading = true;

                let nextPage = this.result.current_page + 1
                if (this.result.last_page && nextPage > this.result.last_page) {
                    return
                }

                axios.get('/clients/list',
                    {
                        params: {
                            page: nextPage,
                            review: this.review,
                            tag: this.activeTag
                        }
                    }
                )
                    .then(({data}) => {

                        this.result.loading = false;
                        this.result.data = (this.result.current_page) ? this.result.data.concat(data.data) : data.data
                        this.result.total = data.meta.total;
                        this.result.last_page = data.meta.last_page;
                        this.result.current_page = data.meta.current_page;

                        if (data.meta.last_page == data.meta.current_page) {
                            bus.$emit('hideBtnMore');
                        }

                        if (data.meta.last_page > data.meta.current_page) {
                            bus.$emit('showBtnMore');
                        }

                        /*setTimeout(() => {
                            this.$refs.cpt.shuffle()
                        }, 100);*/


                    }).catch(() => {
                    this.result.loading = false;
                })
            },
            getOption: function () {
                var _this = this;
                return {
                    masonry: {
                        //gutter: 10
                    },
                    getSortData: {
                        id: "id",
                        name: function (itemElem) {
                            return itemElem.name.toLowerCase();
                        }
                    },
                    getFilterData: {
                        isEven: function (itemElem) {
                            return itemElem.id % 2 === 0;
                        },
                        isOdd: function (itemElem) {
                            return itemElem.id % 2 !== 0;
                        },
                        filterByText: function (itemElem) {
                            return itemElem.name.toLowerCase().includes(_this.filterText.toLowerCase());
                        }
                    }
                }
            },
            add: function () {
                this.list.push({name: 'Juan', id: count++});
            },
            replace: function () {
                this.list = [{name: 'Edgard', id: count++}, {name: 'James', id: count++}]
            },
            sort: function (key) {
                this.isotopeSort(key);
                this.sortOption = key;
            },
            filter: function (key) {
                if (this.filterOption == key)
                    key = null;
                this.isotopeFilter(key);
                this.filterOption = key;
            },
            shuttle: function () {
                this.isotopeShuttle();
                this.sortOption = null;
            }
        }
    }
</script>

<style scoped>

</style>
