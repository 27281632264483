import UserList from '../../admin/pages/Users/List'
import UserForm from '../../admin/pages/Users/UserEdit'
export default  [
    {
        path: 'users',
        name: 'users',
        component: UserList
    },
    {
        path: 'users/create',
        name: 'user-create',
        component: UserForm
    },
    {
        path: 'users/:id/edit',
        name: 'users-edit',
        component: UserForm,
        props: true
    }
]
