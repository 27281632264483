
import Login from './../../pages/Auth/Login'
import ForgetPassword from '../../pages/Auth/ForgetPassword'
import RestorePassword from '../../pages/Auth/ResetPassword'
import EmailActiveted from '../../pages/Auth/EmailActiveted'

export default [
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'password-forget',
        path: '/password-forget',
        component: ForgetPassword
    },
    {
        name: 'password-restore',
        path: '/password-restore',
        component: RestorePassword
    }, {
        name: 'register-activation',
        path: '/register-activation',
        component: EmailActiveted
    },
]
