<template>
    <div class="content">
        <button v-on:click="generateSiteMap($event)">Переформировать Sitemap</button>
        <br>
        <br>
        <br>
        <span id="mess" style="display:none;">Ждите ...</span>
    </div>
</template>


<script>

    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        methods: {
            generateSiteMap: function (e) {
                var mess = document.querySelector('#mess');
                // addBtnLoading(e);
                mess.style.display = 'block';
                axios.post('admin/generate-sitemap', {})
                    .then(({data}) => {
                        console.log(data);
                        // removeBtnLoading(e);
                        mess.style.display = 'none';
                        window.notification.success('Формирование прошло успешно');
                    })
                    .catch(error => {
                        console.log(error.response);
                        // removeBtnLoading(e);
                        mess.style.display = 'none';
                        window.notification.error('Ошибка');
                    });
            }
        }
    }
</script>


<style>

</style>
