<template>
    <div class="content">
        <div class="container-fluid" v-if="!startLoading">
            <div class="row">
                <div class="col-8">
                    <div>
                        <b-tabs>
                            <b-tab :title-item-class="getClassForTitleTab(language.id)" v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <post-description card-title="Информация о клиенте" :enablePartPost="[]"
                                                  :description="getPropsByKey(language.id)" :lang_id="language.id"
                                                  @updateData="setDescriptionData(language.id,$event)">
                                    <template v-slot:meta_info="slotProps">
                                        <type-business :lang_id="language.id"
                                                       :relation-meta="slotProps.description.meta"
                                                       @updateMeta="slotProps.method($event)"></type-business>
                                        <client-information :lang_id="language.id"
                                                            :relation-meta="slotProps.description.meta"
                                                            @updateMeta="slotProps.method($event)"></client-information>
                                        <completed-work :lang_id="language.id"
                                                        :relation-meta="slotProps.description.meta"
                                                        @updateMeta="slotProps.method($event)"></completed-work>
                                        <services-prover :lang_id="language.id"
                                                         :relation-meta="slotProps.description.meta"
                                                         @updateMeta="slotProps.method($event)"></services-prover>
                                        <review :lang_id="language.id" :relation-meta="slotProps.description.meta"
                                                @updateMeta="slotProps.method($event)"></review>
                                    </template>
                                </post-description>
                            </b-tab>
                        </b-tabs>
                    </div>

                </div>
                <div class="col-4">
                    <post-data :date-pub="false" :editData="postForEditing" @updateData="setGeneralData($event)"
                               @publish="publish">
                    </post-data>
                    <relation-tag :relation-tags="postForEditing.tags || []" @setTags="setTags($event)"></relation-tag>
                    <full-case :relation-meta="postForEditing.meta" @updateMeta="updateMeta($event)"></full-case>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import PostData from './../Post/PostData'
    import RelationTag from './../Post/Relation/Taggable'
    import PostDescription from './../Post/PostDescription'
    import ClientInformation from './ClientInformation'
    import CompletedWork from './CompletedWork'
    import ServicesProver from './ServicesProver'
    import TypeBusiness from './TypeBusiness'
    import Review from './Reviews'
    import FullCase from './FullCase'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        props: ['id'],
        components: {
            PostData,
            PostDescription,
            RelationTag,
            ClientInformation,
            CompletedWork,
            ServicesProver,
            Review,
            TypeBusiness,
            FullCase
        },
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: 'admin/case/'
                },
                startLoading: true,
                languages: [],

                postForEditing: {},
                post: {
                    meta: {},
                    type: 'client',
                    relations: {
                        tags: null
                    },
                    post_description: {}
                }
            }
        },
        async beforeCreate() {
            await axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                })
                .then(() => {
                    if (this.id > 0) {
                        axios.get(`admin/case/${this.id}`)
                            .then(({data}) => {
                                    this.$set(this.$data, 'postForEditing', data.data);
                                    this.startLoading = false;
                                    this.paramsSave.method = 'put'
                                    this.paramsSave.url = this.paramsSave.url + this.id
                                }
                            )
                    } else {
                        this.startLoading = false;
                    }
                })
        },
        computed: {
            errors() {
                return this.$store.getters['errors/getErrors'];
            }
        }
        ,
        methods: {
            updateMeta(e) {
                this.post.meta[e.key] = e.value;
            },
            setTags(tags) {
                this.post.relations.tags = tags;
            },
            getPropsByKey(key) {
                if (this.postForEditing && this.postForEditing.hasOwnProperty('description')) {
                    let description = _.find(this.postForEditing.description, (o) => o.language_id == key);
                    return description ? description : {}
                }
                return {}
            }
            ,
            getClassForTitleTab(index) {
                var hasLangError = false;
                for (let cur in this.errors) {
                    if (cur.indexOf(`post_description.${index}`) !== -1) {
                        hasLangError = true;
                        break;
                    }
                }

                if (hasLangError) {
                    return 'has-error'
                }

                return '';
            }
            ,
            publish(e) {
                axios[this.paramsSave.method](this.paramsSave.url, {
                    ...this.post
                }).then(({data}) => {

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message
                        window.notification.error(this.errorMessage);

                    });
            }
            ,
            setDescriptionData(language, data) {
                this.post.post_description[language] = data;

            }
            ,
            setGeneralData($event) {
                for (let property in $event) {
                    this.post[property] = $event[property]
                }
            }
        }
        ,
        watch: {}
    }
</script>


<style>
    .tab-pane {
        outline: none;
    }

    .has-error {
        background: #dc3545;
    }

    .has-error a {
        color: #ffffff;
    }
</style>
