<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Как все начиналось?</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок</label>
                            <b-form-input
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст</label>
                            <b-form-textarea
                                    v-model="text"
                                    :state="localErrors('text').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <label for="">Изображение</label>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('image')">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('image').length > 0 }"
                               class="form-control" v-model="image">
                    </div>
                </div>
                <div class="col-md-12" v-if="image">
                    <img style="max-width: 100%" class="mt-3"  :src="image"/>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Подпись фото</label>
                            <b-form-input
                                    v-model="inscript"
                                    :state="localErrors('inscript').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('inscript'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>



        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var first_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'first_screen')).meta_value || '';

            this.heading = first_screen.heading || '';
            this.text = first_screen.text || '';
            this.image = first_screen.image || '';
            this.inscript = first_screen.inscript
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading:'',
                text: '',
                image : '',
                inscript : ''
            }
        },
        methods: {
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl =  (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`first_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'first_screen',
                    value: {
                        heading: this.heading,
                        text: this.text,
                        image: this.image,
                        inscript : this.inscript
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
