<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-md-12" style="text-align: right">
                    <button class="btn btn-sm btn-fill  btn-danger" v-if="getUserID && !isAdmin"
                            v-confirm="{ok: remove, message: 'Are you sure you want to delete the user? The process is irreversible'}">
                        Remove
                    </button>
                    <button class="btn btn-sm btn-fill btn-success" @click="save">Save</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <user-form></user-form>
                </div>
                <div class="col-md-4">
                    <user-password></user-password>
                </div>
                <div class="col-md-4">
                    <user-access></user-access>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <b-tabs>
                            <b-tab v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <profile :lang_id="language.id"></profile>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import UserForm from './Form'
    import UserPassword from './Password'
    import Profile from './Profile'
    import UserAccess from './Access'
    import Card from './../../../components/Cards/Card.vue'
    import ErrorMixin from '../../mixim/UserMixin'
    import {mapGetters} from 'vuex'

    export default {
        mixins: [ErrorMixin],
        data() {
            return {
                languages: [],
                isAdmin: false
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCompany']
            },
        },
        components: {
            UserForm, UserAccess, UserPassword, Card,Profile
        },
        watch: {
            '$route'(to, from) {
                if (to.name == 'profile') {
                    this.loadHimself();
                }
            }
        },
        methods: {
            loadHimself() {
                this.isAdmin = true;
                this.$store.dispatch('user/loadUser', {
                    id: this.$store.getters['auth/loggedIn'].data.id
                })
                    .then(() => {
                        window.willBeChanges = false;
                    })
            }
        },
        created() {

            axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                });
            this.isAdmin = false;
            this.$store.dispatch('roles/loadRoles')
            this.$store.commit('user/resetAll');
            if (this.$route.params.id !== undefined && parseInt(this.$route.params.id) > 0) {
                this.$store.dispatch('user/loadUser', {
                    id: this.$route.params.id
                })
            } else if (this.$route.name == 'profile') {
                this.loadHimself()
            }
            window.willBeChanges = false;
            this.$store.subscribe((mutation, state) => {
                window.willBeChanges = true;
            })

        }

    }

</script>
<style>

</style>
