<template>
    <card>
        <h5 slot="header" class="card-title">SEO</h5>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Seo title:</label>
                    <b-form-input
                        id="quotes"
                        aria-describedby="input-quotes-help input-live-quotes"
                        placeholder=""
                        v-model="title"
                        trim
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Seo Headline:</label>
                    <b-form-input
                        id="quotes"
                        aria-describedby="input-quotes-help input-live-quotes"
                        placeholder=""
                        v-model="headline"
                        trim
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Seo Description:</label>
                    <b-form-textarea
                        id="textarea-state"
                        v-model="description"
                        :state="1"
                        placeholder="Enter at least 10 characters"
                        rows="3"
                    ></b-form-textarea>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Seo KeyWords:</label>
                    <b-form-textarea
                        id="textarea-state"
                        v-model="keywords"
                        :state="1"
                        placeholder="Enter at least 10 characters"
                        rows="3"
                    ></b-form-textarea>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Seo Text:</label>
                <quill-editor style="min-height:180px;" v-model="text"
                              ref="myQuillEditor"
                              :options="editorOption">
                </quill-editor>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Текст в head</label>
                    <b-form-textarea
                            id="textarea-state"
                            v-model="seo_head"
                            :state="1"
                            placeholder="Enter at least 10 characters"
                            rows="3"
                    ></b-form-textarea>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>
    </card>
</template>
<script>
    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'

    export default {
        mixins: [],
        components: {
            quillEditor
        },
        props: {
            relationSeo : {
                type : [Object]
            }
        },
        created(){
            this.title  = this.relationSeo.title || null;
            this.headline  = this.relationSeo.headline || null;
            this.text  = this.relationSeo.text || null;
            this.keywords  = this.relationSeo.keywords || null;
            this.description  = this.relationSeo.description || null;
            this.seo_head  = this.relationSeo.seo_head || null;
        },
        watch : {
          '$data' : {
              handler(value){
                this.passToParrentData(value);
              },
              deep : true
          }
        },
        methods:{
            passToParrentData(value) {
                let pass = {};
                for (var cur in value) {
                    if (this.pass.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                this.$emit('updateSeo', pass)
            },
        },
        data() {
            return {
                title : '',
                headline : '',
                description : '',
                text : '',
                keywords : '',
                seo_head : '',
                pass : ['title','headline','description','text','keywords','seo_head'],
                editorOption: {
                    height : '500px',
                },
            }
        }
    }
</script>

<style scoped>

</style>
