<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <router-link :to="{path : '/admin'}" class="navbar-brand">Dashboard</router-link>
            <button type="button" v-if="!is_company"
                    class="navbar-toggler navbar-toggler-right"
                    :class="{toggled: $sidebar.showSidebar}"
                    aria-controls="navigation-index"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    @click="toggleSidebar">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">
                <div class="dropdown dropleft" v-if="is_company">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       Menu
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <router-link :to="{name : 'company-meta'}" class="dropdown-item">Basic data</router-link>
                        <router-link :to="{name : 'meta-data'}" class="dropdown-item">Meta data</router-link>
                        <router-link :to="{name : 'company-owners'}" class="dropdown-item">Administrators</router-link>
                        <a style="cursor:pointer" @click="logout()" class="nav-link">
                            Log out
                        </a>
                    </div>
                </div>
                <ul class="navbar-nav ml-auto" v-else>
                    <li class="nav-item">
                        <a @click="logout()" class="nav-link">
                            Log out
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
    export default {
        props:{
            is_company : {
                type : Boolean,
                default : false
            }
        },
        computed: {
            routeName() {
                const {name} = this.$route
                return this.capitalizeFirstLetter(name)
            }
        },
        data() {
            return {
                activeNotifications: false
            }
        },
        methods: {
            logout() {
                    auth.logout();
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            },
            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications
            },
            closeDropDown() {
                this.activeNotifications = false
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false)
            }
        }
    }

</script>
<style>

</style>
