import { render, staticRenderFns } from "./SecondText.vue?vue&type=template&id=5be1a569&scoped=true&"
import script from "./SecondText.vue?vue&type=script&lang=js&"
export * from "./SecondText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be1a569",
  null
  
)

export default component.exports