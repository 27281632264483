<template>
    <div>
        <card v-if="enableTitle">
            <h5 slot="header" class="card-title">{{cardTitle}}</h5>
            <form>
                <div class="row"  v-if="enableTitle">
                    <div class="col-md-12">
                        <base-input type="text"
                                    label="Название"
                                    :inputClass="errors('title').length ? 'is-invalid' : ''"
                                    placeholder="Введите название" v-model="title">
                            <template v-slot:error_message>
                                <div class="invalid-feedback" v-if="errors('title').length">
                                    {{_.head(errors('title'))}}
                                </div>
                            </template>
                        </base-input>
                    </div>
                </div>
                <div class="row" v-if="enableSlug">
                    <div class="col-md-12">
                        <base-input type="text"
                                    label="Slug"
                                    :inputClass="errors('slug').length ? 'is-invalid' : ''"
                                    placeholder="Введите slug" v-model="slug">
                            <template v-slot:error_message>
                                <div class="invalid-feedback" v-if="errors('slug').length">
                                    {{_.head(errors('slug'))}}
                                </div>
                            </template>
                        </base-input>


                    </div>
                </div>
                <div class="clearfix"></div>
            </form>
        </card>
        <div class="div">
            <post-banner v-if="canShotPartBlog('banner')" :relation-meta="description.meta || {}" @updateMeta="updateMeta($event)"></post-banner>
        </div>
        <div class="div">
            <excerpt  v-if="canShotPartBlog('excerpt')"  :relation-meta="description.meta || {}" @updateMeta="updateMeta($event)"></excerpt>
        </div>
        <div class="div">
            <post-part   v-if="canShotPartBlog('post_part')"  :params-edit="description.hasOwnProperty('text') ? description.text : []" :lang_id="lang_id"
                       @newParts="setParts($event)"></post-part>
        </div>
        <slot name="meta_info" v-bind:description="description" v-bind:method="updateMeta">

        </slot>
        <div class="div" v-if="enableSeo">
            <seo-block :relation-seo="description.seo || {}" @updateSeo="updateSeo($event)"></seo-block>
        </div>
        <div class="div">
            <meta-information  v-if="canShotPartBlog('meta-information')"   :relation-meta="description.meta" @updateMeta="updateMeta($event)"></meta-information>
        </div>

        <links-block v-if="isshow" :uri="'services.' + slug"></links-block>

    </div>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import PostPart from './PostDescriptionPart'
    import SeoBlock from './../../components/SeoBlock'
    import MetaInformation from './Relation/MetaInformation'
    import PostBanner from './PostBanner'
    import Excerpt from './Relation/Excerpt'

    import LinksBlock from '../../components/LinksBlock'

    export default {
        props: {
            isshow :{
                type : Boolean
            },
            cardTitle : {
              type : String,
              default : 'Информация о кейсе'
            },
            enablePartPost: {
                type: Array,
                default: ['banner', 'excerpt', 'post_part', 'meta-information'],
            },
            description: {
                type: Object,
                default: {}
            },
            lang_id: {
                type: [String, Number],
                default: 0
            },
            enableSlug : {
                type : Boolean,
                default : true
            },
            enableSeo : {
                type : Boolean,
                default : true
            },
            enableTitle : {
                type : Boolean,
                default : true
            },
            defaultSlug : {
                type : String,
                default : ''
            }
        },
        components: {
            Card, PostPart, SeoBlock, MetaInformation, PostBanner, Excerpt, LinksBlock
        },
        created() {
            this.setData()
            this.slug = this.defaultSlug != "" ? this.defaultSlug : this.slug
        },
        async mounted() {

        },
        computed: {},
        watch: {
            '$data': {
                handler: function (value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                // enabled_translate:false,
                title: '',
                slug: '',
                parts: [],
                seo: [],
                meta: {},
                passible: ['title', 'slug', 'parts', 'seo', 'meta']
            }
        },
        methods: {
            canShotPartBlog(part) {
                return this.enablePartPost.indexOf(part) !== -1;
            },
            updateSeo(e) {
                this.seo = e;
            },
            updateMeta(e) {
                this.meta[e.key] = e.value;
            },
            setData() {
                // console.log(this.description);
                this.title = this.description.hasOwnProperty('title') ? this.description.title : '';
                this.slug = this.description.hasOwnProperty('slug') ? this.description.slug : '';
                // this.enabled_translate = this.description.hasOwnProperty('enabled_translate');
                this.passToParrentData(this.$data);
            },
            errors(key) {
                let error = this.$store.getters['errors/getError'](`post_description.${this.lang_id}.${key}`);

                if (Array.isArray(error))
                    return error;
                return [];
            },
            passToParrentData(value) {
                let pass = {};
                for (var cur in value) {
                    if (this.passible.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                this.$emit('updateData', pass)
            },
            setParts(parts) {
                this.parts = parts;
            }
        }
    }

</script>
<style>

</style>
