<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <base-input type="search"
                                placeholder="Search" v-model="query">
                    </base-input>
                </div>
                <div class="col-md-8">
                    <nav aria-label="..." class="d-flex justify-content-end">
                        <app-pagination v-model="page"
                                        :page-count="result.last_page"
                        ></app-pagination>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <card class="strpied-tabled-with-hover"
                          body-classes="table-full-width table-responsive"
                    >
                        <template slot="header">
                            <div class="text-center">
                                <router-link :to="{name : 'vacancy-category-create'}"
                                             class="btn btn-info btn-sm btn-fill float-right"> Создать
                                </router-link>
                            </div>
                            <h4 class="card-title">Список Категорий</h4>
                            <p class="card-category">
                                В этой таблице вы можете увидеть список всех категорий.</p>
                        </template>

                        <app-preloader-table v-if="loading"></app-preloader-table>
                        <table class="table table-hover table-striped" v-else>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Дата создания</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in result.data" :key="index">
                                <td>{{item.id}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.created_at}}</td>
                                <td>
                                    <router-link  :to="{name : 'vacancy-category-edit', params : {id : item.id}}"  class="btn-sm btn-fill btn btn-warning ml-2"><i class="fa fa-pencil-square-o"></i></router-link>
                                    <buttom @click.prevent="showDialog(item.id)" class="btn-sm btn-fill btn btn-danger ml-2">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </buttom>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </card>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'
    import _ from 'lodash'



    export default {
        components: {
            LTable,
            Card
        },
        created() {
            this.getData();
        },
        watch: {
            page(p) {
                if(p == 0)  {
                    this.page = 1; return;
                }
                if (this.loading) return;
                if (this.page == 0)
                    this.page = 1;

                this.getData();
            },
            query() {
                this.page = 1;
                if (this.loading) return;
                this.loadData();
            }
        },
        data() {
            return {
                query: '',
                page: 1,
                loading: false,
                result: {
                    data : [],
                    total: 0,
                    last_page: 0,
                    current_page: 0
                },

            }
        },
        methods: {

            async remove(id){
                await axios.delete(`admin/vacancy/category/${id}`)
                    .then(() => {
                        this.getData()
                    })
            },
            showDialog(id){
                this.$dialog.confirm('Вы уверены, что хотить безвозвратно удалить ?',{
                    okText: 'Да',
                    cancelText: 'Отмена',
                })
                    .then((dialog) => {
                        dialog.close && dialog.close()
                        this.remove(id);
                    })
            },
            getData(){
                if(this.loading) return;

                this.loading = true;

                let data = {
                    query: this.query,
                    page: this.page
                };

                axios.get('admin/vacancy/category',
                    {
                        params: {
                            ...data
                        }
                    }
                )
                    .then(({data}) => {
                        this.loading = false;
                        this.result.data = data.data;
                        this.result.total = data.meta.total
                        this.result.last_page = data.meta.last_page
                        this.result.current_page = data.meta.current_page
                    }).catch(() => {
                    this.loading = false;
                })


            },
            loadData() {
                this.debounce(() => {
                    this.getData();
                });
            },
            debounce: _.debounce((cb) => {
                cb()
            }, 600),
        }
    }
</script>
<style>
</style>
