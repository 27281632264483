<template>
    <div class="content">
        <div class="container-fluid" v-if="!startLoading">
            <div class="row">
                <div class="col-8">
                    <div>
                        <b-tabs>
                            <b-tab :title-item-class="getClassForTitleTab(language.id)" v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <post-description :description="getPropsByKey(language.id)" :lang_id="language.id"
                                                  @updateData="setDescriptionData(language.id,$event)"></post-description>
                            </b-tab>
                        </b-tabs>
                    </div>

                </div>
                <div class="col-4">
                    <post-data :editData="postForEditing" @updateData="setGeneralData($event)"
                               @publish="publish"></post-data>
                    <relation-author  :relation-author="postForEditing.author || {}" @setAuthor="setAuthor($event)"></relation-author>
                    <relation-category :relation-categories="postForEditing.categories || []" @setCategories="setCategories($event)"></relation-category>
                    <relation-tag :relation-tags="postForEditing.tags || []" @setTags="setTags($event)"></relation-tag>
                    <post-relation :relation-meta="postForEditing.meta || []" @updateMeta="updateMeta($event)"></post-relation>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import PostData from './PostData'
    import RelationCategory from './Relation/Category'
    import RelationTag from './Relation/Taggable'
    import RelationAuthor from './Relation/Author'
    import PostRelation from './Relation/PostRelation'
    import PostDescription from './PostDescription'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        props: ['id'],
        components: {
            PostData, PostDescription,RelationCategory,RelationTag,RelationAuthor,PostRelation
        },
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: 'admin/case/'
                },
                startLoading: true,
                languages: [],

                postForEditing: {},
                post: {
                    relations : {
                        author : null,
                        categories : null,
                        tags : null
                    },
                    meta : {

                    },
                    post_description: {}
                }
            }
        },
        async beforeCreate() {
            await axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                })
                .then(() => {
                    if (this.id > 0) {
                        axios.get(`admin/case/${this.id}`)
                            .then(({data}) => {
                                console.log("TESTTESTTESTTESTTESTTESTTESTTEST");
                                console.log(data);
                                    this.$set(this.$data, 'postForEditing', data.data);
                                    this.startLoading = false;
                                    this.paramsSave.method = 'put'
                                    this.paramsSave.url = this.paramsSave.url + this.id
                                }
                            )
                    } else {
                        this.startLoading = false;
                    }
                })
        },
        computed: {
            errors() {
                return this.$store.getters['errors/getErrors'];
            }
        }
        ,
        methods: {
            updateMeta(e) {
                this.post.meta[e.key] = e.value;
            },
            setAuthor(author){
              this.post.relations.author = author;
            },
            setCategories(categories){
                this.post.relations.categories = categories;
            },
            setTags(tags){
                this.post.relations.tags = tags;
            },
            getPropsByKey(key) {
                if (this.postForEditing && this.postForEditing.hasOwnProperty('description')) {
                    let description = _.find(this.postForEditing.description, (o) => o.language_id == key);
                    return description ? description : {}
                }
                return {}
            }
            ,
            getClassForTitleTab(index) {
                var hasLangError = false;
                for (let cur in this.errors) {
                    if (cur.indexOf(`post_description.${index}`) !== -1) {
                        hasLangError = true;
                        break;
                    }
                }

                if (hasLangError) {
                    return 'has-error'
                }

                return '';
            }
            ,
            publish(e) {
                console.log(this.post);
                axios[this.paramsSave.method](this.paramsSave.url, {
                    ...this.post
                }).then(({data}) => {
                    if(data.id){
                       this.$router.push({name : 'cases-edit',params : {id : data.id}})
                    }
                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message
                        window.notification.error(this.errorMessage);

                    });
            }
            ,
            setDescriptionData(language, data) {
                this.post.post_description[language] = data;

            }
            ,
            setGeneralData($event) {
                console.log('dfsfsdfdsff23r23trf424tgf3');
                console.log($event);
                for (let property in $event) {
                    this.post[property] = $event[property]
                }
            }
        }
        ,
        watch: {}
    }
</script>


<style>
    .tab-pane {
        outline: none;
    }

    .has-error {
        background: #dc3545;
    }

    .has-error a {
        color: #ffffff;
    }
</style>
