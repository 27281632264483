<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Шестой экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Digital Transformation?</label>
                            <b-form-input
                                    v-model="digital_transformation"
                                    :state="localErrors('digital_transformation').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('digital_transformation'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>



                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>СДЕЛАЙ СВОЮ ТРАНСФОРМАЦИЮ</label>
                            <b-form-input
                                    v-model="make_your_transormation"
                                    :state="localErrors('make_your_transormation').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('make_your_transormation'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что такое Digital
                                Transformation? (Заголовок)</label>
                            <b-form-input
                                    v-model="what_digital_transformation_head"
                                    :state="localErrors('what_digital_transformation_head').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_digital_transformation_head'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что такое Digital
                                Transformation? (Текст)</label>
                            <b-form-textarea
                                    v-model="what_digital_transformation_text"
                                    :state="localErrors('what_digital_transformation_text').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_digital_transformation_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>



                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что не есть Digital
                                Transformation? (Заголовок)</label>
                            <b-form-input
                                    v-model="what_not_digital_transformation_head"
                                    :state="localErrors('what_not_digital_transformation_head').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_not_digital_transformation_head'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что не есть Digital
                                Transformation? (Текст) (каждый элемент с новой строки)</label>
                            <b-form-textarea
                                    v-model="what_not_digital_transformation_text"
                                    :state="localErrors('what_not_digital_transformation_text').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_not_digital_transformation_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>ЭТО НЕ ФРАГМЕНТАРНЫЕ ИНСТРУМЕНТЫ!</label>
                            <b-form-input
                                    v-model="this_is_not_a_fragmental_tools"
                                    :state="localErrors('this_is_not_a_fragmental_tools').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('this_is_not_a_fragmental_tools'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>



        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var six_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'six_screen')).meta_value || '';

            this.digital_transformation = six_screen.digital_transformation || '';
            this.make_your_transormation = six_screen.make_your_transormation || '';
            this.what_digital_transformation_head = six_screen.what_digital_transformation_head || '';
            this.what_digital_transformation_text = six_screen.what_digital_transformation_text || '';
            this.what_not_digital_transformation_head = six_screen.what_not_digital_transformation_head || '';
            this.what_not_digital_transformation_text = six_screen.what_not_digital_transformation_text || '';
            this.this_is_not_a_fragmental_tools = six_screen.this_is_not_a_fragmental_tools || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                digital_transformation:'',
                make_your_transormation: '',
                what_digital_transformation_head : '',
                what_digital_transformation_text : '',
                what_not_digital_transformation_head : '',
                what_not_digital_transformation_text : '',
                this_is_not_a_fragmental_tools : ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`six_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'six_screen',
                    value: {
                        digital_transformation:this.digital_transformation,
                        make_your_transormation: this.make_your_transormation,
                        what_digital_transformation_head : this.what_digital_transformation_head,
                        what_digital_transformation_text : this.what_digital_transformation_text,
                        what_not_digital_transformation_head : this.what_not_digital_transformation_head,
                        what_not_digital_transformation_text : this.what_not_digital_transformation_text,
                        this_is_not_a_fragmental_tools : this.this_is_not_a_fragmental_tools
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
