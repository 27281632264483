<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Третий экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Время делит компании на два типа (заголовок)</label>
                            <b-form-input
                                    v-model="time_divides_the_company_heading"
                                    :state="localErrors('time_divides_the_company_heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('time_divides_the_company_heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Время делит компании на два типа</label>
                            <b-form-input
                                    :state="localErrors('time_divides_the_company').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                                    v-model="time_divides_the_company"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('time_divides_the_company'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Компания старого типа (каждый пункт с новой строки)</label>
                            <b-form-textarea
                                    v-model="old_type_company"
                                    :state="localErrors('old_type_company').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('old_type_company'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('old_type_company_image')">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('old_type_company_image').length > 0 }"
                               class="form-control" v-model="old_type_company_image">
                    </div>
                </div>
                <div class="col-md-12" v-if="old_type_company_image">
                    <img style="max-width: 100%" class="mt-3"  :src="old_type_company_image"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Компания будущего (каждый пункт с новой строки)</label>
                            <b-form-textarea
                                    v-model="future_company"
                                    :state="localErrors('future_company').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('future_company'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('future_company_image')">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('future_company_image').length > 0 }"
                               class="form-control" v-model="future_company_image">
                    </div>
                </div>
                <div class="col-md-12" v-if="future_company_image">
                    <img style="max-width: 100%" class="mt-3"  :src="future_company_image"/>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var third_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'third_screen')).meta_value || '';

            this.time_divides_the_company_heading = third_screen.time_divides_the_company_heading || '';
            this.time_divides_the_company = third_screen.time_divides_the_company || '';
            this.old_type_company = third_screen.old_type_company || '';
            this.future_company = third_screen.future_company || '';
            this.old_type_company_image = third_screen.old_type_company_image || '';
            this.future_company_image = third_screen.future_company_image || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                time_divides_the_company_heading: '',
                time_divides_the_company: '',
                old_type_company: '',
                future_company: '',
                old_type_company_image : null,
                future_company_image: null
            }
        },
        methods: {
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl =  (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`third_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'third_screen',
                    value: {
                        time_divides_the_company_heading: this.time_divides_the_company_heading,
                        time_divides_the_company: this.time_divides_the_company,
                        old_type_company: this.old_type_company,
                        future_company: this.future_company,
                        future_company_image : this.future_company_image,
                        old_type_company_image :this.old_type_company_image
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
