<template>
    <div class="comments-section__comments">
<!--        <div class="comment-block">-->
        <div v-for="comment in result.data" :key="comment.id">


        <div class="comment-block" v-if="comment.parent === null">
            <div class="comments-section__author-photo comment-block__author-photo">
                <img class="img-fit img-fit--cover" :src="comment.image"
                     alt="">
            </div>
            <div  class="comment-block__content">
                <div class="d-flex mb-3">
                    <div class="comment-block__author-name">
                        {{comment.userName}}
                    </div>
                    <div class="comment-block__date">
                        {{ moment(comment.created_at).fromNow() }}
                    </div>
                </div>
                <div class="comment-block__comment">
                    <p v-html="comment.text"></p>
                </div>
                <div class="comment-block__action">
                </div>
            </div>
        </div>

            <div class="comment-block" v-if="comment.answer.length > 0" v-for="answer in comment.answer" :key="answer.id" style="margin-left: 70px">
                <div class="comments-section__author-photo comment-block__author-photo">
                    <img class="img-fit img-fit--cover" src="/WITS_logo.png"
                         alt="">

                </div>
                <div  class="comment-block__content">
                    <div class="d-flex mb-3">
                        <div class="comment-block__author-name">
                            {{answer.userName}}, {{answer.role}}
                        </div>
                        <div class="comment-block__date">
                            {{ moment(answer.created_at).fromNow() }}
                        </div>
                    </div>
                    <div class="comment-block__comment">
                        <p v-html="answer.text"></p>
                    </div>
                    <div class="comment-block__action">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Comments",
        props: ['post_id'],
        data() {
            return {
                loading: false,
                page: 0,
                result: {
                    data: [],
                    total: 0,
                    last_page: 0,
                    current_page: 0
                },
            }
        },
        created() {
            this.getData();
            this.onScrollInit();
            bus.$on('newComment-' + this.post_id, (e) => {
                this.result.total++;
                this.result.data.push(e.data);
            })
        },
        methods: {
            moment(date){
                return this.$moment(date);
            },
            getData() {
                // console.log(this.result.data)
                if (this.loading) return;

                this.loading = true;

                let nextPage = this.result.current_page + 1
                if (this.result.last_page && nextPage > this.result.last_page) {
                    return
                }

                axios.get('/comments/' + this.post_id,
                    {
                        params: {
                            page: nextPage
                        }
                    }
                )
                    .then(({data}) => {
                        console.log(data);
                        this.loading = false;
                        this.result.data = (this.result.current_page) ? this.result.data.concat(data.data) : data.data
                        this.result.total = data.meta.total;
                        this.result.last_page = data.meta.last_page;
                        this.result.current_page = data.meta.current_page;

                    }).catch(() => {
                    this.loading = false;
                })
            },
            debounce: _.debounce((cb) => {
                cb()
            }, 600),

            onScrollInit() {
                let vm = this;
                window.onscroll = function () {
                    let el = document.documentElement;
                    let isPage = document.querySelector('.comments-section__comments');
                    if (isPage && (el.scrollTop + el.clientHeight + 500 > el.scrollHeight)) {
                        vm.getData();
                    }
                };
            }
        }
    }
</script>

<style scoped>

</style>