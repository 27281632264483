<template>
    <div class="row">
        <div class="col-md-12">
            <div role="group">
                <label for="position">Ссылка на YouTube:</label>
                <b-form-input
                    id="position"
                    v-model="url"
                    aria-describedby="input-help input-live-position"
                    placeholder="Введите подпись документа"
                    :state="errors('url').length == 0 ? null : false"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('url'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="col-md-12 m-2" style="text-align: center" v-if="link">
            <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-md-12">
            <div role="group">
                <label for="position">Подпись:</label>
                <b-form-input
                    id="position"
                    v-model="inscript"
                    aria-describedby="input-help input-live-position"
                    :state="errors('inscript').length == 0 ? null : false"
                    placeholder="Введите подпись документа"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('inscript'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import watchParams from './mixin/watchParams'

    export default {
        mixins:[watchParams],
        props: {
            keyComponent:{
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        data() {
            return {
                inscript : null,
                url: null,
                link : false,
                validate: {
                    url: ['required','min:1','url'],
                },
                passible : [
                    'link','validate','url','inscript'
                ],
            }
        },
        methods: {
            YouTubeGetID(url) {
                url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : false;
            }
        },
        watch: {
            url() {
              this.link = this.YouTubeGetID(this.url)
            }
        }
    }
</script>

<style scoped>

</style>
