<template>
    <div class="row">
        <div class="col-12 col-md-4 col-lg-3 offset-lg-1 ">
            <div class="blog-filter d-none d-md-flex flex-column align-items-start">
                <button :class="{active : actual != 1}" class="red-dark mb-5 text-uppercase font-weight-bold"
                        @click="setActual" type="button">
                    <span>{{text_freshest}}</span>
                    <span class="ml-4" v-if="count_new_article > 0">{{count_new_article}}</span>
                </button>
                <hr>
                <pre>
        </pre>
                <button @click="resetNews()" class="dark-red text-uppercase font-weight-bold mb-4" type="button">
                    {{text_all_news}}
                </button>
                <button v-for="category_ in categories" :key="category_.id"
                        class="dark-red text-uppercase font-weight-bold mb-4"
                        :class="{active : category_.slug == category}" @click="setCategory(category_.slug)"
                        type="button">
                    {{category_.name}}
                </button>
                <hr>
                <div class="d-flex flex-wrap blog-filter__hashtags">
                    <button v-for="tag_ in tags" :key="tag_.id" type="button" class="red-dark text-uppercase"
                            @click="setTag(tag_.name)" :class="{active : tag_.name == tag}">
                        #{{tag_.name}}
                    </button>
                </div>
                <p class="blog-filter__title mb-3">{{text_subscribe}}</p>
                <div class="blog-filter__form">
                   <app-subscribe></app-subscribe>
                </div>
            </div>

            <div class="blog-filter-mobile">

                <div class="blog-filter-mobile__inner">
                    <div class="blog-filter-mobile__top ">
                        <button :class="{class : actual != 1}"
                                class="red-dark text-uppercase font-weight-bold blog-filter-mobile__new" type="button">
                            <span>{{text_freshest}}</span>
                            <span class="ml-2" v-if="count_new_article > 0">{{count_new_article}}</span>
                        </button>

                        <div class="dropdown">
                            <button class="dropdown__toggle text-uppercase font-weight-bold blog-filter-mobile__filter"
                                    type="button">
                                {{text_filter}}
                                <span class="css-triangle"></span>
                            </button>

                            <div class="dropdown__inner">
                                <div class="dropdown__list">
                                    <button @click="resetNews()"
                                            class="dropdown__item dark-red text-uppercase font-weight-bold mb-4"
                                            type="button">
                                        {{text_all_news}}
                                    </button>

                                    <button v-for="category_ in categories" :key="category_.id"
                                            class="dropdown__item dark-red text-uppercase font-weight-bold mb-4"
                                            :class="{active : category_.slug == category}"
                                            @click="setCategory(category_.slug)" type="button">
                                        {{ category_.name }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap blog-filter__hashtags">
                        <button v-for="tag_ in tags" :key="tag_.id" type="button" class="red-dark text-uppercase"
                                :class="{active : tag_ == tag}" @click="setTag(tag_.name)">#{{tag_.name}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="result.data.length" class="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2 search-result"
             ref="blog_elements">
            <div class="blog-page__articles d-flex flex-column">

                <div class="article-preview" v-for="item in result.data" :key="item.id" ref="article">
                    <p class="article-preview__date">
                        {{item.date_publish}} </p>
                    <a :href="item.url" class="h3 dark-red mb-3 d-block">{{item.title}}
                    </a>
                    <div class="article-preview__hashtags d-flex mb-3">
                        <button type="button" class="red-dark text-uppercase" v-for="(tag,index) in item.tags"
                                :key="index" @click="setTag(tag.name)">#{{tag.name}}
                        </button>
                    </div>
                    <p class="article-preview__excerpt" v-html="item.excerpt"></p>
                    <a :href="item.url" class="article-preview__thumbnail mb-4 d-block">
                        <img v-lazy="item.banner" alt="" class="img-fluid">
                    </a>
                    <div class="d-flex justify-content-between">
                        <div class="article-preview__views">
                            <span class="icon-man"></span>
                            <span>{{ item.count_view }}</span>
                            <span>{{item.text_view}}</span>
                        </div>
                        <div class="article-preview__remain" v-html="item.read_for"></div>
                    </div>
                </div>

            </div>
        </div>


        <div v-else class="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2 search-result search-result--no-result">
            <p style="padding-top: 25%;">Нет статей по заданным параметрам</p>
        </div>

        <div class="col-12 col-md-1 d-none d-md-block">
            <div class="d-flex flex-column align-items-end justify-content-center blog-page__scroll"
                 ref="scrollControls">
                <button class="red-dark blog-page__btn-prev" type="button" v-on:click="prevArticle()">
                    &uarr;
                </button>
                <button class="red-dark blog-page__btn-next" type="button" v-on:click="nextArticle()">
                    &darr;
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import {TweenMax} from 'gsap/TweenMax'
    import URI from 'urijs'
    import AppSubscribe from "./Subscribe";

    export default {
        components : {
            AppSubscribe
        },
        props: [
            'count_new_article',
            'text_freshest',
            'text_all_news',
            'text_subscribe',
            'text_filter',
            'text_count_view'
        ],
        data() {
            return {
                loading: false,
                page: 0,
                result: {
                    data: [],
                    total: 0,
                    last_page: 0,
                    current_page: 0
                },
                articlesOffsets: [],
                articlesCounter: 0,
                offsetTop: 0,
                controlsOffset: {},
                category: null,
                tag: null,
                author: null,
                actual: null,
                categories: [],
                tags: []
            }
        },
        created() {
            let url = new URI(location.href)
            let tag = url.search(true).tag
            let category = url.search(true).category
            let author = url.search(true).author
            let actual = url.search(true).actual
            this.tag = tag || null;
            this.category = category || null;
            this.author = author || null;
            this.actual = actual || null;
            axios.get('/blog/get-categories')
                .then(({data}) => this.categories = data.data)
                .then(() => {
                    axios.get('/blog/get-tags')
                        .then(({data}) => this.tags = data.data);
                })
                .then(() => this.loadData())

        },
        mounted() {


            var articles = $(this).find(".article-preview"),
                $btnPrev = $(this).find(".blog-page__btn-prev"),
                $btnNext = $(this).find(".blog-page__btn-next"),
                counter = 0,
                triggerOffsetTop = $(".blog-page__scroll").offset().top,
                triggerOffsetBottom = triggerOffsetTop + $(".blog-page__scroll").outerHeight();


            this.onScrollInit();
        },
        watch: {
            category() {
                this.setUrlParam('category', this.category)
                this.resetResult();
                this.loadData();
                this.setFilter()
            },
            actual() {
                this.resetResult();
                this.loadData();
                this.setFilter()
            },
            tag() {
                this.setUrlParam('tag', this.tag)
                this.resetResult();
                this.loadData();
                this.setFilter()
            },
            author() {
                this.setUrlParam('author', this.author)
                this.resetResult();
                this.loadData();
                this.setFilter()
            },
        },
        methods: {
            setFilter() {
                this.setUrlParam('category', this.category)
                this.setUrlParam('tag', this.tag)
                this.setUrlParam('author', this.author)
                this.setUrlParam('actual', this.actual)
            },
            setUrlParam(name, val) {
                let url = new URI(location.href)
                if (val) {
                    url.setSearch(name, val)
                } else {
                    url.removeSearch(name)
                }
                history.pushState({}, '', url.href())
            },
            resetNews() {
                this.category = null;
                this.tag = null;
                this.author = null;
                this.actual = null;
            },
            resetResult() {
                this.loading = false;
                this.result = {
                    data: [],
                    total: 0,
                    last_page: 0,
                    current_page: 0
                };
            },
            setCategory(id) {
                this.tag = null;
                this.author = null;
                this.actual = null;
                this.category = id;

            },
            setTag(id) {
                this.category = null;
                this.author = null;
                this.actual = null;
                this.tag = id;

            },
            setActual() {
                this.category = null;
                this.author = null;
                this.actual = 1;
                this.tag = null;
            },
            prevArticle() {
                if (this.articlesCounter === 0) return;
                this.articlesCounter--;

                TweenMax.to(window, 0.7, {
                    scrollTo: {
                        y: this.$refs.article[this.articlesCounter],
                        offsetY: this.offsetTop,
                        ease: Circ.easeOut
                    }
                });
            },
            nextArticle() {
                if (this.articlesCounter === this.$refs.article.length - 1) return;
                this.articlesCounter++;


                TweenMax.to(window, 0.7, {
                    scrollTo: {
                        y: this.$refs.article[this.articlesCounter],
                        offsetY: this.offsetTop,
                        ease: Circ.easeOut
                    }
                });
            },
            getData() {
                if (this.loading) return;

                this.loading = true;

                let nextPage = this.result.current_page + 1
                if (this.result.last_page && nextPage > this.result.last_page) {
                    return
                }

                axios.get('/blog/articles',
                    {
                        params: {
                            page: nextPage,
                            category: this.category,
                            tag: this.tag,
                            author: this.author
                        }
                    }
                )
                    .then(({data}) => {
                        this.loading = false;
                        this.result.data = (this.result.current_page) ? this.result.data.concat(data.data) : data.data
                        this.result.total = data.meta.total;
                        this.result.last_page = data.meta.last_page;
                        this.result.current_page = data.meta.current_page;
                        console.log('-----------------------');
                        console.log(this.result.data);
                        console.log('-----------------------');

                    }).catch(() => {
                    this.loading = false;
                })
            },
            debounce: _.debounce((cb) => {
                cb()
            }, 600),
            loadData() {
                this.getData();

                setTimeout(() => {
                    this.$refs.article.forEach((el, i) => {
                        try {
                            this.articlesOffsets.splice(i, 1, {
                                top: el.getBoundingClientRect().top,
                                bottom: el.getBoundingClientRect().top + el.getBoundingClientRect().height + 50
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    });
                }, 1000);
            },
            onScrollInit() {
                let vm = this;
                let checkTimeout = null;
                window.onscroll = function () {
                    let el = document.documentElement;
                    let isPage = document.querySelector('.search-result');
                    if (isPage && (el.scrollTop + el.clientHeight + 200 > el.scrollHeight)) {
                        vm.loadData();
                    }
                    if (checkTimeout) {
                        clearInterval(checkTimeout);
                    }
                    checkTimeout = setTimeout(() => {
                        for (let i = 0; i < vm.articlesOffsets.length; i++) {

                            try {
                                vm.articlesOffsets.splice(i, 1, {
                                    top: vm.$refs.article[i].getBoundingClientRect().top,
                                    bottom: vm.$refs.article[i].getBoundingClientRect().top + vm.$refs.article[i].getBoundingClientRect().height + 50
                                });

                                if (vm.articlesOffsets[i].top < vm.controlsOffset.top && vm.articlesOffsets[i].bottom > vm.controlsOffset.bottom) {
                                    vm.articlesCounter = i;
                                }
                            } catch (e) {
                                vm.loading = false;
                            }
                        }
                    }, 100);
                };
                try {
                    this.offsetTop = 5.5 * parseFloat(getComputedStyle(document.documentElement).fontSize) + 20;
                    this.controlsOffset = {
                        top: this.$refs.scrollControls.getBoundingClientRect().top,
                        bottom: this.$refs.scrollControls.getBoundingClientRect().top + this.$refs.scrollControls.getBoundingClientRect().height
                    }
                } catch (e) {
                    vm.loading = false;
                }

            }
        }
    };
</script>

<style scoped>

</style>
