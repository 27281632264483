<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Мы</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <label for="quotes">Заголовок:</label>
                        <b-form-input
                                id="quotes"
                                v-model="heading"
                                :state="localErrors('heading').length == 0 ? null : false"
                                aria-describedby="input-quotes-help input-live-quotes"
                                placeholder="Введите заголовок"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.0'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <label for="quotes">Первый текст:</label>
                        <b-form-input
                                id="quotes"
                                v-model="textes[0]"
                                :state="localErrors('textes.0').length == 0 ? null : false"
                                aria-describedby="input-quotes-help input-live-quotes"
                                placeholder=""
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.0'))}}
                        </b-form-invalid-feedback>
                    </div>

                </div>

                <div class="col-md-6">
                    <div role="group">
                        <label for="author">Второй текст:</label>
                        <b-form-input
                                id="author"
                                v-model="textes[1]"
                                aria-describedby="input-help input-live-author"
                                :state="localErrors('textes.1').length == 0 ? null : false"
                                placeholder=""
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.1'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
                <div class="col-md-6">
                    <div role="group">
                        <label for="position">Третий текст:</label>
                        <b-form-input
                                id="position"
                                v-model="textes[2]"
                                aria-describedby="input-help input-live-position"
                                placeholder=""
                                :state="localErrors('textes.3').length == 0 ? null : false"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.2'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст 1</label>
                            <b-form-textarea
                                    v-model="text_1"
                                    :state="localErrors('text_1').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_1'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст 2</label>
                            <b-form-textarea
                                    v-model="text_2"
                                    :state="localErrors('text_2').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_2'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            let we_are = (_.find(this.relationMeta, (o) => o.meta_key == 'we_are')).meta_value || {};
            this.heading = we_are.heading;
            this.textes = we_are.textes || [];
            this.text_1 = we_are.text_1 || '';
            this.text_2 = we_are.text_2 || '';

        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading : '',
                textes: [],
                text_1 : '',
                text_2 : ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`we_are.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'we_are',
                    value: {
                        heading : this.heading,
                        textes : this.textes,
                        text_1 : this.text_1,
                        text_2 : this.text_2
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
