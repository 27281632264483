<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Нам доверяют....</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок</label>
                            <b-form-input
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Подзаголовок</label>
                            <b-form-textarea
                                    v-model="subheading"
                                    :state="localErrors('subheading').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('subheading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var first_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'first_screen')).meta_value || '';

            this.heading = first_screen.heading || '';
            this.subheading = first_screen.subheading || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading:'',
                subheading: ''
            }
        },
        methods: {
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl =  (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`first_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'first_screen',
                    value: {
                        heading: this.heading,
                        subheading: this.subheading,
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
