import Category from '../../admin/pages/Vacancy/Category/List'
import CategoryForm from '../../admin/pages/Vacancy/Category/Form'

export default [
    {
        path: 'vacancy/category',
        name: 'vacancy-category',
        component: Category,
    },
    {
        path: 'vacancy/category/create',
        name: 'vacancy-category-create',
        component: CategoryForm
    },
    {
        path: 'vacancy/category/:id/edit',
        name: 'vacancy-category-edit',
        component: CategoryForm,
        props: true,
        meta: {
            breadcrumb: 'Post Edit'
        }
    },
]
