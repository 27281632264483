<template>
    <form class="contact-form__form contact-form__form--vacancy" action="">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="contact-form__item contact-form__item--vacancy">
                <input :class="{'input-error':hasError('name')}" type="text" name="name" v-model="name"
                       :placeholder="placeholder_your_name+'*'">
            </div>
            <div class="contact-form__item contact-form__item--vacancy">
                <input :class="{'input-error':hasError('lname')}" type="text" name="lname" v-model="lname"
                       :placeholder="placeholder_your_lname+'*'">
            </div>
            <div class="contact-form__item contact-form__item--vacancy">
                <input :class="{'input-error':hasError('email')}" type="email" name="email" v-model="email"
                       placeholder="email">
            </div>
            <div class="contact-form__item contact-form__item--vacancy">
                <input :class="{'input-error':hasError('tel')}" v-mask="'+38(###)-##-###-##'" name="tel" type="tel"
                       v-model="tel" placeholder="+38(099)-99-999-99">
            </div>
        </div>
        <p class="contact-form__note contact-form__note--vacancy">
            * {{text_required_field}} </p>
        <span class="error-container message-container active">
            {{response.message}}
        </span>
        <div class="contact-form__submit contact-form__submit--vacancy">
            <div class="contact-form__send-file" :class="{'error-field':hasError('resume')}">
                <button class="contact-form__send-file-btn dark-red" type="button">
                    <span class="icon-attach"></span>
                    <span>{{text_attach_resume}}</span>
                </button>
                <input class="" type="file" name="resume" ref="resume" @change="previewFiles($event)"
                       accept=".doc, .docx, .pdf">
                <p class="contact-form__filename" v-if="file">{{file.name}}</p>
            </div>
            <button class="cta-btn cta-btn--red" @click.prevent="send" :disable="sending" type="submit">
                {{btn_send}} <i class="icon-arrow  ml-5"></i>

            </button>
        </div>
    </form>
</template>

<script>
    export default {
        props: {
            placeholder_your_name: {
                type: String
            },
            placeholder_your_lname: {
                type: String
            },
            text_required_field: {
                type: String
            },
            text_attach_resume: {
                type: String
            },
            btn_send: {
                type: String
            },
            route: {
                type: String,
                required: true
            },
            vacancy_id: {
                type: [String, Number],
                default: null
            }
        },
        data() {
            return {
                name: '',
                lname: '',
                email: '',
                tel: '',
                file: '',
                sending: false,
                errors: [],
                response: {
                    message: '',
                    errors: []
                }
            }
        },
        methods: {
            hasError(name) {
                return _.has(this.response.errors, name)
            },
            getFirstError(name) {
                return _.head(this.response.errors[name])
            },
            previewFiles() {
                this.file = this.$refs.resume.files[0] || null
            },
            setSending() {
                this.sending = true;
            },
            resetSending() {
                this.sending = false;
            },
            flushForm() {
                Object.assign(this.$data, this.$options.data())
            },
            flushErrors() {
                this.response.message = '';
                this.response.errors = [];
            },
            getFormData() {
                let formData = new FormData();
                formData.append("resume", this.file);
                formData.append('name', this.name)
                formData.append('lname', this.lname)
                formData.append('tel', this.tel)
                formData.append('email', this.email)
                formData.append('vacancy_id', this.vacancy_id)

                return formData;
            },
            send() {
                if (this.sending) return;

                this.setSending();
                this.flushErrors();

                axios.post(this.route, this.getFormData(), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(({data}) => {
                        this.flushForm();
                        this.resetSending();
                        this.response.message = data.success_message
                    }).catch(e => {
                    let response = e.response || {};
                    this.response.message = response.data.message || ''
                    this.response.errors = response.data.errors || []
                    this.resetSending();
                })
            }
        }
    }
</script>

<style scoped>

</style>
