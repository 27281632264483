<template>

    <social-sharing :url="url"
                    :title="title"
                    :description="description"
                    :hashtags="hashtags"
                    inline-template>
        <div class="d-flex flex-wrap w-100">
            <network network="facebook" class="article-page__social-link">
                <i class="icon-facebook"></i>
            </network>
            <network network="linkedin" class="article-page__social-link">
                <i class="icon-linkedin"></i>
            </network>
            <!--<network network="twitter" class="article-page__social-link">-->
                <!--<i class="icon-twitter"></i>-->
            <!--</network>-->
            <network network="telegram" class="article-page__social-link">
                <i class="icon-telegram"></i>
            </network>

            <network network="skype" class="article-page__social-link">
                <i class="icon-skype"></i>
            </network>
        </div>
    </social-sharing>
</template>

<script>
    export default {
         pross : ['url','title','description','hashtags']
    }
</script>

<style scoped>

</style>