<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Отзыв</h5>
            <div class="row">
                <div class="col-md-12">
                    <br>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('Images','photo')">
                                <i class="fa fa-picture-o"></i> Фото
                            </a>
                        </div>
                        <input type="text" readonly :class="{'is-invalid':localErrors('photo').length > 0 }"
                               class="form-control" v-model="photo">
                        <button type="button" v-on:click="photo=''">
                            Удалить ссылку на фото
                        </button>
                    </div>
                    <img style="width: 100%" class="mt-3" :src="photo"/>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Имя:</label>
                        <b-form-input
                                id="position"
                                v-model="name"
                                :state="localErrors('name').length == 0 ? null : false"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите имя клиента"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('name'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Отзыв:</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="review"
                                :state="localErrors('review').length == 0 ? null : false"
                                rows="5"
                                max-rows="6"
                        ></b-form-textarea>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('review'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>

                <div class="col-md-12">
                    <br>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('Files','original_reviews')">
                                <i class="fa fa-picture-o"></i> Оригинал
                            </a>
                        </div>
                        <input type="text" readonly :class="{'is-invalid':localErrors('original_reviews').length > 0 }"
                               class="form-control" v-model="original_reviews">
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Ссылка:</label>
                        <b-form-input
                                id="position"
                                v-model="client_link"
                                :state="localErrors('client_link').length == 0 ? null : false"
                                aria-describedby="input-help input-live-position"
                                placeholder="Ссылка на сайт клиента"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('client_link'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            let review = (_.find(this.relationMeta, (o) => o.meta_key == 'review')).meta_value || {}
            this.photo = review.photo || ''
            this.name = review.name || ''
            // this.review = review.original_reviews || ''
            this.review = review.review || ''
            this.original_reviews = review.original_reviews || ''
            this.client_link = review.client_link || ''
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                photo: null,
                name: null,
                review: null,
                original_reviews: null,
                client_link: null,
                passible: [
                    'photo', 'name', 'review', 'original_reviews', 'client_link'
                ]
            }
        },
        methods: {
            localErrors(key){
              return this.errors('review.'+key);
            },
            passToParrentData(value) {
                let pass = {};
                for (var cur in value) {
                    if (this.passible.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                this.$emit('updateMeta', {
                    key: 'review',
                    value: {
                        photo: this.photo,
                        name: this.name,
                        review: this.review,
                        original_reviews: this.original_reviews,
                        client_link: this.client_link
                    }
                })
            },
            openFileManager(type, param) {
                var params = {
                    type: type
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                var self = this
                window.SetUrl = function (items) {
                    self[param] = items.replace(window.location.origin, '')
                }
                return false
            }
        }
    }
</script>

<style scoped>

</style>
