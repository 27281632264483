<template>
    <div class="content">
        <div class="container-fluid">
<!--            <div class="row mb-2">-->
<!--                <div class="col-md-12" style="text-align: right">-->
<!--                    <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">-->
<!--                                                            <span class="spinner-border d-none spinner-border-sm"-->
<!--                                                                  role="status" aria-hidden="true"></span>-->
<!--                        <span class="">Сохранить</span>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <ul style="list-style-type: none">
                            <li><strong>Имя:</strong> {{comment.user_name}}</li>
                            <hr>
                            <li><strong>Email:</strong> {{comment.email}}</li>
                            <hr>
                            <li><strong>Коментарий:</strong>{{comment.comment}}</li>
                        </ul>
                                <card>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12" v-for="answer in comment.answer">
                                                <base-input type="textarea"
                                                            style="width: 30%"
                                                            label="Имя"
                                                            placeholder="Введите Имя"
                                                            v-model="answer.userName">
                                                </base-input>
                                                <base-input type="textarea"
                                                            label="Роль"
                                                            style="width: 30%"
                                                            placeholder="Введите Роль"
                                                            v-model="answer.role">
                                                </base-input>
                                                <label for="answer">Ответ:</label>
                                                <b-form-textarea
                                                         id="answer"
                                                        type="textarea"
                                                        label="Ответ"
                                                        placeholder="Введите Ответ"
                                                        v-model="answer.text"
                                                ></b-form-textarea>
                                                <hr>

                                            </div>
                                        </div>
                                        <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                                            <span class="">Сохранить</span>
                                        </button>
                                    </form>
                                </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
     .card label {
         /*margin-left: 10px;*/
     }
</style>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import ErrorMixin from '../../mixim/UserMixin'
    import {mapGetters} from 'vuex'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        props: ['id'],
        mixins: [ErrorMixin],
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: 'admin/case/comments'
                },
                isAdmin: false,
                comment: {},
                errors: []
            }
        },
        computed: {

        },
        components: {
            Card
        },
        methods: {
            setEditFunc() {
                this.paramsSave = {
                    method: 'put',
                    url: `admin/case/comment/${this.id}`
                };

                axios.get(`admin/case/comment/${this.id}`)
                    .then(({data}) => {
                        console.log(data)
                        this.comment = data.data;
                        this.comment.answer.push({
                            text:null,
                            userName:null,


                        })
                        // this.setEditFunc();
                        // if (data.data.description.length || typeof data.data.description == 'object') {
                        //     _.map( data.data.comment,(o) => {
                        //         // if(o.language_id){
                        //         //     this.tag[o.language_id].name = o.name;
                        //         // }
                        //     })

                        // }
                    })
            },
            save(e) {
                addBtnLoading(e)
                this.errors = [];
                axios[this.paramsSave.method](this.paramsSave.url, {
                        data: this.comment
                    }
                ).then(({data}) => {
                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');
                    this.setEditFunc()

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message
                        this.errors = error.response.data.errors
                        window.notification.error(this.errorMessage);

                    });
            }
        },
        mounted() {
            if (this.id > 0) {
                this.setEditFunc();
            }
        },
        beforeCreate() {
            axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                    _.map(data.data, (i) => {
                        this.$set(this.tag, i.id, {
                            name: ''
                        })
                    })
                });

        }

    }

</script>
<style>

</style>
