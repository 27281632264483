<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Информация ок клиенте</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <p style="display: block" class="invalid-feedback" v-if="errors('client_information').length">
                            {{_.head(errors('client_information'))}}</p>
                        <quill-editor style="min-height:220px;" v-model="client_information"
                                      ref="myQuillEditor"
                                      :options="client_information">
                        </quill-editor>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './mixins/MetaDescriptionMixin'

    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        components : {
            quillEditor
        },
        created() {
            this.client_information = (_.find(this.relationMeta, (o) => o.meta_key == 'client_information')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                client_information: null,
                passible: [
                    'client_information'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'client_information',
                    value: this.client_information
                })
            }
        }
    }
</script>

<style scoped>

</style>
