<template>
    <ul class="nav nav-mobile-menu">
        <li class="nav-item">
            <a class="nav-link" @click="logout()">
                <span class="no-icon">Log out</span>
            </a>
        </li>
    </ul>
</template>
<script>
    export default {
        name: 'mobile-menu'
    }
</script>
<style>
</style>
