<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Короткое описание</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <b-form-textarea
                            id="textarea"
                            v-model="excerpt"
                            rows="5"
                            max-rows="6"
                        ></b-form-textarea>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    export default {
        mixins: [],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.excerpt = (_.find(this.relationMeta, (o) => o.meta_key == 'excerpt')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                excerpt: null,
                passible: [
                    'excerpt'
                ]
            }
        },
        methods: {
            errors(e) {
                return ''
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'excerpt',
                    value: this.excerpt
                })
            }
        }
    }
</script>

<style scoped>

</style>
