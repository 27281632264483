<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Блок перелинковки</h5>
        <div class="row">
            <div class="col-md-12">
                <b-form-group>
                    <b-form-checkbox
                            v-for="(option,key) in list_pages"
                            v-model="pages"
                            :key="key"
                            :value="key"
                            name="flavour-3a"
                    >
                        {{ option }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
        </div>
    </card>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'
    export default {
        props : {
            infoForEdit : {
                type : Object,
                default : {}
            }
        },
        components: {
            Card, LTable
        },
        data() {
            return {
                loading: false,
                pages : [],
                list_pages : {
                    'frontend-home-index' : 'Главная',
                    'frontend-about-index' : 'О нас',
                    'frontend-services' : 'Сервисы',
                    'frontend-blog-page' : 'Блог',
                    'frontend-clients-page' : 'Клиенты',
                    'frontend-contact-contact' : 'Контакты',
                    'frontend-careers-careers' : 'Вакансии',
                    'frontend-clients-client' : 'Страница клиента',
                    'frontend-clients-article' : 'Страница статьи'
                }
            }
        },
        watch:{
            '$data' : {
                handler(){
                    this.passDataToParrent();
                },
                deep : true
            }
        },
        created() {
            var property = this.infoForEdit.linking_unit;
            this.pages = property || [];
        },
        methods: {
            passDataToParrent(){
                this.$emit('setInfo',{
                    key : 'linking_unit',
                    value : this.pages
                });
            },
        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
