import router from "../routes/routes";

require("babel-polyfill");

require('./bootstrap')

import Vue from 'vue'
Vue.config.productionTip = false
Vue.config.silent = true
import {store} from './vuex/store'
import VueLazyload from 'vue-lazyload'

window._ = require('lodash')

Vue.prototype._ = _;

import VueAxios from 'vue-axios'

import axios from 'axios'

Vue.use(VueAxios, axios)
Vue.use(VueLazyload)

window.bus = new Vue;

import VueSocialauth from 'vue-social-auth'

Vue.use(VueSocialauth, {

    providers: {
        facebook: {
            // clientId: '2427934907249928',
            clientId: '513321902733657',
            redirectUri: 'https://wits.agency/login/facebook/callback' // Your client app URL
        },
        twitter: {
            url : `${window.location.origin}/login/twitter`,
            clientId: 'v6piCDcrrCanjPYdgnpnpbDuA',
            redirectUri: 'https://wits.agency/login/twitter/callback' // Your client app URL
        },

        google: {
            name: 'google',
            url : `${window.location.origin}/login/google`,
            // clientId: '73807187248-leeokef220uic8hshkof2k6p6u4nm71l.apps.googleusercontent.com',
            // clientId: '43656231273-8s51fsh14qfsffv6c2om6arc2cv1pmk6.apps.googleusercontent.com',
            clientId: '727797825261-5ntv7gbaq9dk2rp3dupqr2msdovtc6ds.apps.googleusercontent.com',
            redirectUri: 'https://wits.agency/login/google/callback' // Your client app URL
        },
    }
})

import Moment from 'moment'

Vue.prototype.$moment = Moment

Vue.component('page-blog-articles',require('./components/Blog/PageArticle').default)
Vue.component('page-clients-list',require('./components/Clients/Page').default)
Vue.component('btn-more',require('./components/BtnMore').default)
Vue.component('form-comment',require('./components/Auth/FormComment').default)
Vue.component('list-comments',require('./components/Auth/Comments').default)
Vue.component('form-vacancy',require('./components/FormVacancy').default)

Vue.component('app-auth',require('./components/Auth/Auth').default)
Vue.component('app-sharing',require('./components/Blog/Sharing').default)
Vue.component('app-form-crm',require('./components/FormCRM').default)
Vue.component('app-contact-form',require('./components/ContactForm').default)
Vue.component('app-subscribe',require('./components/Blog/Subscribe').default)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);


var SocialSharing = require('vue-social-sharing');

Vue.use(SocialSharing);

var app = new Vue({
    store,
    el: '#app'
});


new Vue({
    el: '#form-crm',
    store
})

new Vue({
    el: '#contact-form',
    store
})

new Vue({
    el: '#contact-form2',
    store
})

