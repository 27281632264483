<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Социальные сети</h5>
        <div class="row">
            <div class="col-md-12">
                <label for="">Facebook</label>
                <b-form-input
                        id="position"
                        v-model="facebook"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Linkedin</label>
                <b-form-input
                        id="position"
                        v-model="linkedin"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Twitter</label>
                <b-form-input
                        id="position"
                        v-model="twitter"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Instagram</label>
                <b-form-input
                        id="position"
                        v-model="instagram"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
        </div>
    </card>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'
    export default {
        props : {
            infoForEdit : {
              type : Object,
              default : {}
            },
            relationTags : {
                type : [Object]
            }
        },
        components: {
            Card, LTable
        },
        data() {
            return {
                loading: false,
                facebook: '',
                linkedin : '',
                twitter : '',
                instagram : ''
            }
        },
        watch:{
            '$data' : {
                handler(){
                    this.passDataToParrent();
                },
                deep : true
            }
        },
        created() {
            var property = this.infoForEdit.socials;
            if (typeof property === 'object') {
                if (property.hasOwnProperty('facebook')) {
                    this.facebook = property.facebook;
                }
                if (property.hasOwnProperty('linkedin')) {
                    this.linkedin = property.linkedin;
                }
                if (property.hasOwnProperty('twitter')) {
                    this.twitter = property.twitter;
                }
                if (property.hasOwnProperty('instagram')) {
                    this.instagram =property.instagram;
                }

            }
        },
        methods: {
            passDataToParrent(){
                this.$emit('setInfo',{
                    key : 'socials',
                    value : {
                        facebook : this.facebook,
                        linkedin: this.linkedin,
                        twitter : this.twitter,
                        instagram: this.instagram
                    }
                });
            },
        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
