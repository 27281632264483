import Post from '../../admin/pages/Post/Post'
import PostForm from '../../admin/pages/Post/PostForm'
export default [
    {
        path: 'cases',
        name: 'cases',
        component: Post,
    },
    {
        path: 'cases/create',
        name: 'cases-create',
        component: PostForm
    },
    {
        path: 'cases/:id/edit',
        name: 'cases-edit',
        component: PostForm,
        props: true,
        meta: {
            breadcrumb: 'Post Edit'
        }
    },
]
