export default {
    props : {
        params: {
            type: Object,
            default: {}
        },
    },
    updated(){

        for (var key in
            this.params
            ) {
            if (this.key !== undefined) {
                this.key = this.params[key];
            }
        }
    },
    created() {
        for (var key in
            this.params
            ) {
            if (this[key] !== undefined) {
                this[key] = this.params[key];
            }
        }
    },
    mounted() {
        this.passToParrent(this.$data);
    },
    watch: {
        '$data': {
            handler(value) {
                this.passToParrent(value);
            },
            deep: true
        }
    },
    methods: {
        errors(key) {
            let error = this.$store.getters['errors/getError'](`post_description.${this.lang_id}.parts.${this.keyComponent}.params.${key}`);
            if (Array.isArray(error))
                return error;
            return [];
        },
        passToParrent(value) {
            let pass = {};
            for (var cur in value) {
                if (this.passible.indexOf(cur) !== -1) {
                    pass[cur] = value[cur];
                }
            }
            this.$emit('updateParams', pass)
        }
    }
}
