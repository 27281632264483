module.exports.addBtnLoading = function(e) {
    var el = e.target;
    if (e.target.nodeName !== 'BUTTON') {
        el = e.target.parentNode;
    }
    el.setAttribute("disabled", true);
    el.childNodes[0].classList.remove('d-none');
    el.childNodes[2].classList.add('sr-only');
}


module.exports.removeBtnLoading = function(e) {
    var el = e.target;
    if (e.target.nodeName !== 'BUTTON') {
        el = e.target.parentNode;
    }
    el.removeAttribute("disabled");
    el.childNodes[0].classList.add('d-none');
    el.childNodes[2].classList.remove('sr-only');
}
