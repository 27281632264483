<template>
    <div class="row">
        <div class="col-md-12">
            <p style="display: block" class="invalid-feedback" v-if="errors('elements').length">
                {{_.head(errors('elements'))}}</p>
            <table class="table table-hover table-bordered table-striper">
                <tr v-for="(element,index) in elements" :key="element.id">
                    <td>{{index+1}}</td>
                    <td>
                        <p style="display: block" class="invalid-feedback"
                           v-if="errors('elements.'+index+'.val').length">
                            {{_.head(errors('elements.'+index+'.val'))}}</p>
                        <quill-editor v-model="elements[index].val"
                                      ref="myQuillEditor"
                                      :options="editorOption">
                        </quill-editor>
                    </td>
                    <td>
                        <button class="btn-sm btn btn-fill btn-warning pull-right" @click="removeElements(index)">-
                        </button>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-md-12">
            <button class="btn-sm btn btn-fill btn-success pull-right" @click="addElements">+</button>
        </div>
    </div>
</template>

<script>

    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'

    import watchParams from './mixin/watchParams'

    export default {
        mixins: [watchParams],
        props: {
            keyComponent: {
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        components: {
            quillEditor
        },
        created() {
            if (this.elements.length == 0) {
                this.addElements()
            }
        },
        data() {
            return {
                elements: [],
                passible: [
                    'elements', 'validate'
                ],
                validate: {
                    elements: ['array', 'required', 'min:1'],
                    'elements.*.val': ['required', 'min:1']
                },
                editorOption: {}
            };
        },
        methods: {
            removeElements(index) {
                this.elements.splice(index, 1);
            },
            addElements() {
                this.elements.push({
                    val: "",
                    id: Date.now()
                });
            }
        }
    }
</script>


<style>
    .ql-container {
        min-height: inherit;
    }
</style>
