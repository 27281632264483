<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Ссылка на полный кейс:</h5>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <autocomplete
                            ref="autocomplete"
                            placeholder="Search Active Users"
                            :source="distributionGroupsEndpoint"
                            input-class="form-control"
                            results-property="data"
                            :showNoResults="false"
                            :results-display="formattedDisplay"
                            :request-headers="authHeaders()"
                            @selected="addDistributionGroup">
                    </autocomplete>
                    <div ref="post" v-if="relation_case">
                        <router-link :to="{name : 'cases-edit',params : {id : relation_case}}">Ссылка (id :
                            {{relation_case}})
                        </router-link>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
    export default {
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.relation_case  = (_.find(this.relationMeta, (o) => o.meta_key == 'relation_case')).meta_value || {}
        },
        watch: {
            '$data': {
                handler(value) {
                    this.$emit('updateMeta', {
                        key: 'relation_case',
                        value: this.relation_case
                    })
                },
                deep: true
            }
        },
        data() {
            return {
                relation_case: null
            }
        },
        methods: {
            distributionGroupsEndpoint(input) {
                return '/api/v1/admin/case?query=' + input
            },
            addDistributionGroup(group) {
                this.relation_case = group.selectedObject.id;
                this.$refs.autocomplete.clear()
            },
            authHeaders() {
                return {
                    'Authorization': 'Bearer ' + auth.getToken()
                }
            },
            formattedDisplay(result) {
                return result.title
            },
        }
    }
</script>


<style scoepd></style>