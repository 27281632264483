<template>
    <form class="side-form__form lirax-custom-form" id="contact-form1"  action="">
        <div class="d-flex flex-column">

            <input type="hidden" name="lirax_deal_cf_490941" id="lirax_deal_cf_490941" value="">

            <input type="hidden" class="lirax-name" name="name" value="Автоконтакт">
            <input type="hidden" class="form-control" name="lirax_deal_cf_433613" value="">
            <input type="hidden" class="form-control" name="lirax_deal_cf_449501" value="1564581720">
            <input type="hidden" class="form-control" name="lirax_deal_tag" value="">
            <input type="hidden" class="form-control" name="lirax_deal_name"
                   :value="cur_url + '_header'">
            <input type="hidden" name="lang" :value="lang">
            <div class="side-form__item">
                        <textarea v-model="text" name="lirax_deal_cf_193749"
                                  :placeholder="text_greeting" :class="{'input-error':hasError('text')}"></textarea>
            </div>
            <div class="side-form__item">
                <input v-model="email" :class="{'input-error':hasError('email')}" name="email" type="email"
                       :placeholder="text_email">
            </div>
            <div class="side-form__item">
                <input type="tel" :class="{'input-error':hasError('phone')}" v-model="phone" name="phone"
                       v-mask="'+38(###)-##-###-##'"
                       placeholder="+38(__) ___ __ __" v-on:focus="placeValue">
            </div>
            <div class="side-form__submit">
                <span class="message-container"
                      :class="{
                          'active' : response.message.length,
                          'success-container' : !hasErrors,
                          'error-container' : hasErrors
                      }">{{response.message}}</span>
                <button class="cta-btn cta-btn--dark" type="submit" @click.prevent="send('contact-form1')" :disable="sending">
                    {{text_btn_send}} <i class="icon-arrow"></i>
                </button>
            </div>
            <p class="text-primary side-form__note">{{text_nda}}</p>
        </div>

    </form>
</template>

<script>
    import FormSendMixin from './mixins/formSendMixin'
    export default {
        mixins : [FormSendMixin],
        props: {
            text_greeting: {
                type: String,
                default: ''
            },
            text_email: {
                type: String,
                default: ''
            },
            text_btn_send: {
                type: String,
                default: ''
            },
            text_nda: {
                type: String,
                default: ''
            },
            url_send: {
                type: String,
            },
            cur_url: {
                type: String,
                default: window.location.host + (window.location.pathname == '/' ? '' : window.location.pathname)
            },
            lang : {
                type : String
            }
        },
        methods: {
            placeValue(){
                this.phone = '+380(';
            }
        }
    }
</script>

<style scoped>

</style>
