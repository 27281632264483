<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Мы ориентированны на следующие показатели:</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок
                                <code style="color: #B0140E">
                                    &lt;span>Любой текст<&frasl;span>
                                </code>- для выделения текста красным цветов
                            </label>
                            <b-form-textarea
                                    id="textarea"
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <label for="quotes">Первый текст:</label>
                        <b-form-input
                                id="quotes"
                                v-model="textes[0]"
                                :state="localErrors('textes.0').length == 0 ? null : false"
                                aria-describedby="input-quotes-help input-live-quotes"
                                placeholder="Введите цитату"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.0'))}}
                        </b-form-invalid-feedback>
                    </div>

                </div>

                <div class="col-md-6">
                    <div role="group">
                        <label for="author">Второй текст:</label>
                        <b-form-input
                                id="author"
                                v-model="textes[1]"
                                aria-describedby="input-help input-live-author"
                                :state="localErrors('textes.1').length == 0 ? null : false"
                                placeholder="Введите цитату"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.1'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
                <div class="col-md-6">
                    <div role="group">
                        <label for="position">Третий текст:</label>
                        <b-form-input
                                id="position"
                                v-model="textes[2]"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите дожлность"
                                :state="localErrors('textes.3').length == 0 ? null : false"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.2'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
                <div class="col-md-6">
                    <div role="group">
                        <label for="position">Четвертый текст:</label>
                        <b-form-input
                                id="position"
                                v-model="textes[3]"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите дожлность"
                                :state="localErrors('textes.3').length == 0 ? null : false"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('textes.3'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var we_oriented = (_.find(this.relationMeta, (o) => o.meta_key == 'we_oriented')).meta_value || '';
            this.textes = we_oriented.textes || []
            this.heading = reality.heading || ''
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                textes: [],
                heading: ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`we_oriented.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'we_oriented',
                    value: {
                        textes: this.textes,
                        heading: this.heading
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
