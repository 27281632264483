import router from './../../routes/routes'

export default {
    namespaced: true,
    state: {
       roles : []
    },
    getters: {
      getRoles : (state) => {
          return state.roles;
      }
    },
    actions : {
        loadRoles(store,payload){
            if(store.state.roles.length === 0){
                axios.get('roles')
                    .then(({data}) => {
                        store.state.roles = data.data;
                    })
            }

        }
    }
}
