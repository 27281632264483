<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Общий шаблон блока перелинковки:</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Заголовок:</label>
                        <b-form-input
                                id="position"
                                v-model="heading"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите имя клиента"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">

                        </b-form-invalid-feedback>
                    </div>
                </div>
                <div class="col-md-12">
                    <h4>Дерево ссылок</h4>
                    <div>
                        <div style="width:840px; margin: 0 auto;">
                            <div style="width:49%; display:inline-block; vertical-align: top;">

                                <v-jstree :data="data"
                                          :item-events="itemEvents"
                                          multiple
                                          allow-batch
                                          whole-row
                                          draggable
                                          @item-click="itemClick"
                                          @item-drag-start="itemDragStart"
                                          @item-drag-end="itemDragEnd"
                                          @item-drop-before="itemDropBefore"
                                          @item-drop="itemDrop"
                                          ref="tree"></v-jstree>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <table class="table table-bordered table-striped table-hover">
                        <tr>
                            <td>
                                Текст
                            </td>
                            <td>
                                <input class="form-control" v-model="editingItem.text"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ссылка
                            </td>
                            <td>
                                <input class="form-control" v-model="editingItem.value"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                title
                            </td>
                            <td>
                                <input class="form-control" v-model="editingItem.title"/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button class="btn btn-fill btn-success btn-sm" @click="addChildNode">Добавить узел
                                </button>
                                <button class="btn-sm btn-fill btn btn-danger" @click="removeNode">Удалить узел</button>
                                <button class="btn-sm btn-fill btn btn-warning" @click="toRoot()">Корень / </button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </card>
    </div>
</template>

<script>

    import VJstree from 'vue-jstree'

    export default {
        props : {
            editDescription : {
                type : Object,
                default : {}
            }
        },
        created(){
            this.heading = this.editDescription.heading || ''
            this.data = this.editDescription.services || this.data
        },
        components: {
            VJstree
        },
        watch: {
            'data': {
                handler() {
                    this.passToParrentData();
                },
                deep: true
            },
            heading() {
                this.passToParrentData();
            }
        },
        data() {
            return {
                msg: 'A Tree Plugin For Vue2',
                searchText: '',
                editingItem: {},
                editingNode: null,
                itemEvents: {
                    mouseover: function () {
                        console.log('mouseover')
                    },
                    contextmenu: function () {
                        arguments[2].preventDefault()
                    }
                },
                heading: null,
                data: [
                    {
                        'icon': 'fa fa-sitemap',
                        "children": []
                    },
                ],
                passible: [
                    'client_information'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateInfo', {
                    key: 'services',
                    value: {
                        heading: this.heading,
                        services: this.data
                    }
                })
            },
            toRoot(){
                this.editingNode = null;
                this.editingItem = {};
            },
            itemClick(node) {
                this.editingNode = node
                this.editingItem = node.model
            },
            itemDropBefore(node, item, draggedItem, e) {
                if (!draggedItem) {
                    item.addChild({
                        text: "newNode",
                        value: "newNode"
                    })
                }
            },
            itemDrop(node, item, draggedItem, e) {
                var sortBy = function (attr, rev) {
                    if (rev == undefined) {
                        rev = 1;
                    } else {
                        rev = (rev) ? 1 : -1;
                    }
                    return function (a, b) {
                        a = a[attr];
                        b = b[attr];
                        if (a < b) {
                            return rev * -1;
                        }
                        if (a > b) {
                            return rev * 1;
                        }
                        return 0;
                    }
                }
                item.children.sort(sortBy('text', true))
                this.$refs.tree.handleRecursionNodeChildren(draggedItem, function (childrenItem) {
                    childrenItem.selected = item.selected
                })
            },
            addChildNode: function () {
                if (this.editingItem.id !== undefined) {
                    this.editingItem.addChild({
                        text: "newNode",
                        value: "newNode",
                        icon: 'fa fa-sitemap'
                    })
                }else{
                    this.data.push({
                        text: "newNode",
                        value: "newNode",
                        icon: 'fa fa-sitemap'
                    });

                    this.$refs.tree.initializeData(this.data)
                }
            },
            removeNode: function () {
                if (this.editingItem.id !== undefined) {
                    var index = this.editingNode.parentItem.indexOf(this.editingItem)
                    this.editingNode.parentItem.splice(index, 1)
                }
            },
            openChildren: function () {
                if (this.editingItem.id !== undefined) {
                    this.editingItem.openChildren()
                }
            },
            closeChildren: function () {
                if (this.editingItem.id !== undefined) {
                    this.editingItem.closeChildren()
                }
            }
        }
    }
</script>

<style>
.tree-selected{
    background: #e1e1e1 !important;
}
</style>
