<template>
    <card>
        <h5 slot="header" class="card-title">Общие данные</h5>
        <form>
            <div class="row" v-if="logoImage">
                <div class="col-md-12">
                    <div class="input-group">
                        <input type="text" :class="{'is-invalid': _.has(errors,'main_image') }" class="form-control"
                               v-model="main_image">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager">
                                <i class="fa fa-picture-o"></i> Изобажение
                            </a>
                        </div>
                    </div>


                </div>
                <div class="col-12">
                    <b-img-lazy v-if="main_image" thumbnail :src="main_image" alt="Image 1"></b-img-lazy>
                </div>
            </div>

            <div class="row" v-if="datePub">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="control-label">
                            Дата публикации
                        </label>
                        <div class="custom-file">
                            <input type="date" class="form-control" id="date_published" v-model="dataPublished"
                                   :class="{'is-invalid': _.has(errors,'dataPublished') }">
                            <div class="invalid-feedback" v-if="_.has(errors,'dataPublished')">
                                {{_.head(errors['dataPublished'])}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <slot name="meta_information"></slot>
            <b-form-checkbox
                v-if="editData.type === 'case' "
                v-model="enabled_translate_english"
                :key="enabled_translate_english"
                value="1"
                unchecked-value="0"
                :name="title"
        >
            English
        </b-form-checkbox>
            <b-form-checkbox
                    v-if="editData.type === 'case' "
                    v-model="enabled_translate_ukrainian"
                    :key="enabled_translate_ukrainian"
                    value="1"
                    unchecked-value="0"
                    :name="title"
            >
                Ukrainian
            </b-form-checkbox>
            <div class="row">
                <div class="col-md-5">
                    <button class="btn btn-sm btn-warning btn-fill btn-block" @click.prevent="draft($event)">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                        <span class="">Черновик</span>
                    </button>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5">
                    <button class="btn btn-sm btn-success btn-fill btn-block" @click.prevent="publish($event)">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                        <span class="">Опубликовать</span>
                    </button>
                </div>
            </div>

            <div class="clearfix"></div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        props: {
            editData : {
                type : Object
            },
            datePub : {
                type : Boolean,
                default : true
            },
            logoImage : {
                type : Boolean,
                default : true
            }
        },
        components: {
            Card
        },
        created() {
            if (typeof this.editData === 'object') {
                if (this.editData.hasOwnProperty('image')) {
                    this.main_image = this.editData.image;
                }

                if (this.editData.hasOwnProperty('publish_at')) {
                    this.dataPublished = this.editData.publish_at;
                }
            }
            console.log(1231231231231);
            console.log(this.editData);
            this.enabled_translate_english = this.editData.enabled_translate_english;
            this.enabled_translate_ukrainian = this.editData.enabled_translate_ukrainian;

            // this.checkEnglish();
        },
        data() {
            return {
                main_image: null,
                enabled_translate_english:null,
                enabled_translate_ukrainian:null,
                dataPublished: new Date(),
                status: 'draft',
                passible: ['logo', 'dataPublished', 'status', 'main_image','enabled_translate_english','enabled_translate_ukrainian']
            }
        },
        computed: {
            errors() {
                return this.$store.getters['errors/getErrors'];
            }
        },
        watch: {
            '$data': {
                 handler: async function (value) {
                   this.passData(value)
                },
                deep: true
            }
        },
        methods: {
            checkEnglish(){
                this.enabled_translate_english = this.editData.enabled_translate_english;
                console.log(this.editData);
                // this.enabled_translate_english = true;
            },
            async passData(value){
                let pass = {};
                for (var cur in value) {
                    if (this.passible.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                await this.$emit('updateData', pass)
            },
            openFileManager() {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                var self = this
                window.SetUrl = function (items) {
                    self.main_image = items.replace(window.location.origin, '')
                }
                return false
            },
            async draft(e) {
                addBtnLoading(e);
                this.status = 'draft';
                this.passData(this.$data);
                await this.$emit('publish', e)
            },
            async publish(e) {
                this.status = 'publish';
                this.passData(this.$data);
                addBtnLoading(e);
                await this.$emit('publish', e)
            }
        }
    }

</script>
<style>

</style>
