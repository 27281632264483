<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Файл пример</h5>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':errors('example_file').length > 0 }"
                               class="form-control" v-model="example_file">
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.example_file = (_.find(this.relationMeta, (o) => o.meta_key == 'example_file')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                example_file: null
            }
        },
        methods: {

            openFileManager() {
                var params = {
                    type: 'Files'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );
                var self = this
                window.SetUrl = function (items) {
                    self.example_file = items.replace(window.location.origin, '')
                }
                return false
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'example_file',
                    value: this.example_file
                })
            }
        }
    }
</script>

<style scoped>

</style>
