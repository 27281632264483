import Home from '../../admin/pages/PageControll/Home'
import About from '../../admin/pages/PageControll/About'
import Blog from '../../admin/pages/PageControll/Blog'
import Clients from '../../admin/pages/PageControll/Clients'
import Contacts from '../../admin/pages/PageControll/Contacts'
import Careers from '../../admin/pages/PageControll/Careers'
export default [
    {
        path: 'page/home',
        name: 'page-home',
        component: Home,
    },
    {
        path: 'page/about',
        name: 'page-about',
        component: About,
    },
    {
        path: 'page/blog',
        name: 'page-blog',
        component: Blog,
    },
    {
        path: 'page/clients',
        name: 'page-clients',
        component: Clients,
    },
    {
        path: 'page/contacts',
        name: 'page-contacts',
        component: Contacts,
    },
    {
        path: 'page/careers',
        name: 'page-careers',
        component: Careers,
    },
]
