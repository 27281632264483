import Client from '../../admin/pages/Clients/Client'
import ClientForm from '../../admin/pages/Clients/ClientForm'
export default [
    {
        path: 'clients',
        name: 'clients',
        component: Client,
    },
    {
        path: 'clients/create',
        name: 'clients-create',
        component: ClientForm
    },
    {
        path: 'clients/:id/edit',
        name: 'clients-edit',
        component: ClientForm,
        props: true,
        meta: {
            breadcrumb: 'Post Edit'
        }
    },
]
