<template>
    <div class="row">
        <div class="col-md-12">
            <div role="group">
                <label for="quotes">Цитата:</label>
                <b-form-input
                    id="quotes"
                    v-model="quotes"
                    :state="errors('quotes').length == 0 ? null : false"
                    aria-describedby="input-quotes-help input-live-quotes"
                    placeholder="Введите цитату"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                   {{_.head(errors('quotes'))}}
                </b-form-invalid-feedback>
            </div>

        </div>

        <div class="col-md-6">
            <div role="group">
                <label for="author">Автор:</label>
                <b-form-input
                    id="author"
                    v-model="author"
                    aria-describedby="input-help input-live-author"
                    :state="errors('author').length == 0 ? null : false"
                    placeholder="Введите цитату"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('author'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="col-md-6">
            <div role="group">
                <label for="position">Должность:</label>
                <b-form-input
                    id="position"
                    v-model="position"
                    aria-describedby="input-help input-live-position"
                    placeholder="Введите дожлность"
                    :state="errors('position').length == 0 ? null : false"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('position'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
    </div>

</template>
<script>
    import watchParams from './mixin/watchParams'

    export default {
        mixins:[watchParams],
        props: {
            keyComponent:{
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        data() {
            return {
                quotes: '',
                author: '',
                position: '',
                validate : {
                    author : ['required','min:1'],
                    quotes : ['required','min:10'],
                    position : ['required','min:1']
                },
                passible : [
                    'quotes','author','position','validate'
                ]
            }
        }
    }
</script>

<style scoped>

</style>
