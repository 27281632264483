<template>
    <div class="d-flex justify-content-center" v-if="show" @click="more">
        <button class="cta-btn cta-btn--red clients-page__btn-more">
            {{text}}
            <span class="icon-cycle"></span>
        </button>
    </div>
</template>

<script>
    export default {
        props :['text'],
        data(){
          return {
            show : false
          }
        },
        methods:{
          more(){
              bus.$emit('clickBntMore');
          }
        },
        created() {
            bus.$on('hideBtnMore',() => {
                this.show = false
            })

            bus.$on('showBtnMore',() => {
                this.show = true
            })
        }
    }
</script>

<style scoped>

</style>