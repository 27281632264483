import { render, staticRenderFns } from "./Subscribe.vue?vue&type=template&id=01f4bdec&scoped=true&"
import script from "./Subscribe.vue?vue&type=script&lang=js&"
export * from "./Subscribe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f4bdec",
  null
  
)

export default component.exports