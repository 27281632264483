<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Тип безнеса:</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <b-form-input
                                id="position"
                                v-model="business_type"
                                :state="errors('business_type').length == 0 ? null : false"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите тип бизнесса"
                                trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(errors('business_type'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.business_type = (_.find(this.relationMeta, (o) => o.meta_key == 'business_type')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                business_type: null,
                passible: [
                    'business_type'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'business_type',
                    value: this.business_type
                })
            }
        }
    }
</script>

<style scoped>

</style>
