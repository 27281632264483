<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Шалон:</h5>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <select name="" id="" class="form-control" v-model="pattern">
                        <option v-for="(pattern,index) in patterns" :key="index" :value="pattern">{{pattern}}</option>
                    </select>
                    <hr>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
    export default {
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.pattern  = (_.find(this.relationMeta, (o) => o.meta_key == 'pattern')).meta_value || {}
        },
        watch: {
            '$data': {
                handler(value) {
                    this.$emit('updateMeta', {
                        key: 'pattern',
                        value: this.pattern
                    })
                },
                deep: true
            }
        },
        data() {
            return {
                patterns : ['serviceppc','serviceseo','servicesmm'],
                pattern : null,
            }
        },
        methods: {

        }
    }
</script>


<style scoepd></style>