import objectToFormData from 'object-to-formdata'

export default {
    namespaced: true,
    state: {
        errors: [],
        roles: [],
        start_email: null,
        user: {
            id: false,
            email: null,
            password: "",
            password_confirmation: "",
            roles: [],
            profile: {},
            is_active: false,
            email_activated: false,
            sendConfirmationEmail: false,
            avatar : ''
        },
    },
    getters: {
        getName: (state) => (lang_id) => {
            var profile = state.user.profile[lang_id] || {}
            if (profile && profile.hasOwnProperty('name')) return profile.name;
            return ''
        },
        getInfo: (state) => (lang_id) => {
            var profile = state.user.profile[lang_id] || {}
            if (profile && profile.hasOwnProperty('info')) return profile.info;
            return ''
        },
        needSendConfirmation(state) {
            return (state.start_email != state.user.email && state.user.id !== false) || state.user.email_activated == false
        },
        getErrors(state) {
            return state.errors;
        },
        getRoles(state) {
            return state.roles;
        },
        getEmail(state) {
            return state.user.email;
        },
        getAvatar(state) {
            return state.user.avatar;
        },
        getId(state) {
            return state.user.id;
        },
        getUserRoles(state) {
            return state.user.roles;
        },
        getUserCompamy(state) {
            return state.user.company;
        },
        getPassword(state) {
            return state.user.password;
        },
        getUserPasswordConfirmation(state) {
            return state.user.password_confirmation;
        },
        getActive(state) {
            return state.user.is_active;
        },
        getEmailActivated(state) {
            return state.user.email_activated;
        },
        getCompany(state) {
            return state.user.company
        },
        getSendConfirmationEmail(state) {
            return state.user.sendConfirmationEmail
        }
    },
    mutations: {
        setListRoles(state, payload) {
            state.roles = payload;
        },
        resetAll: (state) => {
            state.errors = [];
            state.user = {
                id: false,
                email: "",
                name: "",
                password: "",
                password_confirmation: "",
                roles: "",
                profile : {},
                email_activated: false,
                is_active: false,
                avatar: '',
            }
        },
        setActive(state, payload) {
            state.user.is_active = payload;
        },
        setEmailActivated(state, payload) {
            state.user.email_activated = payload;
        },
        setSendConfirmationEmail(state, payload) {
            state.user.sendConfirmationEmail = payload;
        },
        setEmail: (state, payload) => {
            state.user.email = payload;
        },
        setAvatar: (state, payload) => {
            state.user.avatar = payload;
        },
        setName: (state, payload) => {
            state.user.profile[payload.lang_id] = state.user.profile[payload.lang_id] || {}
            state.user.profile = {...state.user.profile}
            state.user.profile[payload.lang_id].name = payload.value;
        },
        setInfo: (state, payload) => {
            state.user.profile[payload.lang_id] = state.user.profile[payload.lang_id] || {}
            state.user.profile = {...state.user.profile}
            state.user.profile[payload.lang_id].info = payload.value;
        },
        setRoles: (state, payload) => {
            state.user.roles = payload;
        },
        setPassword: (state, payload) => {
            state.user.password = payload;
        },
        setPasswordConfirm: (state, payload) => {
            state.user.password_confirmation = payload;
        },
        setUserID(state, payload) {
            state.user.id = payload;
        }
    },
    actions: {
        loadRole: (store, payload) => {
            window.axios.get('roles')
                .then(({data}) => {
                    store.state.roles = data.data;
                })
                .catch(e => {
                    console.log(e);
                })
        },
        loadUser: (store, payload) => {
            store.state.errors = [];
            window.axios.get('users/' + payload.id)
                .then(({data}) => {
                    store.state.start_email = data.data.email
                    store.state.user = Object.assign(data.data, {
                        sendConfirmationEmail: false,
                        password_confirmation: data.data.password
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        },
        saveUserData: (store, payload) => {
            return new Promise((resolve, reject) => {

                var data = store.state.user;
                var url = 'users/';
                if (store.getters['getId']) {
                    data._method = 'PUT';
                    url += store.getters['getId'];
                }

                store.state.errors = [];

                window.axios.post(url, data)
                    .then(({data}) => {
                        store.state.user = Object.assign(data.data, {password_confirmation: data.data.password});
                        resolve(data);
                    })
                    .catch(e => {
                        let response = e.response;
                        if (_.has(response, 'data.errors')) {
                            store.state.errors = response.data.errors
                        }
                        reject(e);
                    })
            });
        }
    }
}
