import router from './../../routes/routes'

export default {
    namespaced: true,
    state: {
        user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : false,
        user_role: []
    },
    mutations: {
        logout: (state) => {
            window.localStorage.removeItem('user')
            state.user = false
            window.willBeChanges = false;
            router.push('/login')

        },
        setUser: (state, user) => {
            state.user = user
            window.localStorage.setItem('user', JSON.stringify(user))
            window.willBeChanges = false;
        },
        setUserRole: (state, user) => {

        }
    },
    getters: {
        getCompany(state){
            return state.user ? state.user.company : false
        },
        loggedIn(state) {
            return state.user
        },
        filled(state) {
            return !!state.user.name
        }
    }
}
