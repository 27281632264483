<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Параметры</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок</label>
                            <b-form-input
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Адрес</label>
                            <b-form-input
                                    v-model="address"
                                    :state="localErrors('address').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('address'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Телефоны (Каждый телефон с новой строки)</label>
                            <b-form-textarea
                                    v-model="telephons"
                                    :state="localErrors('telephons').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('telephons'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Skype</label>
                            <b-form-input
                                    v-model="skype"
                                    :state="localErrors('skype').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('skype'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Telegram</label>
                            <b-form-input
                                    v-model="telegram"
                                    :state="localErrors('telegram').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('telegram'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Viber</label>
                            <b-form-input
                                    v-model="viber"
                                    :state="localErrors('viber').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('viber'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Письмо самому главному текст:</label>
                            <b-form-input
                                    v-model="letter_the_most_important_text"
                                    :state="localErrors('letter_the_most_important_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('letter_the_most_important_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Письмо самому главному значение:</label>
                            <b-form-input
                                    v-model="letter_the_most_important_value"
                                    :state="localErrors('letter_the_most_important_value').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('letter_the_most_important_value'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Отдел продаж: текст:</label>
                            <b-form-input
                                    v-model="sales_department_text"
                                    :state="localErrors('sales_department_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('sales_department_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Отдел продаж значение:</label>
                            <b-form-input
                                    v-model="sales_department_value"
                                    :state="localErrors('sales_department_value').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('sales_department_value'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Вакансии текст:</label>
                            <b-form-input
                                    v-model="vacancy_text"
                                    :state="localErrors('vacancy_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('vacancy_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Вакансии значение:</label>
                            <b-form-input
                                    v-model="vacancy_value"
                                    :state="localErrors('vacancy_value').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('vacancy_value'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Сотрудничество текст:</label>
                            <b-form-input
                                    v-model="cooperation_text"
                                    :state="localErrors('cooperation_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('cooperation_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Сотрудничество значение:</label>
                            <b-form-input
                                    v-model="cooperation_value"
                                    :state="localErrors('cooperation_value').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('cooperation_value'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Тех.отдел текст:</label>
                            <b-form-input
                                    v-model="technical_depart_text"
                                    :state="localErrors('technical_depart_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('technical_depart_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div role="group">
                        <div role="group">
                            <label>Тех.отдел значение:</label>
                            <b-form-input
                                    v-model="technical_depart_value"
                                    :state="localErrors('technical_depart_value').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('technical_depart_value'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>ИЛИ НАПИШИТЕ ПРЯМО СЕЙЧАС:</label>
                            <b-form-input
                                    v-model="write_now"
                                    :state="localErrors('write_now').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('write_now'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Приветствую! Меня зовут Александр и мой проект www:</label>
                            <b-form-input
                                    v-model="greeting"
                                    :state="localErrors('greeting').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('greeting'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Данная информация не будет передана 3-им лицам :</label>
                            <b-form-input
                                    v-model="nda"
                                    :state="localErrors('nda').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('nda'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    function getDefaultData() {
        return {
            heading: '',
            address: '',
            telephons: '',
            skype: '',
            telegram: '',
            viber: '',
            letter_the_most_important_text: '',
            letter_the_most_important_value: '',
            sales_department_text: '',
            sales_department_value: '',
            vacancy_text: '',
            vacancy_value: '',
            cooperation_text: '',
            cooperation_value: '',
            technical_depart_text: '',
            technical_depart_value: '',
            write_now: '',
            greeting: '',
            nda: ''
        }
    }

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var params = {};
            try {
                params = (_.find(this.relationMeta, (o) => o.meta_key == 'params')).meta_value || '';
            } catch (e) {
            }
            for (var key in params) {
                if (this.$data.hasOwnProperty(key)) this.$data[key] = params[key];
            }
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return getDefaultData();
        },
        methods: {
            getPassParam() {
                var dataParam = Object.keys(getDefaultData());
                var response = {};
                for (let key in this.$data)
                    if (dataParam.indexOf(key) !== -1)
                        response[key] = this.$data[key];
                return response;

            },
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl = (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`params.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'params',
                    value: {
                        ...this.getPassParam()
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
