<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Четвертый экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что хотят управленцы и владельцы? (заголовок)</label>
                            <b-form-input
                                    v-model="what_do_managers_and_owners_want_heading"
                                    :state="localErrors('what_do_managers_and_owners_want_heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_do_managers_and_owners_want_heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>РАЗВИТИЯ И ДОХОДНОСТИ БИЗНЕСА. ЭТО ВЫЗОВ! (Подзаголовок)</label>
                            <b-form-input
                                    v-model="development_and_profitability_of_business"
                                    :state="localErrors('development_and_profitability_of_business').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('development_and_profitability_of_business'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <label for="">Картинка 1</label><br>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('image_1')">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('image_1').length > 0 }"
                               class="form-control" v-model="image_1">
                    </div>
                </div>
                <div class="col-md-12" v-if="image_1">
                    <img style="max-width: 100%" class="mt-3"  :src="image_1"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label for="">Картинка 2</label><br>
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager('image_2')">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('image_2').length > 0 }"
                               class="form-control" v-model="image_2">
                    </div>
                </div>
                <div class="col-md-12" v-if="image_2">
                    <img style="max-width: 100%" class="mt-3"  :src="image_2"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>СДЕЛАЛО ИССЛЕДОВАНИЕ, В ХОДЕ КОТОРОГО:</label>
                            <b-form-input
                                    v-model="done_the_research_in_which"
                                    :state="localErrors('done_the_research_in_which').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('done_the_research_in_which'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>3000</label>
                            <b-form-input
                                    v-model="number_1"
                                    :state="localErrors('number_1').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('number_1'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст над 3000</label>
                            <b-form-textarea
                                    v-model="text_number_1"
                                    :state="localErrors('text_number_1').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_number_1'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>



                <!----->

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>3%</label>
                            <b-form-input
                                    v-model="procent_1"
                                    :state="localErrors('procent_1').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('procent_1'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст над 3%</label>
                            <b-form-textarea
                                    v-model="text_procent"
                                    :state="localErrors('text_procent').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_procent'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <!---->

            </div>

        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var fourth_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'fourth_screen')).meta_value || '';

            this.what_do_managers_and_owners_want_heading = fourth_screen.what_do_managers_and_owners_want_heading || '';
            this.development_and_profitability_of_business = fourth_screen.development_and_profitability_of_business || '';
            this.image_1 = fourth_screen.image_1 || '';
            this.image_2 = fourth_screen.image_2 || '';
            this.done_the_research_in_which = fourth_screen.done_the_research_in_which || '';
            this.number_1 = fourth_screen.number_1 || '';
            this.text_number_1 = fourth_screen.text_number_1 || '';
            this.procent_1 = fourth_screen.procent_1 || '';
            this.text_procent = fourth_screen.text_procent || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                what_do_managers_and_owners_want_heading: '',
                development_and_profitability_of_business : '',
                image_1 : '',
                image_2 : '',
                done_the_research_in_which : '',
                number_1 : '',
                text_number_1 : '',
                procent_1 : '',
                text_procent : ''
            }
        },
        methods: {
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl =  (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`fourth_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'fourth_screen',
                    value: {
                        what_do_managers_and_owners_want_heading: this.what_do_managers_and_owners_want_heading,
                        development_and_profitability_of_business : this.development_and_profitability_of_business,
                        image_1 : this.image_1,
                        image_2 : this.image_2,
                        done_the_research_in_which : this.done_the_research_in_which,
                        number_1 : this.number_1,
                        text_number_1 : this.text_number_1,
                        procent_1 : this.procent_1,
                        text_procent : this.text_procent
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
