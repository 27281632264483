<template>
    <card>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                        <span class="">Сохранить</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-textarea
                            id="textarea"
                            v-model="head"
                            rows="5"
                            max-rows="6"
                    ></b-form-textarea>
                </div>
            </div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        data() {
            return {
                head : '',
                errors : []
            }
        },
        computed: {

        },
        components: {
            Card
        },
        created(){
            axios.get('admin/setting/head')
                .then(({data}) => {
                    this.head = data.data.value;

                })
        },
        methods: {
            save(e) {
                addBtnLoading(e)
                this.errors = [];
                axios.put('admin/setting/head', {
                        key : 'head',
                        value : this.head
                    }
                ).then(({data}) => {

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)
                        this.loading = false;
                        this.errors = error.response.data.errors
                        window.notification.error(this.errorMessage);

                    });
            }
        }

    }

</script>
<style>

</style>
