<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Преимущества контекстной рекламы</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок
                                <code style="color: #B0140E">
                                    &lt;span>Любой текст<&frasl;span>
                                </code>- для выделения текста красным цветов
                            </label>
                            <b-form-textarea
                                    id="textarea"
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12">
                    <h5>Элементы</h5>
                    <p style="display: block" class="invalid-feedback" v-if="localErrors('elements').length">
                        {{_.head(localErrors('elements'))}}</p>
                    <table class="table table-hover table-bordered table-striper">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Короткий текст</th>
                                <th> <code style="color: #B0140E">
                                    &lt;span>Любой текст<&frasl;span>
                                </code>- для выделения текста красным цветов</th>
                            </tr>
                        </thead>
                        <tr v-for="(element,index) in elements" :key="element.id">
                            <td>{{index+1}}</td>
                            <td>
                                <b-form-input
                                        id="position"
                                        v-model="elements[index].short_text"
                                        aria-describedby="input-help input-live-position"
                                        placeholder=""
                                        :state="localErrors(`elements.${index}.short_text`).length == 0 ? null : false"
                                        trim
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-live-quotes">
                                    {{_.head(localErrors('elements.'+index+'.short_text'))}}
                                </b-form-invalid-feedback>
                            </td>
                            <td>
                                <b-form-textarea
                                        id="textarea"
                                        v-model="elements[index].text"
                                        :state="localErrors(`elements.${index}.text`).length == 0 ? null : false"
                                        rows="5"
                                        max-rows="6"
                                ></b-form-textarea>
                                <b-form-invalid-feedback id="input-live-quotes">
                                    {{_.head(localErrors('elements.'+index+'.text'))}}
                                </b-form-invalid-feedback>
                            </td>
                            <td>
                                <button class="btn-sm btn btn-fill btn-warning pull-right"
                                        @click="removeElements(index)">-
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <button class="btn-sm btn btn-fill btn-success pull-right" @click="addElements">+</button>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var benefits_context_advertising = (_.find(this.relationMeta, (o) => o.meta_key == 'benefits_context_advertising')).meta_value || '';

            this.heading = benefits_context_advertising.heading || '';
            this.elements = benefits_context_advertising.elements || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading: null,
                elements: []
            }
        },
        methods: {
            removeElements(index) {
                this.elements.splice(index, 1);
            },
            addElements() {
                this.elements.push({
                    text: "",
                    short_text: "",
                    id: Date.now()
                });
            },
            localErrors(key) {
                return this.errors(`project_was_made.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'benefits_context_advertising',
                    value: {
                        heading: this.heading,
                        elements: this.elements
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
