<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Контакты</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Заголовок:</label>
                        <b-form-input
                                id="position"
                                v-model="heading"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите имя клиента"
                                trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">

                        </b-form-invalid-feedback>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Адрес:</label>
                        <b-form-textarea
                                id="position"
                                v-model="address"
                                aria-describedby="input-help input-live-position"
                                placeholder="Введите имя клиента"
                                trim
                        ></b-form-textarea>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">

                        </b-form-invalid-feedback>
                    </div>
                </div>

            </div>
        </card>
    </div>
</template>

<script>

    export default {
        props : {
            editDescription : {
                type : Object,
                default : {}
            }
        },
        created(){
            this.heading = this.editDescription.heading || ''
            this.address = this.editDescription.address || ''
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading: null,
                address: '',
                passible: [
                    'client_information'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateInfo', {
                    key: 'contacts',
                    value: {
                        heading: this.heading,
                        address: this.address
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
