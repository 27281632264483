<template>
    <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <span class="display-1 d-block">403 Forbidden
                    </span>
                    <div class="mb-4 lead">The page you are looking for was not found.</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Error500"
    }
</script>

<style scoped>

</style>
