import Tag from '../../admin/pages/Tag/Tag'
import TagForm from '../../admin/pages/Tag/TagForm'
let route = [
    {
        path: 'cases/tag',
        name: 'cases-tag',
        component: Tag,
    },
    {
        path: 'cases/tag/create',
        name: 'cases-tag-create',
        component: TagForm
    },
    {
        path: 'cases/tag/:id/edit',
        name: 'cases-tag-edit',
        component: TagForm,
        props: true,
        meta: {
            breadcrumb: 'Post Edit'
        }
    },
]

export default route;
