<template>
    <div class="row">
        <div class="col-md-12">
            <p style="display: block" class="invalid-feedback" v-if="errors('content').length">
                {{_.head(errors('content'))}}</p>
            <quill-editor style="min-height:300px;" v-model="content"
                          ref="myQuillEditor"
                          :options="editorOption">
            </quill-editor>
        </div>
    </div>
</template>

<script>

    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'

    import watchParams from './mixin/watchParams'

    export default {
        mixins: [watchParams],
        props: {
            keyComponent: {
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        components: {
            quillEditor
        },
        data() {
            return {
                content: '',
                editorOption: {
                    height: '500px',
                },
                validate: {
                    content: ['required', 'min:1'],
                },
                passible: [
                    'content', 'validate'
                ],
            }
        },
        methods: {},
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill
            }
        },
    }
</script>

<style>
    .ql-container {
        min-height: inherit;
    }
</style>
