<template>
    <div class="row">
        <div class="col-12 mb-2 mt-2">
            <part-instruments @pushComponent="unshiftComponent($event)"></part-instruments>
        </div>
        <draggable class="list-group" tag="tbody" style="display: contents !important;" ghost-class="ghost"
                   v-model="data">
            <div class="col-12" v-for="(component,index) in data" :key="component.id" v-on:mouseup="componentRemoved()">
                <b-card header-tag="header">
                    <template slot="header">
                        <h6>{{component.component}}</h6>
                        <button class="btn-sm btn-danger btn-fill btn pull-right btn-circle"
                                @click="removeBlock(index)">&times;
                        </button>
                    </template>
                    <keep-alive>
                        <component :keyComponent="index" :lang_id="lang_id" :key="component.id"
                                   :is="component.component"
                                   :params="component.params"
                                   @updateParams="updateParams(index,$event)"></component>
                    </keep-alive>
                </b-card>

            </div>
        </draggable>
        <div class="col-12 mb-2 mt-2">
            <part-instruments v-if="data.length > 0" @pushComponent="pushComponent($event)"></part-instruments>
        </div>
    </div>
</template>

<script>
    import AppBlockquote from './components/blockquote'
    import AppTinyMc from './components/TinyMc'
    import AppFile from './components/File'
    import AppImage from './components/Image'
    import AppVideo from './components/Video'
    import AppList from './components/List'
    import PartInstruments from './PartInstrumnets'
    import AppTitle from './components/Title'

    export default {
        props: {
            paramsEdit: {
                type: Array,
                default: []
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        components: {
            AppList, AppVideo, AppBlockquote, AppTinyMc, AppFile, AppImage, PartInstruments,AppTitle
        },
        async created(){
            if(this.paramsEdit.length){
                _.map(this.paramsEdit,(item) => {
                   this.pushFromText(item);
                });
            }
        },
        data() {
            return {
                data: []
            }
        },
        watch: {
            data: {
                handler: function () {
                    this.$emit('newParts', this.data)
                },
                deep: true
            }
        },
        methods: {
            componentRemoved() {
                console.log('mouseup');
            },
            updateParams(index, e) {
                this.data[index].params = e;
            },
            removeBlock(index) {
                if (confirm('Are you sure')) {
                    this.data.splice(index, 1)
                }
                // console.log('------ removeBlock --------');
            },
            unshiftComponent(component) {
                let d = Date.now();
                this.data.unshift({
                    id: d,
                    component,
                    params: {}
                });
                // console.log('------ unshiftComponent --------');
            },
            pushFromText(item){
                let d = Date.now();
                this.data.push({
                    id: d,
                    component : item.component,
                    params: item.params
                });

                // ===========================================================================================
                // Задержка.
                // Если установить здесь задержку, то информация из перетаскиваемых блоков не будет изчезать.
                // ===========================================================================================
                // function delay(ms){
                //     setTimeout(function(){
                //         console.log('------ delay - '+ ms +' ms. --------');
                //     }, ms);
                // };
                //
                // delay(500);
                function sleep(ms) {
                    ms += new Date().getTime();
                    while (new Date() < ms){}
                }
                // ===========================================================================================
                sleep(100);
                console.log('------ pushFromText --------');

            },
            pushComponent(component) {
                let d = Date.now();
                this.data.push({
                    id: d,
                    component,
                    params: {}
                });
                // console.log('------ pushComponent --------');
            }
        }
    }
</script>

<style scoped>
    .buttons {
        margin-top: 35px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .btn-circle.btn-xl {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        border-radius: 35px;
        font-size: 24px;
        line-height: 1.33;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 1.42857;
    }
</style>
