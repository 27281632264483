<template>
  <div class="comments-section__register-socials">
    <span>
      Авторизируйтесь, чтобы оставить комментарий:
    </span>
    <div class="d-flex">
      <a href="#" v-on:click="AuthProvider('facebook')" class="comments-section__social-link">
        <i class="icon-facebook"></i>
      </a>
      <a href="#" v-on:click="AuthProvider('google')" class="comments-section__social-link">
        <i class="icon-google"></i>
      </a>

      <!--<button v-on:click="AuthProvider('facebook')" class="comments-section__social-link">-->
        <!--<i class="icon-facebook"></i>-->
      <!--</button>-->
      <!--<button v-on:click="AuthProvider('google')" class="comments-section__social-link">-->
        <!--<i class="icon-google"></i>-->
      <!--</button>-->

      <!--<a href="#" @click="AuthProvider('twitter')" class="comments-section__social-link">-->
        <!--<i class="icon-twitter"></i>-->
      <!--</a>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: "Auth",
    methods: {
      AuthProvider(provider) {
        var self = this

        this.$auth.authenticate(provider).then(response => {

          self.SocialLogin(provider, response)

        }).catch(err => {
          console.log({err: err})
        })

      },

      SocialLogin(provider, response) {

        // if(provider == 'twitter'){
        // var resp = JSON.parse(response.config.data) || response;
        // resp['oauth'] = response.data['oauthtemp'] || null;
        // }else{
        //     resp = response;
        // }

        this.$http.post(`${window.location.origin}/login/${provider}/callback`, response).then(response => {

            window.location.reload();

        }).catch(err => {
            console.log({err: err})
            window.location.reload();
        })

      },
    }
  }
</script>

<style scoped>

</style>
