<template>
    <div class="row">
        <div class="col-md-12">
            <div class="input-group">
                <div class="input-group-btn">
                    <a class="btn btn-info" @click="openFileManager">
                        <i class="fa fa-picture-o"></i> Choose
                    </a>
                </div>
                <input type="text" readonly :class="{'is-invalid':errors('main_image').length > 0 }" class="form-control" v-model="main_image">
            </div>
            <img style="width: 100%" class="mt-3" :src="main_image"/>
        </div>
        <div class="col-md-12">
            <div role="group">
                <label for="position">Подпись фотографии:</label>
                <b-form-input
                    id="position"
                    v-model="inscript"
                    :state="errors('inscript').length == 0 ? null : false"
                    aria-describedby="input-help input-live-position"
                    placeholder="Введите подпись фотографии"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('inscript'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import watchParams from './mixin/watchParams'

    export default {
        mixins:[watchParams],
        props: {
            keyComponent:{
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        data() {
            return {
                main_image: null,
                inscript: null,
                validate: {
                    main_image: ['required','exists:entity_files,path', 'max:4096'],
                    // inscript: ['required','min:1'],
                },
                passible: [
                    'main_image', 'inscript','validate'
                ]
            }
        },
        methods: {
            openFileManager() {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                var self = this
                window.SetUrl = function (items) {
                    self.main_image = items.replace(window.location.origin,'')
                }
                return false
            }
        }
    }
</script>

<style scoped>

</style>
