<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Второй экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что происходит в мире (заголовок)?</label>
                            <b-form-input
                                    v-model="what_happens_world_heading"
                                    :state="localErrors('what_happens_world_heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_happens_world'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что происходит в мире (текст)?</label>
                            <b-form-textarea
                                    v-model="what_happens_world"
                                    :state="localErrors('what_happens_world').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_happens_world'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что происходит с компаниями? (заголовок)?</label>
                            <b-form-input
                                    v-model="what_happens_company_heading"
                                    :state="localErrors('what_happens_company_heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_happens_company_heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что происходит с компаниями? (текст)?</label>
                            <b-form-textarea
                                    v-model="what_happens_company"
                                    :state="localErrors('what_happens_company').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_happens_company'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>ЧТОБЫ ОРГАНИЗАЦИЯ БЫЛА УСТОЙЧИВОЙ, ЕЙ НУЖНО ИСКАТЬ НОВЫЕ МОДЕЛИ, ИНСТРУМЕНТЫ И ПОСТОЯННО МЕНЯТЬСЯ</label>
                            <b-form-input
                                    v-model="to_organize_was_sustainable"
                                    :state="localErrors('to_organize_was_sustainable').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('to_organize_was_sustainable'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var second_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'second_screen')).meta_value || '';

            this.what_happens_world_heading = second_screen.what_happens_world_heading || '';
            this.what_happens_world = second_screen.what_happens_world || '';
            this.what_happens_company_heading = second_screen.what_happens_company_heading || '';
            this.what_happens_company = second_screen.what_happens_company || '';
            this.to_organize_was_sustainable = second_screen.to_organize_was_sustainable || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                what_happens_world_heading:'',
                what_happens_world: '',
                what_happens_company_heading: '',
                what_happens_company: '',
                to_organize_was_sustainable : ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`second_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'second_screen',
                    value: {
                        what_happens_world_heading: this.what_happens_world_heading,
                        what_happens_world: this.what_happens_world,
                        what_happens_company_heading: this.what_happens_company_heading,
                        what_happens_company: this.what_happens_company,
                        to_organize_was_sustainable : this.to_organize_was_sustainable
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
