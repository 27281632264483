<template>
    <card>
        <h5 slot="header" class="card-title">Дополнительная информация</h5>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <label for="quotes">Прочтете за....</label>
                    <b-form-input
                        id="quotes"
                        aria-describedby="input-quotes-help input-live-quotes"
                        placeholder=""
                        v-model="read_for"
                        trim
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-quotes">
                    </b-form-invalid-feedback>
                </div>
            </div>
        </div>
    </card>
</template>
<script>

    export default {
        mixins: [],
        components: {

        },
        props: {
            relationMeta : {
                type : [Object]
            }
        },
        created(){
            this.read_for  = (_.find(this.relationMeta,(o) => o.meta_key == 'read_for')).meta_value || ''
        },
        watch : {
            '$data' : {
                handler(value){
                    this.passToParrentData(value);
                },
                deep : true
            }
        },
        methods:{
            passToParrentData(value) {
                let pass = {};
                for (var cur in value) {
                    if (this.pass.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                this.$emit('updateMeta', {
                    key : 'read_for',
                    value : this.read_for
                })
            },
        },
        data() {
            return {
                read_for : '',
                pass : ['read_for']
            }
        }
    }
</script>

<style scoped>

</style>
