<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Седьмой экран</h5>
            <div class="row">

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Для кого цифровая
                                трансформация? (Заголовок)</label>
                            <b-form-input
                                    v-model="for_whom_digital_transformation_head"
                                    :state="localErrors('for_whom_digital_transformation_head').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('for_whom_digital_transformation_head'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Для кого цифровая
                                трансформация?(Текст)</label>
                            <b-form-textarea
                                    v-model="for_whom_digital_transformation_text"
                                    :state="localErrors('for_whom_digital_transformation_text').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('for_whom_digital_transformation_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var seventh_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'seventh_screen')).meta_value || '';
            this.for_whom_digital_transformation_text = seventh_screen.for_whom_digital_transformation_text || '';
            this.for_whom_digital_transformation_head = seventh_screen.for_whom_digital_transformation_head || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                for_whom_digital_transformation_text:'',
                for_whom_digital_transformation_head : ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`seventh_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'seventh_screen',
                    value: {
                        for_whom_digital_transformation_head:this.for_whom_digital_transformation_head,
                        for_whom_digital_transformation_text: this.for_whom_digital_transformation_text
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
