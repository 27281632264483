<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Пятый экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Что делать?</label>
                            <b-form-input
                                    v-model="what_to_do"
                                    :state="localErrors('what_to_do').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('what_to_do'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>



                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст 1</label>
                            <b-form-input
                                    v-model="text_1"
                                    :state="localErrors('text_1').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_1'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст 2</label>
                            <b-form-input
                                    v-model="text_2"
                                    :state="localErrors('text_2').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_2'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст 3</label>
                            <b-form-input
                                    v-model="text_3"
                                    :state="localErrors('text_3').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text_3'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>



        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var fives_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'fives_screen')).meta_value || '';

            this.what_to_do = fives_screen.what_to_do || '';
            this.text_1 = fives_screen.text_1 || '';
            this.text_2 = fives_screen.text_2 || '';
            this.text_3 = fives_screen.text_3 || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                what_to_do:'',
                text_1: '',
                text_2 : '',
                text_3 : ''
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`fives_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'fives_screen',
                    value: {
                        what_to_do: this.what_to_do,
                        text_1: this.text_1,
                        text_2: this.text_2,
                        text_3: this.text_3
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
