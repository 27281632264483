<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Связанные статьи:</h5>
        <div class="row">
            <div class="col-md-12">
                <div role="group">
                    <autocomplete
                            ref="autocomplete"
                            placeholder="Search Active Users"
                            :source="distributionGroupsEndpoint"
                            input-class="form-control"
                            results-property="data"
                            :showNoResults="false"
                            :results-display="formattedDisplay"
                            :request-headers="authHeaders()"
                            @selected="addDistributionGroup">
                    </autocomplete>
                    <div ref="post" v-if="relation_case">
                        <div  v-if="relation_case.length">
                            <card class="strpied-tabled-with-hover"
                                  body-classes="table-full-width table-responsive"
                            >

                                <table class="table table-hover table-striped table-sm">
                                    <thead>
                                    <slot name="columns">
                                        <tr>
                                            <th></th>
                                            <th>Ссылка</th>
                                            <th></th>
                                        </tr>
                                    </slot>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(tag, index) in relation_case" :key="index">
                                        <td>{{(index+1)}}</td>
                                        <td><span class="badge badge-success">
                                            <router-link target="_blank" :to="{name : 'cases-edit',params : {id : tag}}">Ссылка : {{tag}}</router-link>
                                        </span></td>
                                        <td>
                                            <span style="cursor: pointer" @click="remove(tag.id)">x</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </card>

                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
    export default {
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.relation_case  = (_.find(this.relationMeta, (o) => o.meta_key == 'relation_case')).meta_value || []
        },
        watch: {
            '$data': {
                handler(value) {
                    this.$emit('updateMeta', {
                        key: 'relation_case',
                        value: this.relation_case
                    })
                },
                deep: true
            }
        },
        data() {
            return {
                relation_case: false,
                tags: []
            }
        },
        methods: {
            remove(id) {
                this.relation_case = _.filter(this.relation_case, (o) => o.id != id);
            },
            distributionGroupsEndpoint(input) {
                return '/api/v1/admin/case?query=' + input
            },
            addDistributionGroup(group) {
                this.relation_case = _.filter(this.relation_case, (o) => group.selectedObject.id != o.id);
                this.relation_case.push(group.selectedObject.id);
                this.$refs.autocomplete.clear()
            },
            authHeaders() {
                return {
                    'Authorization': 'Bearer ' + auth.getToken()
                }
            },
            formattedDisplay(result) {
                return result.title
            },
        }
    }
</script>


<style scoepd></style>