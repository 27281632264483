export default{
    props : {
        lang_id : {
            type : [String,Number]
        }
    },
    methods : {
        errors(key) {
            let error = this.$store.getters['errors/getError'](`post_description.${this.lang_id}.meta.${key}`);
            if (Array.isArray(error))
                return error;
            return [];
        },
    }
}