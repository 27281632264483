<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Цели SMM бывают разные:: <em>Используйте тег
                <code style="color: #B0140E">
                    &lt;span>Любой текст<&frasl;span>
                </code>- для выделения текста красным цветов</em></h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <label for="">Заголовок</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="heading"
                                :state="localErrors('heading').length == 0 ? null : false"
                                rows="5"
                                max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('heading'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <label for="">Текст (enter = новый елемент)</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="text"
                                :state="localErrors('text').length == 0 ? null : false"
                                rows="5"
                                max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('text'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var smm_goals = (_.find(this.relationMeta, (o) => o.meta_key == 'smm_goals')).meta_value || '';
            this.heading = smm_goals.heading;
            this.text = smm_goals.text;
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading: null,
                text: null
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`request.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'smm_goals',
                    value: {
                        heading : this.heading,
                        text : this.text
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
