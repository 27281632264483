<template>
    <div class="d-flex flex-column">
        <p class="text-primary text-uppercase font-weight-bold mb-2">{{writeNow}}</p>
        <div class="contact-form__item">
                                        <textarea
                                                v-model="text"
                                                :class="{'input-error':hasError('text')}"
                                                name="lirax_deal_cf_193749" id="form-message"
                                                  :placeholder="greeting"></textarea>
        </div>
        <div class="contact-form__item">
            <input type="hidden" name="lang" v-model="lang">
            <input
                    v-model="email" name="email"
                    :class="{'input-error':hasError('email')}"
                    type="email" :placeholder="text_email">
        </div>
        <div class="contact-form__item">
            <input type="tel"
                   :class="{'input-error':hasError('phone')}"
                   v-model="phone" name="phone"
                   v-mask="'+38(###)-##-###-##'"
                   placeholder="+38(__) ___ __ __">
        </div>
        <span class="error-container"
              :class="{
                          'active' : response.message.length,
                          'success-container' : !hasErrors,
                          'error-container' : hasErrors
                      }">{{response.message}}</span>

        <input type="hidden" name="lirax_deal_cf_490941" id="lirax_deal_cf_490941" value="">

        <div class="contact-form__submit">
            <p :class="class_text_nda">
                {{text_nda}}</p>
            <button @click.prevent="send('contact-form2')" :disable="sending" :class="class_btn_send" type="submit">
                {{text_btn_send}}<i class="icon-arrow"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import FormSendMixin from './mixins/formSendMixin'

    export default {
        mixins: [FormSendMixin],
        props: {
            class_text_nda : {
              type : String,
              default : 'text-primary contact-form__note'
            },
            class_btn_send : {
                type : String,
                default : 'cta-btn cta-btn--red'
            },
            writeNow: {
                type: String,
                default: ''
            },
            greeting: {
                type: String,
                default: ''
            },
            text_email: {
                type: String,
                default: ''
            },
            text_nda: {
                type: String,
                default: ''
            },
            text_btn_send: {
                type: String,
                default: ''
            },
            url_send : {
                type : String
            },
            lang : {
                type : String
            }
        }
    }
</script>

<style scoped>

</style>
