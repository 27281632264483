<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Блок перелинковки</h5>
        <div class="row">
            <div class="col-md-12">
                <b-form-group>
                    <b-form-checkbox
                            v-for="(option,key) in data"
                            v-model="option.checked"
                            :key="option.id"
                            :value="option.id"
                            name="flavour-3a"
                    >
                        {{ option.text }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <div class="col-md-5">
                <button class="btn btn-sm btn-success btn-fill btn-block" v-on:click="save">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                    <span class="">Сохранить</span>
                </button>
            </div>
        </div>

    </card>
</template>


<script>
    import Card from '../../components/Cards/Card.vue'
    export default {

        props: {
            uri : {
                type : String,
                default : ''
            }
        },
        components: {
            Card
        },
        data() {
            return {
                'data' : {}
            }
        },

        mounted() {
            console.log('uri = ' + this.uri);
            axios.post('admin/get-links-block',
                {
                    'uri': this.uri
                })
                .then((response)=>{
                    this.data = response.data;
                })
                .catch((error)=>{
                console.log(error.response.data)
            });
        },

        watch:{
            'data': {
                handler() {
                    this.passToParrentData();
                },
                deep: true
            },
        },
        methods: {
            passToParrentData() {
                for (var i = 0; i < this.data.length; i++ ){
                    if(this.data[i]['checked'] == true) {
                        this.data[i]['checked'] = this.data[i]['id'];
                    }
                }
            },

            save(){
                axios.post('admin/get-links-block',
                    {
                        // 'data' : JSON.stringify(this.getDataForSave()),
                        'data' : JSON.stringify(this.getDataForSave()),
                        'save': 'true',
                        'uri': this.uri
                    })
                    .then((response)=>{
                        window.notification.success('Сохранение прошло успешно');
                        // console.log(response.data);
                    })
                    .catch((error)=>{
                        window.notification.error(error.response.data.message);
                        console.log(error.response.data);
                });
            },

            getDataForSave() {
                var saveData = {...this.data};

                for (var key in saveData){
                    saveData[key]['checked'] = (saveData[key]['checked'] !== false);
                }
                // for (var i = 0; i < Object.keys(saveData).length; i++ ){
                //     saveData[i]['checked'] = (saveData[i]['checked'] !== false);
                // }

                return saveData;
            },

        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
