<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Требования (каждый пункт с новой строки)</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <b-form-textarea
                                    id="textarea"
                                    v-model="requirements"
                                    :state="errors('requirements').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(errors('requirements'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.requirements = (_.find(this.relationMeta, (o) => o.meta_key == 'requirements')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                requirements: null,
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'requirements',
                    value: this.requirements
                })
            }
        }
    }
</script>

<style scoped>

</style>
