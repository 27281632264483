export default {
    date(){
      return {
          loading : false,
      }
    },
    computed:{
        errors(){
            return this.$store.getters['user/getErrors'];
        },
        getUserID(){
            return this.$store.getters['user/getId'];
        }
    },
    methods:{
        notifyVue (verticalAlign, horizontalAlign,color,message) {
            this.$notifications.notify(
                {
                    message: message,
                    icon: 'nc-icon nc-app',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: color
                })
        },
        remove(){
            window.axios.delete('users/'+this.getUserID)
                .then(() => {
                    this.notifyVue('bottom','right','warning','Remove Success');
                    if(this.getUserID){
                        window.willBeChanges = false;
                        this.$router.push({ name: 'users'});
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        save(){
            this.loading = true;
            this.$store.dispatch('user/saveUserData')
                .then(() => {
                    this.notifyVue('bottom','right','info','Save Success');
                    if(this.getUserID && this.$route.name != 'profile'){
                        window.willBeChanges = false;
                        this.$router.push({ name: 'users-edit', params: { id: this.getUserID } })
                    }
                    this.loading =false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.notifyVue('bottom','left','danger',e.response.data.message);
                })
        }
    }
}
