<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Теги</h5>
        <div class="row">
            <div class="col-md-12">
                <autocomplete
                    ref="autocomplete"
                    placeholder="Search Active Users"
                    :source="distributionGroupsEndpoint"
                    input-class="form-control"
                    results-property="data"
                    :showNoResults="false"
                    :results-display="formattedDisplay"
                    :request-headers="authHeaders()"
                    @selected="addDistributionGroup">
                </autocomplete>
            </div>
            <hr>
            <div class="col-12" v-if="tags.length">
                <card class="strpied-tabled-with-hover"
                      body-classes="table-full-width table-responsive"
                >

                    <table class="table table-hover table-striped table-sm">
                        <thead>
                        <slot name="columns">
                            <tr>
                                <th></th>
                                <th>Тег</th>
                                <th></th>
                            </tr>
                        </slot>
                        </thead>
                        <tbody>
                        <tr v-for="(tag, index) in tags" :key="index">
                            <td>{{(index+1)}}</td>
                            <td><span class="badge badge-success">{{tag.name}}</span></td>
                            <td>
                                <span style="cursor: pointer" @click="remove(tag.id)">x</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </card>

            </div>
        </div>
    </card>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'
    import {mapGetters} from 'vuex'

    export default {
        props : {
            relationTags : {
                type : [Object]
            }
        },
        components: {
            Card, LTable
        },
        data() {
            return {
                loading: false,
                tags: []
            }
        },
        watch:{
            tags(){
                this.passTags();
            }
        },
        created() {
            this.tags = this.relationTags;
            this.passTags();
        },
        methods: {
            passTags(){
                this.$emit('setTags',this.tags);
            },
            remove(id) {
                this.tags = _.filter(this.tags, (o) => o.id != id);
            },
            distributionGroupsEndpoint(input) {
                return '/api/v1/admin/case/tag?query=' + input
            },
            addDistributionGroup(group) {
                this.tags = _.filter(this.tags, (o) => group.selectedObject.id != o.id);
                this.tags.push(group.selectedObject);
                this.$refs.autocomplete.clear()
            },
            authHeaders() {
                return {
                    'Authorization': 'Bearer ' + auth.getToken()
                }
            },
            formattedDisplay(result) {
                return result.name
            },
        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
