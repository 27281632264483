<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Автор</h5>
        <div class="row">
            <div class="col-md-12">
                <autocomplete
                    ref="autocomplete"
                    placeholder="Search Active Users"
                    :source="distributionGroupsEndpoint"
                    input-class="form-control"
                    results-property="data"
                    :showNoResults="false"
                    :results-display="formattedDisplay"
                    :request-headers="authHeaders()"
                    @selected="addDistributionGroup">
                </autocomplete>
            </div>
            <hr>
            <div class="col-12">
                <card class="strpied-tabled-with-hover"
                      body-classes="table-full-width table-responsive"
                >

                    <table class="table table-hover table-striped table-sm" v-if="author.email">
                        <thead>
                        <slot name="columns">
                            <tr>
                                <th>#</th>
                                <th>Имя</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </slot>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>{{author.name}}</td>
                            <td>{{author.email}}</td>
                            <td>
                                <span style="cursor: pointer" @click="remove(author.id)">x</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </card>

            </div>
        </div>
    </card>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'
    import {mapGetters} from 'vuex'

    export default {
        props : {
            relationAuthor : {
                type : [Object]
            }
        },
        components: {
            Card, LTable
        },
        data() {
            return {
                loading: false,
                author: {
                    id: null,
                    name: null,
                    email: null
                }
            }
        },
        created() {
            this.author.id = this.relationAuthor.id || null
            this.author.name = this.relationAuthor.name || null
            this.author.email = this.relationAuthor.email || null
            this.passAuthor();
        },
        watch:{
          author(){
             this.passAuthor();
          }
        },
        methods: {
            passAuthor(){
                this.$emit('setAuthor',this.author)
            },
            remove(id) {
                Object.assign(this.$data, this.$options.data())
            },
            distributionGroupsEndpoint(input) {
                return '/api/v1/users?role=author&query=' + input
            },
            addDistributionGroup(group) {

                this.author = group.selectedObject;

                this.$refs.autocomplete.clear()
            },
            authHeaders() {
                return {
                    'Authorization': 'Bearer ' + auth.getToken()
                }
            },
            formattedDisplay(result) {
                return result.name + ' [' + result.email + ']'
            },
        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
