import PpcForm from './../../admin/pages/Services/PPC/Form'
import SeoForm from './../../admin/pages/Services/SEO/Form'
import SmmForm from '../../admin/pages/Services/SMM/Form'
import List from '../../admin/pages/Services/Index'
import Form from '../../admin/pages/Services/Form'
export default [
    {
        path: 'services/',
        name: 'service-index',
        component: List,
        props : {
            edit : true
        }
    },
    {
        path: 'services/create',
        name: 'service-create',
        component: Form
    },
    {
        path: 'services/ppc/edit',
        name: 'service-ppc-edit',
        component: PpcForm,
        props : {
            edit : true
        }
    },
    {
        path: 'services/seo/edit',
        name: 'service-seo-edit',
        component: SeoForm,
        props : {
            edit : true
        }
    },
    {
        path: 'services/smm/edit',
        name: 'service-smm/edit',
        component: SmmForm,
        props : {
            edit : true
        }
    },
    {
        path: 'services/:id/edit',
        name: 'service-edit',
        component: Form,
        props : true
    }
]