<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Город</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <b-form-input
                                    id="textarea"
                                    v-model="location"
                                    :state="errors('location').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(errors('location'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.location = (_.find(this.relationMeta, (o) => o.meta_key == 'location')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                location: null,
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'location',
                    value: this.location
                })
            }
        }
    }
</script>

<style scoped>

</style>
