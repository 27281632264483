<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Экран партнера</h5>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input readonly type="text" :class="{'is-invalid':localErrors('image').length > 0 }"
                               class="form-control" v-model="image">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label>
                        <code style="color: #B0140E">
                            &lt;span>Любой текст<&frasl;span>
                        </code>- для выделения текста красным цветов
                    </label>
                        <b-form-textarea
                                id="textarea"
                                v-model="text"
                                :state="localErrors('text').length == 0 ? null : false"
                                rows="5"
                                max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(localErrors('text'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var partner = (_.find(this.relationMeta, (o) => o.meta_key == 'partner')).meta_value || '';

            this.text = partner.text || '';
            this.image = partner.image || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                image: null,
                text: null
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`partner.${key}`);
            },
            openFileManager() {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );
                var self = this
                window.SetUrl = function (items) {
                    self.image = items.replace(window.location.origin, '')
                }
                return false
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'partner',
                    value: {
                        text: this.text,
                        image: this.image
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
