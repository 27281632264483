<template>
    <card>
        <h4 slot="header" class="card-title">Пароль</h4>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <base-input type="text"
                                label="Password"
                                placeholder="User Password"
                                :inputClass="_.has(errors,'password') ? 'is-invalid' : ''"
                                v-model="password">
                        <template v-slot:error_message>
                            <div class="invalid-feedback" v-if="_.has(errors,'password')">
                                {{_.head(errors['password'])}}
                            </div>
                        </template>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input type="text"
                                label="Confirm Password"
                                placeholder="Confirm User password"
                                v-model="password_confirmation">
                    </base-input>
                </div>
            </div>

            <div class="clearfix"></div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'

    import ErrorMixin from '../../mixim/UserMixin'

    export default {
        mixins:[ErrorMixin],
        components: {
            Card
        },
        data () {
            return {

            }
        },
        computed: {
            password: {
                get() {
                    return this.$store.getters['user/getPassword']
                },
                set(value) {
                    return this.$store.commit('user/setPassword', value)
                }
            },
            password_confirmation: {
                get() {
                    return this.$store.getters['user/getUserPasswordConfirmation']
                },
                set(value) {
                    return this.$store.commit('user/setPasswordConfirm', value)
                }
            }
        }
    }

</script>
<style>

</style>
