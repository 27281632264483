<template>
    <card class="card-user">
        <h5 slot="header" class="card-title">Контакты</h5>
        <div class="row">
            <div class="col-md-12">
                <label for="">Телефон</label>
                <b-form-input
                        id="position"
                        v-model="tel"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Skype</label>
                <b-form-input
                        id="position"
                        v-model="skype"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Телеграм</label>
                <b-form-input
                        id="position"
                        v-model="telegram"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12">
                <label for="">Вайбер</label>
                <b-form-input
                        id="position"
                        v-model="viber"
                        aria-describedby="input-help input-live-position"
                        placeholder=""
                        trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-quotes">

                </b-form-invalid-feedback>
            </div>
        </div>
    </card>
</template>
<script>
    import LTable from '../../../../components/Table.vue'
    import Card from '../../../../components/Cards/Card.vue'

    export default {
        props: {
            infoForEdit: {
                type: Object,
                default: {}
            },
            relationTags: {
                type: [Object]
            }
        },
        components: {
            Card, LTable
        },
        data() {
            return {
                loading: false,
                tel: '',
                skype: '',
                telegram: '',
                viber: ''
            }
        },
        watch: {
            '$data': {
                handler() {
                    this.passDataToParrent();
                },
                deep: true
            }
        },
        mounted() {

            var property = this.infoForEdit.contacts;
            if (property.hasOwnProperty('tel')) {
                this.tel = property.tel;
            }
            if (property.hasOwnProperty('skype')) {
                this.skype = property.skype;
            }
            if (property.hasOwnProperty('telegram')) {
                this.telegram = property.telegram;
            }
            if (property.hasOwnProperty('viber')) {
                this.viber = property.viber;
            }

        },
        methods: {
            passDataToParrent() {
                this.$emit('setInfo', {
                    key: 'contacts',
                    value: {
                        tel: this.tel,
                        viber: this.viber,
                        skype: this.skype,
                        telegram: this.telegram
                    }
                });
            }
            ,
        }
    }

</script>
<style>
    .card-user .card-body {
        min-height: 0px !important;
    }
</style>
