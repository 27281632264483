<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Первый экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>ПИСЬМО САМОМУ ГЛАВНОМУ:</label>
                            <b-form-input
                                    v-model="letter_for_most_import"
                                    :state="localErrors('letter_for_most_import').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('letter_for_most_import'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Email:</label>
                            <b-form-input
                                    v-model="letter_for_most_import_email"
                                    :state="localErrors('letter_for_most_import_email').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('letter_for_most_import_email'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Трансформируем организации в компании будущего</label>
                            <b-form-input
                                    v-model="we_transform_organizations"
                                    :state="localErrors('we_transform_organizations').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('we_transform_organizations'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var first_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'first_screen')).meta_value || '';

            this.letter_for_most_import = first_screen.letter_for_most_import || '';
            this.letter_for_most_import_email = first_screen.letter_for_most_import_email || '';
            this.we_transform_organizations = first_screen.we_transform_organizations || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                letter_for_most_import: 'ПИСЬМО САМОМУ ГЛАВНОМУ:',
                letter_for_most_import_email : 'chief@seomasters.com.ua',
                we_transform_organizations : 'Трансформируем организации в компании будущего',
            }
        },
        methods: {
            localErrors(key) {
                return this.errors(`first_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'first_screen',
                    value: {
                        letter_for_most_import: this.letter_for_most_import,
                        letter_for_most_import_email: this.letter_for_most_import_email,
                        we_transform_organizations: this.we_transform_organizations
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
