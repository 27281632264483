import PostComments from '../../admin/pages/PostComments/PostComments'
import PostCommentsForm from'../../admin/pages/PostComments/PostCommentsForm'
import TagForm from "../../admin/pages/Tag/TagForm";
// import TagForm from '../../admin/pages/Tag/TagForm'
let route = [
    {
        path: 'cases/comments',
        name: 'cases-comments',
        component: PostComments,
    },
    {
        path: 'cases/comment/:id/edit',
        name: 'cases-comment-edit',
        component: PostCommentsForm,
        props: true,
        meta: {
            breadcrumb: 'CommentsEdit'
        }
    },
];

export default route;
