<template>
    <div class="content">
        <div class="container-fluid" v-if="!startLoading">
            <div class="row">
                <div class="col-12">
                    <div>
                        <b-tabs>
                            <b-tab :title-item-class="getClassForTitleTab(language.id)" v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <post-description :default-slug="'home'" :enable-slug="false" card-title="Информация"
                                                  :enablePartPost="[]"
                                                  :description="getPropsByKey(language.id)" :lang_id="language.id"
                                                  @updateData="setDescriptionData(language.id,$event)">
                                    <template v-slot:meta_info="slotProps">
                                        <first-screen :lang_id="language.id"
                                                      :relation-meta="slotProps.description.meta"
                                                      @updateMeta="slotProps.method($event)"></first-screen>
                                        <second-screen :lang_id="language.id"
                                                       :relation-meta="slotProps.description.meta"
                                                       @updateMeta="slotProps.method($event)"></second-screen>
                                        <third-screen :lang_id="language.id"
                                                      :relation-meta="slotProps.description.meta"
                                                      @updateMeta="slotProps.method($event)"></third-screen>

                                        <fourth-screen :lang_id="language.id"
                                                       :relation-meta="slotProps.description.meta"
                                                       @updateMeta="slotProps.method($event)"></fourth-screen>

                                        <fives-screen :lang_id="language.id"
                                                      :relation-meta="slotProps.description.meta"
                                                      @updateMeta="slotProps.method($event)"></fives-screen>

                                        <six-screen :lang_id="language.id"
                                                    :relation-meta="slotProps.description.meta"
                                                    @updateMeta="slotProps.method($event)"></six-screen>

                                        <seventh-screen :lang_id="language.id"
                                                        :relation-meta="slotProps.description.meta"
                                                        @updateMeta="slotProps.method($event)"></seventh-screen>

                                        <eighth-screen :lang_id="language.id"
                                                       :relation-meta="slotProps.description.meta"
                                                       @updateMeta="slotProps.method($event)"></eighth-screen>
                                    </template>
                                </post-description>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <post-data :date-pub="false" :logoImage="false" :editData="postForEditing"
                               @updateData="setGeneralData($event)"
                               @publish="publish">
                    </post-data>

                    <links-block uri="home"></links-block>

                </div>

            </div>
        </div>
    </div>
</template>


<script>
    import PostData from './../Post/PostData'
    import RelationTag from './../Post/Relation/Taggable'
    import PostDescription from './../Post/PostDescription'
    import FirstScreen from './Home/FirstScreen'
    import SecondScreen from './Home/SecondScreen'
    import ThirdScreen from './Home/ThirdScreen'
    import FourthScreen from './Home/FourthScreen'
    import FivesScreen from './Home/FivesScreen'
    import SixScreen from './Home/SixScreen'
    import SeventhScreen from './Home/SeventhScreen'
    import EighthScreen from './Home/EighthScreen'

    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    import LinksBlock from '../../components/LinksBlock'

    export default {
        props: {
            edit: {
                type: Boolean,
                default: false
            }
        },
        components: {
            PostData,
            PostDescription,
            RelationTag,
            FirstScreen,
            SecondScreen,
            ThirdScreen,
            FourthScreen,
            FivesScreen,
            SixScreen,
            SeventhScreen,
            EighthScreen,
            LinksBlock
        },
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: this.getDefaultUrl()
                },
                startLoading: true,
                languages: [],

                postForEditing: {},
                post: {
                    meta: {},
                    type: 'page',
                    relations: {
                        tags: null
                    },
                    post_description: {}
                }
            }
        },
        async beforeCreate() {
            await axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                })
                .then(() => {
                    axios.get(`admin/case/` + this.post.type+'/home')
                        .then(({data}) => {
                                this.$set(this.$data, 'postForEditing', data.data);
                                this.startLoading = false;
                                this.paramsSave.method = 'put'
                                this.paramsSave.url = this.getDefaultUrl()+ this.post.type +'/home'
                            }
                        ).catch(() => {
                        this.startLoading = false;
                    })
                })
                .catch(() => {
                    this.startLoading = false;
                })
        },
        computed: {
            errors() {
                return this.$store.getters['errors/getErrors'];
            }
        }
        ,
        methods: {
            getDefaultUrl() {
                return 'admin/case/';
            },
            updateMeta(e) {
                this.post.meta[e.key] = e.value;
            },
            setTags(tags) {
                this.post.relations.tags = tags;
            },
            getPropsByKey(key) {
                if (this.postForEditing && this.postForEditing.hasOwnProperty('description')) {
                    let description = _.find(this.postForEditing.description, (o) => o.language_id == key);
                    return description ? description : {}
                }
                return {}
            }
            ,
            getClassForTitleTab(index) {
                var hasLangError = false;
                for (let cur in this.errors) {
                    if (cur.indexOf(`post_description.${index}`) !== -1) {
                        hasLangError = true;
                        break;
                    }
                }

                if (hasLangError) {
                    return 'has-error'
                }

                return '';
            }
            ,
            publish(e) {
                axios[this.paramsSave.method](this.paramsSave.url, {
                    ...this.post
                }).then(({data}) => {
                    this.paramsSave.method = 'put';
                    this.paramsSave.url = this.getDefaultUrl() + this.post.type +'/home'
                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message

                        window.notification.error(this.errorMessage);

                    });
            }
            ,
            setDescriptionData(language, data) {
                this.post.post_description[language] = data;

            }
            ,
            setGeneralData($event) {
                for (let property in $event) {
                    this.post[property] = $event[property]
                }
            }
        }
        ,
        watch: {}
    }
</script>


<style>
    .tab-pane {
        outline: none;
    }

    .has-error {
        background: #dc3545;
    }

    .has-error a {
        color: #ffffff;
    }
</style>
