<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Наши достижения с клиентами</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок</label>
                            <b-form-input
                                    id="textarea"
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h5>Элементы</h5>
                    <p style="display: block" class="invalid-feedback" v-if="localErrors('elements').length">
                        {{_.head(localErrors('elements'))}}</p>
                    <table class="table table-hover table-bordered table-striper">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th> <code style="color: #B0140E">
                                &lt;span>Любой текст<&frasl;span>
                            </code>- для выделения текста красным цветов</th>
                        </tr>
                        </thead>
                        <tr v-for="(element,index) in elements" :key="element.id">
                            <td>
                                {{index+1}}
                                <button class="btn-sm btn btn-fill pull-up"
                                        @click="upElements(index)"
                                        style="margin-top:10px; margin-bottom:10px;">
                                    ↑
                                </button>
                                <button class="btn-sm btn btn-fill pull-down"
                                        @click="downElements(index)">
                                    ↓
                                </button>
                            </td>
                            <td>
                               <div class="row">
                                   <div class="col-md-12">
                                       <label>Заголовок</label>
                                       <b-form-input
                                               v-model="elements[index].heading"
                                               :state="localErrors(`elements.${index}.heading`).length == 0 ? null : false"
                                               rows="5"
                                               max-rows="6"
                                       ></b-form-input>
                                       <b-form-invalid-feedback id="input-live-quotes">
                                           {{_.head(localErrors('elements.'+index+'.heading'))}}
                                       </b-form-invalid-feedback>
                                   </div>

                                   <div class="col-md-12">
                                       <label>Ссылка "Узнать больше" (slug блога)</label>
                                       <b-form-input
                                               v-model="elements[index].link_more"
                                               :state="localErrors(`elements.${index}.heading`).length == 0 ? null : false"
                                               rows="5"
                                               max-rows="6"
                                       ></b-form-input>
                                       <b-form-invalid-feedback id="input-live-quotes">
                                           {{_.head(localErrors('elements.'+index+'.link_more'))}}
                                       </b-form-invalid-feedback>
                                   </div>

                                   <div class="col-md-12">
                                       <label>Текст</label>
                                       <b-form-textarea
                                               v-model="elements[index].text"
                                               :state="localErrors(`elements.${index}.text`).length == 0 ? null : false"
                                               rows="5"
                                               max-rows="6"
                                       ></b-form-textarea>
                                       <b-form-invalid-feedback id="input-live-quotes">
                                           {{_.head(localErrors('elements.'+index+'.text'))}}
                                       </b-form-invalid-feedback>
                                   </div>
                                   <div class="col-md-12">
                                       <div class="input-group">
                                           <div class="input-group-btn">
                                               <a class="btn btn-info" @click="openFileManager(index)">
                                                   <i class="fa fa-picture-o"></i> Choose
                                               </a>
                                           </div>
                                           <input readonly type="text" :class="{'is-invalid':localErrors(`elements.${index}.image`).length > 0 }"
                                                  class="form-control"v-model="elements[index].image">
                                       </div>
                                   </div>
                                   <div class="col-md-12">
                                       <img style="width: 100%" class="mt-3" v-if="elements[index].image" :src="elements[index].image"/>
                                   </div>
                                   <div class="col-md-12">
                                       <label>Параметры</label>
                                       <table class="table table-hover table-bordered table-striper">
                                           <tr v-for="(option,ind) in elements[index].options" :key="option.id">
                                               <td>{{ind+1}}</td>
                                               <td>
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                           <label>Параметр</label>
                                                           <b-form-input
                                                                   v-model="elements[index]['options'][ind].heading"
                                                                   :state="localErrors(`elements.${index}.options.${ind}.heading`).length == 0 ? null : false"
                                                                   rows="5"
                                                                   max-rows="6"
                                                           ></b-form-input>
                                                           <b-form-invalid-feedback id="input-live-quotes">
                                                               {{_.head(localErrors(`elements.${index}.options.${ind}.heading`))}}
                                                           </b-form-invalid-feedback>
                                                       </div>
                                                       <div class="col-md-6">
                                                           <label>Значение</label>
                                                           <b-form-input
                                                                   v-model="elements[index]['options'][ind].val"
                                                                   :state="localErrors(`elements.${index}.options.${ind}.cal`).length == 0 ? null : false"
                                                                   rows="5"
                                                                   max-rows="6"
                                                           ></b-form-input>
                                                           <b-form-invalid-feedback id="input-live-quotes">
                                                               {{_.head(localErrors(`elements.${index}.options.${ind}.val`))}}
                                                           </b-form-invalid-feedback>
                                                       </div>
                                                   </div>
                                               </td>
                                               <td>
                                                   <button class="btn-sm btn btn-fill btn-warning pull-right"
                                                           @click="removeOptions(index,ind)">-
                                                   </button>
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td>
                                                   <div class="col-md-12">
                                                       <button class="btn-sm btn btn-fill btn-success pull-right" @click="addOptions(index)">+</button>
                                                   </div>
                                               </td>
                                           </tr>
                                       </table>
                                   </div>
                               </div>
                            </td>
                            <td>
                                <button class="btn-sm btn btn-fill btn-warning pull-right"
                                        @click="removeElements(index)">-
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <button class="btn-sm btn btn-fill btn-success pull-right" @click="addElements">+</button>
                </div>
            </div>
        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var AchievementsCustomers = (_.find(this.relationMeta, (o) => o.meta_key == 'AchievementsCustomers')).meta_value || '';

            this.heading = AchievementsCustomers.heading || '';
            this.elements = AchievementsCustomers.elements || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading: null,
                elements: []
            }
        },
        methods: {
            openFileManager(index) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );
                var self = this
                window.SetUrl = function (items) {
                    self.elements[index]['image'] = items.replace(window.location.origin, '')
                }
                return false
            },
            removeElements(index) {
                this.elements.splice(index, 1);
            },
            addElements() {
                this.elements.push({
                    heading : null,
                    link_more : null,
                    image : "",
                    text: "",
                    options : [],
                    id: Date.now()
                });
            },
            upElements(index) {
                this.elements = this.swapElements(this.elements, index, 'up');
                this.elements[index]['id'] ++;
                this.elements[index]['id'] --;
            },
            downElements(index) {
                this.elements = this.swapElements(this.elements, index, 'down');
                this.elements[index]['id'] ++;
                this.elements[index]['id'] --;
            },

            swapElements (arr, i, arrow){

                function swap(arr, i, j){
                    var tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
                    return arr;
                }

                if(arrow == 'up') {
                    if(i > 0) arr = swap(arr, i, i-1);
                } else if(arrow == 'down') {
                    if(i < (arr.length - 1)) arr = swap(arr, i, i+1);
                }

                return arr;
            },

            removeOptions(indElement,indOptions) {
                this.elements[indElement]['options'].splice(indOptions, 1);
            },
            addOptions(indElement) {
                this.elements[indElement].options.push({
                    heading : null,
                    val : '',
                    id: Date.now()
                });
            },
            localErrors(key) {
                return this.errors(`AchievementsCustomers.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'AchievementsCustomers',
                    value: {
                        heading: this.heading,
                        elements: this.elements
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
