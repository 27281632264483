<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">ПРОДЕЛАННАЯ РАБОТА:</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <p style="display: block" class="invalid-feedback" v-if="errors('completed_work').length">
                                {{_.head(errors('completed_work'))}}</p>
                            <quill-editor style="min-height:220px;" v-model="completed_work"
                                          ref="myQuillEditor"
                                          :options="completed_work">
                            </quill-editor>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './mixins/MetaDescriptionMixin'
    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        components:{
            quillEditor
        },
        created() {
            this.completed_work = (_.find(this.relationMeta, (o) => o.meta_key == 'completed_work')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                completed_work: null,
                passible: [
                    'completed_work'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'completed_work',
                    value: this.completed_work
                })
            }
        }
    }
</script>

<style scoped>

</style>
