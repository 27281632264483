<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Восьмой экран</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Эволюция</label>
                            <b-form-input
                                    v-model="evolution"
                                    :state="localErrors('evolution').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('evolution'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Элементы (каждый элемент с новой строки)</label>
                            <b-form-textarea
                                    v-model="evolution_elements"
                                    :state="localErrors('evolution_elements').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('evolution_elements'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Революция</label>
                            <b-form-input
                                    v-model="revolution"
                                    :state="localErrors('revolution').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('revolution'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Элементы (каждый элемент с новой строки)</label>
                            <b-form-textarea
                                    v-model="revolution_elements"
                                    :state="localErrors('revolution_elements').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('revolution_elements'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>КОМПАНИИ, ПРОДУКТЫ — ПРИОБРЕТАЮТ НОВЫЕ....</label>
                            <b-form-input
                                    v-model="bottom_text"
                                    :state="localErrors('bottom_text').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('bottom_text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>

        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var eighth_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'eighth_screen')).meta_value || '';
            this.evolution = eighth_screen.evolution || '';
            this.evolution_elements = eighth_screen.evolution_elements || '';
            this.revolution = eighth_screen.revolution || '';
            this.revolution_elements = eighth_screen.revolution_elements || '';
            this.bottom_text = eighth_screen.bottom_text
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                evolution: '',
                evolution_elements : '',
                revolution : '',
                revolution_elements : '',
                bottom_text : ''
            }
        },
        methods: {
            openFileManager(param) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl =  (items) => {
                    this[param] = items.replace(window.location.origin, '')
                }

                return false
            },
            localErrors(key) {
                return this.errors(`fourth_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'eighth_screen',
                    value: {
                        evolution: this.evolution,
                        evolution_elements : this.evolution_elements,
                        revolution : this.revolution,
                        revolution_elements : this.revolution_elements,
                        bottom_text : this.bottom_text
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
