
import Robot from '../../admin/pages/Setting/Robot'
import Head from '../../admin/pages/Setting/Head'
import Header from '../../admin/pages/Setting/Header'
import Footer from '../../admin/pages/Setting/Footer'
// import Blocklink from '../../admin/pages/Setting/Blocklink'
import Sitemap from '../../admin/pages/Setting/Sitemap'


export default [
    {
        path: 'settings/robot',
        name: 'service-robot',
        component: Robot
    },
    {
        path: 'settings/head',
        name: 'service-head',
        component: Head
    },
    {
        path: 'settings/header',
        name: 'service-header',
        component: Header
    },
    {
        path: 'settings/footer',
        name: 'service-footer',
        component: Footer
    },
    /*{
        path: 'settings/blocklink',
        name: 'service-blocklink',
        component: Blocklink
    },*/
    {
        path: 'settings/sitemap',
        name: 'service-sitemap',
        component: Sitemap
    }
]
