<template>
    <form action="">
        <div class="d-flex align-items-end mb-4">
            <div class="blog-filter__input">
                <input type="email"
                       @keypress.enter.prevent="send"
                       v-model="email" name="email"
                       :class="{'input-error':hasError('email')}"
                       placeholder="ваш email">
            </div>
            <button  @click.prevent="send" :disable="sending" class="blog-filter__submit cta-btn cta-btn--red" type="submit">
                <i class="icon-arrow"></i>
            </button>
        </div>
        <span class="message-container"
              :class="{
                          'active' : response.message.length,
                          'success-container' : !hasErrors,
                          'error-container' : hasErrors
                      }">{{response.message}}</span>
    </form>
</template>

<script>
    export default {
        data(){
            return {
                email : null,
                sending : false,
                response: {
                    message: '',
                    errors: {}
                }
            }
        },
        computed: {
            hasErrors() {
                return Object.keys(this.response.errors).length > 0
            }
        },
        methods : {
            hasError(name) {
                return _.has(this.response.errors, name)
            },
            getFirstError(name) {
                return _.head(this.response.errors[name])
            },
            setSending() {
                this.sending = true;
            },
            resetSending() {
                this.sending = false;
            },
            flushForm() {
                Object.assign(this.$data, this.$options.data())
            },
            flushErrors() {
                this.response.message = '';
                this.response.errors = {};
            },
            getFormData() {
                let formData = new FormData();
                formData.append('email', this.email)
                return formData;
            },
            send() {
                if (this.sending) return;

                this.setSending();
                this.flushErrors();

                axios.post(window.location.origin + '/'+document.documentElement.lang+'/api/v1/frontend/subscribe', this.getFormData())
                    .then(({data}) => {
                        this.flushForm();
                        this.resetSending();
                        this.response.message = data.success_message
                    }).catch(e => {
                    let response = e.response || {};
                    this.response.message = response.data.errors.email[0] || ''
                    this.response.errors = response.data.errors || {}
                    this.resetSending();
                })
            }
        }
    }
</script>

<style scoped>

</style>
