<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <base-input type="search"
                                placeholder="Search" v-model="query">
                    </base-input>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <select class="form-control" v-model="user_role">
                            <option value="">Фильтр по по ролям</option>
                            <option v-for="role in roles" :key="role.id" :value="role.id">{{role.description}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-5">
                    <nav aria-label="..." class="d-flex justify-content-end">
                        <app-pagination v-model="page"
                                        :page-count="users.last_page"
                        ></app-pagination>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <card class="strpied-tabled-with-hover"
                          body-classes="table-full-width table-responsive"
                    >
                        <template slot="header">
                            <div class="text-center">
                                <router-link :to="{name : 'user-create'}"
                                             class="btn btn-info btn-sm btn-fill float-right"> Создать
                                </router-link>
                            </div>
                            <h4 class="card-title">Список пользователей</h4>
                            <p class="card-category">В этой таблице, вы можете увидеть список всех пользователей</p>
                        </template>

                        <app-preloader-table v-if="loading"></app-preloader-table>
                        <table class="table table-hover table-striped" v-else>
                            <thead>
                            <tr>
                                <th v-for="column in table1.columns" :key="column">{{column}}</th>
                                <th>Company</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in table1.data" :key="index">
                                <td v-for="column in table1.columns" :key="column"
                                    v-html="item[column.toLowerCase()]"></td>
                                <td>
                                    <router-link v-if="item.company" :to="{name : 'company-edit',params : {id : item.company.id}}">
                                        {{item.company.name}}
                                    </router-link>
                                </td>
                                <td>
                                    <router-link :to="{name : 'users-edit',params : {id : item.id}}"
                                                 class="btn btn-info btn-sm btn-fill float-right">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </router-link>
                                    <buttom @click.prevent="showDialog(item.id)" class="btn-sm btn-fill btn btn-danger ml-2">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </buttom>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </card>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import LTable from '../../../components/Table.vue'
    import Card from '../../../components/Cards/Card.vue'
    import _ from 'lodash'

    const tableColumns = ['Id', 'Name', 'email', 'Roles', 'created_at'];

    const tableData = [];

    export default {
        components: {
            LTable,
            Card
        },
        created() {
           this.$store.dispatch('roles/loadRoles')
        },
        computed:{
          roles(){
              return this.$store.getters['roles/getRoles'];
          }
        },
        watch: {
            page(p) {
                if (p == 0) {
                    this.page = 1;
                    return;
                }
                if (this.loading) return;
                if (this.page == 0)
                    this.page = 1;

                this.getData();
            },
            user_role() {
                this.page = 1;
                if (this.loading) return;
                this.loadData();
            },
            query() {
                this.page = 1;
                if (this.loading) return;
                this.loadData();
            }
        },
        data() {
            return {
                user_role: "",
                query: '',
                page: 1,
                loading: false,
                table1: {
                    columns: [...tableColumns],
                    data: [...tableData]
                },
                users: {
                    total: 0,
                    last_page: 0,
                    current_page: 0
                },

            }
        },
        methods: {
            async remove(id){
                await axios.delete(`/users/${id}`)
                    .then(() => {
                        this.getData()
                    })
            },
            showDialog(id){
                this.$dialog.confirm('Вы уверены, что хотить безвозвратно удалить ?',{
                    okText: 'Да',
                    cancelText: 'Отмена',
                })
                    .then((dialog) => {
                        dialog.close && dialog.close()
                        this.remove(id);
                    })
            },

            getData() {
                this.loading = true;
                let data = {
                    query: this.query,
                    page: this.page
                };

                if (parseInt(this.user_role) > 0) {
                    data.user_role = parseInt(this.user_role);
                }


                axios.get('/users',
                    {
                        params: {
                            ...data
                        }
                    }
                )
                    .then(({data}) => {
                        this.loading = false;
                        this.table1.data = data.data;
                        this.users.total = data.meta.total
                        this.users.last_page = data.meta.last_page
                        this.users.current_page = data.meta.current_page
                    }).catch(() => {
                    this.loading = false;
                })
            },
            loadData() {
                this.debounce(() => {
                    this.getData();
                });
            },
            debounce: _.debounce((cb) => {
                cb()
            }, 600),
        }
    }
</script>
<style>
</style>
