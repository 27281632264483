<template>
    <div class="row">
        <div class="col-md-12">
            <div role="group">
                <label for="position">Пункт оглавления:</label>
                <b-form-input
                        id="position"
                        v-model="title"
                        aria-describedby="input-help input-live-position"
                        :state="errors('title').length == 0 ? null : false"
                        placeholder=""
                        trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('title'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import watchParams from './mixin/watchParams'

    export default {
        mixins:[watchParams],
        props: {
            keyComponent:{
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        data() {
            return {
                title : null,
                passible : [
                    'title',
                ],
            }
        }
    }
</script>

<style scoped>

</style>
