<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                    <div class="col-md-12">
                        <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                            <span class="">Сохранить</span>
                        </button>
                    </div>
                <div class="col-md-12">
                    <textarea style="min-height:300px;" v-model="robot"
                                  ref="myQuillEditor"
                                  class="form-control">
                    </textarea>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        data() {
            return {
                robot: '',
                editorOption: {
                    height: '500px',
                },
            }
        },
        components: {
            quillEditor
        },
        methods: {
            save(e) {
                addBtnLoading(e)
                axios.put('admin/robot-edit',{
                    content : this.robot
                })
                    .then(() => {
                        removeBtnLoading(e)
                        window.notification.success('Сохранение прошло успешно');
                    });
            }
        },
        created() {
            axios.get('/admin/robot-edit')
                .then(({data}) => {
                    this.robot = data.content;
                })
        }
    }
</script>

<style scoped>

</style>