export default {
    namespaced: true,
    state: {
        errors: []
    },
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getError: (state) => (key) =>{
            try {
                return state.errors[key];
            } catch (e) {
                return []
            }
        }
    },
    mutations: {
        setErrors(state, payload) {
            state.errors = payload;
        },
        resetErrors(state, payload) {
            state.errors = [];
        }
    }
}
