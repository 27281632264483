<template>
    <div class="row">
        <div class="col-md-12">
            <div class="input-group">
                <div class="input-group-btn">
                    <a class="btn btn-info" @click="openFileManager">
                        <i class="fa fa-picture-o"></i> Choose
                    </a>
                </div>
                <input readonly type="text" :class="{'is-invalid':errors('file').length > 0 }"  class="form-control" v-model="file">
            </div>
        </div>
        <div class="col-md-12">
            <div role="group">
                <label for="position">Подпись документа:</label>
                <b-form-input
                    id="position"
                    v-model="inscript"
                    aria-describedby="input-help input-live-position"
                    :state="errors('inscript').length == 0 ? null : false"
                    placeholder="Введите подпись документа"
                    trim
                ></b-form-input>

                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-quotes">
                    {{_.head(errors('inscript'))}}
                </b-form-invalid-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import watchParams from './mixin/watchParams'

    export default {
        props: {
            keyComponent:{
                type: [String, Number],
            },
            lang_id: {
                type: [String, Number],
                default: 0
            }
        },
        mixins:[watchParams],
        data(){
            return {
                file : null,
                inscript : null,
                validate: {
                    file: ['required','exists:entity_files,path', 'max:4096'],
                    inscript: ['required','min:1'],
                },
                passible : [
                    'file','inscript','validate'
                ]
            }
        },
        methods : {

            openFileManager () {
                var params = {
                    type: 'Files'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );
                var self = this
                window.SetUrl = function (items) {
                    self.file = items.replace(window.location.origin,'')
                }
                return false
            }
        }
    }
</script>

<style scoped>

</style>
