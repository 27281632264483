<template>
    <card>
        <h4 slot="header" class="card-title">Права доступа</h4>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label">
                        User Role
                    </label>
                    <select multiple v-model="userRoles" class="form-control" id=""
                            :class="{'is-invalid': _.has(errors,'roles') }">
                        <option :value="role.id" v-for="role in roles" :key="role.id">
                            {{role.description}}
                        </option>
                    </select>
                    <div class="invalid-feedback" v-if="_.has(errors,'roles')">
                        {{_.head(errors['roles'])}}
                    </div>
                </div>
                <div class="col-md-12">
                    <base-checkbox v-model="isActive">Active User</base-checkbox>
                </div>

                <div class="col-md-12" v-if="needSendConfirmationEmail">
                    <base-checkbox v-model="sendConfirmationEmail">Send confirmation email</base-checkbox>
                </div>
                <div class="col-md-12">
                    <base-checkbox v-model="emailActivated">Mail Confirmed</base-checkbox>
                </div>
            </div>

            <div class="clearfix"></div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'

    import ErrorMixin from '../../mixim/UserMixin'
    import {mapGetters} from 'vuex'

    export default {
        mixins: [ErrorMixin],
        components: {
            Card
        },
        computed: {
            needSendConfirmationEmail() {
                return this.$store.getters['user/needSendConfirmation']
            },
            emailActivated: {
                get() {
                    return this.$store.getters['user/getEmailActivated']
                },
                set(value) {
                    this.$store.commit('user/setEmailActivated', value)
                }
            },
            sendConfirmationEmail: {
                get() {
                    return this.$store.getters['user/getSendConfirmationEmail']
                },
                set(value) {
                    this.$store.commit('user/setSendConfirmationEmail', value)
                }
            },
            isActive: {
                get() {
                    return this.$store.getters['user/getActive']
                },
                set(value) {
                    this.$store.commit('user/setActive', value)
                }
            },
            userRoles: {
                get() {
                    return Array.isArray(this.$store.getters['user/getUserRoles']) ? this.$store.getters['user/getUserRoles'] : []
                },
                set(value) {
                    this.$store.commit('user/setRoles', value)
                }
            },
            roles() {
                return this.$store.getters['roles/getRoles'];
            }
        }
        ,
        data() {
            return {}
        }
    }

</script>
<style>

</style>
