<template>
    <card>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                        <span class="">Сохранить</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input type="text"
                                label="Телефон 1"
                                placeholder="Телефон 1"
                                v-model="tels[0]">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input type="text"
                                label="Телефон 2"
                                placeholder="Телефон 2"
                                v-model="tels[1]">
                    </base-input>
                </div>
            </div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        data() {
            return {
                tels : ['sdfds','fsdf'],
                errors : ['','']
            }
        },
        computed: {

        },
        components: {
            Card
        },
        created(){
            axios.get('admin/setting/header')
                .then(({data}) => {
                    this.tels = (data.data.value != null && Array.isArray(data.data.value)) ? data.data.value : ['',''];
                    this.$set('$data','tels',this.tels);

                })
        },
        methods: {
            save(e) {
                addBtnLoading(e)
                this.errors = [];
                axios.put('admin/setting/header', {
                        key : 'header',
                        value : this.tels
                    }
                ).then(({data}) => {

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)
                        this.loading = false;
                        this.errors = error.response.data.errors
                        window.notification.error(this.errorMessage);

                    });
            }
        }

    }

</script>
<style>

</style>
