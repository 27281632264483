<template>
    <b-card header-tag="header">
        <template slot="header">
            <h5 style="margin: 0px">Компоненты статьи</h5>
        </template>
        <div class="d-flex justify-content-between ">
            <b-button variant="outline-primary" @click="pushComponent('AppTinyMc')">Текст</b-button>
            <b-button variant="outline-secondary" @click="pushComponent('AppBlockquote')">Цитата</b-button>
            <b-button variant="outline-success" @click="pushComponent('AppFile')">Файл</b-button>
            <b-button variant="outline-danger" @click="pushComponent('AppImage')">Картинка</b-button>
            <b-button variant="outline-warning" @click="pushComponent('AppVideo')">Youtube</b-button>
            <b-button variant="outline-info" @click="pushComponent('AppList')">Список</b-button>
            <b-button variant="outline-info" @click="pushComponent('AppTitle')">Оглавление</b-button>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: "PartInstrumnets",
        methods:{
            pushComponent(i){
                this.$emit('pushComponent',i);
            }
        }
    }
</script>

<style scoped>

</style>
