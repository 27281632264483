<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Контекстная реклама - это...: <em>Используйте тег
                <code style="color: #B0140E">
                    &lt;span>Любой текст<&frasl;span>
                </code>- для выделения текста красным цветов</em></h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <b-form-textarea
                                id="textarea"
                                v-model="first_text"
                                :state="errors('first_text').length == 0 ? null : false"
                                rows="5"
                                max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(errors('first_text'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            this.first_text = (_.find(this.relationMeta, (o) => o.meta_key == 'first_text')).meta_value || '';
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                first_text: null,
                passible: [
                    'first_text'
                ]
            }
        },
        methods: {
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'first_text',
                    value: this.first_text
                })
            }
        }
    }
</script>

<style scoped>

</style>
