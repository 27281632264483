<template>
    <div class="content">
        <div class="container-fluid" v-if="!startLoading">
            <div class="row">
                <div class="col-12">
                    <div>
                        <b-tabs>
                            <b-tab :title-item-class="getClassForTitleTab(language.id)" v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <post-description :default-slug="'google-ads'" :enable-slug="false" card-title="Информация"
                                                  :enablePartPost="[]"
                                                  :description="getPropsByKey(language.id)" :lang_id="language.id"
                                                  @updateData="setDescriptionData(language.id,$event)">
                                    <template v-slot:meta_info="slotProps">

                                        <first-text :lang_id="language.id"
                                                    :relation-meta="slotProps.description.meta"
                                                    @updateMeta="slotProps.method($event)"></first-text>
                                        <second-text :lang_id="language.id"
                                                     :relation-meta="slotProps.description.meta"
                                                     @updateMeta="slotProps.method($event)"></second-text>
                                        <file-example :lang_id="language.id"
                                                      :relation-meta="slotProps.description.meta"
                                                      @updateMeta="slotProps.method($event)"></file-example>
                                        <partner :lang_id="language.id"
                                                 :relation-meta="slotProps.description.meta"
                                                 @updateMeta="slotProps.method($event)"></partner>
                                        <project-was-made :lang_id="language.id"
                                                          :relation-meta="slotProps.description.meta"
                                                          @updateMeta="slotProps.method($event)"></project-was-made>
                                        <benefits-contextual-advertising :lang_id="language.id"
                                                                         :relation-meta="slotProps.description.meta"
                                                                         @updateMeta="slotProps.method($event)"></benefits-contextual-advertising>
                                        <stages-work :lang_id="language.id" :relation-meta="slotProps.description.meta"
                                                     @updateMeta="slotProps.method($event)"></stages-work>
                                        <achievements-customers :lang_id="language.id"
                                                                :relation-meta="slotProps.description.meta"
                                                                @updateMeta="slotProps.method($event)"></achievements-customers>
                                        <request :lang_id="language.id" :relation-meta="slotProps.description.meta"
                                                 @updateMeta="slotProps.method($event)"></request>
                                        <profitable-offer :lang_id="language.id"
                                                          :relation-meta="slotProps.description.meta"
                                                          @updateMeta="slotProps.method($event)"></profitable-offer>
                                    </template>
                                </post-description>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <post-data :date-pub="false" :logoImage="false" :editData="postForEditing"
                               @updateData="setGeneralData($event)"
                               @publish="publish">
                    </post-data>

                    <links-block uri="services.ppc"></links-block>

                </div>

            </div>
        </div>
    </div>
</template>


<script>
    import PostData from './../../Post/PostData'
    import RelationTag from './../../Post/Relation/Taggable'
    import PostDescription from './../../Post/PostDescription'
    import FirstText from './FirstText'
    import SecondText from './SecondText'
    import FileExample from './FileExample'
    import Partner from './Partner'
    import ProjectWasMade from './ProjectWasMade'
    import BenefitsContextualAdvertising from './BenefitsContextualAdvertising'
    import StagesWork from './StagesWork'
    import AchievementsCustomers from './AchievementsCustomers'
    import Request from './Request'
    import ProfitableOffer from './ProfitableOffer'

    import {addBtnLoading, removeBtnLoading} from './../../../../helpers/btnloading'

    import LinksBlock from '../../../components/LinksBlock'

    export default {
        props: {
            edit: {
                type: Boolean,
                default: false
            }
        },
        components: {
            PostData,
            PostDescription,
            RelationTag,
            FirstText,
            SecondText,
            FileExample,
            Partner,
            ProjectWasMade,
            BenefitsContextualAdvertising,
            StagesWork,
            AchievementsCustomers,
            Request,
            ProfitableOffer,
            LinksBlock
        },
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: 'admin/case/'
                },
                startLoading: true,
                languages: [],

                postForEditing: {},
                post: {
                    meta: {},
                    type: 'serviceppc',
                    relations: {
                        tags: null
                    },
                    post_description: {}
                }
            }
        },
        async beforeCreate() {
            await axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                })
                .then(() => {
                    if (this.edit) {
                        axios.get(`admin/case/` + this.post.type)
                            .then(({data}) => {
                                    this.$set(this.$data, 'postForEditing', data.data);
                                    this.startLoading = false;
                                    this.paramsSave.method = 'put'
                                    this.paramsSave.url = this.paramsSave.url + this.post.type
                                }
                            ).catch(() => {
                            this.startLoading = false;
                        })
                    } else {
                        this.startLoading = false;
                    }
                })
                .catch(() => {
                    this.startLoading = false;
                })
        },
        computed: {
            errors() {
                return this.$store.getters['errors/getErrors'];
            }
        }
        ,
        methods: {
            updateMeta(e) {
                this.post.meta[e.key] = e.value;
            },
            setTags(tags) {
                this.post.relations.tags = tags;
            },
            getPropsByKey(key) {
                if (this.postForEditing && this.postForEditing.hasOwnProperty('description')) {
                    let description = _.find(this.postForEditing.description, (o) => o.language_id == key);
                    return description ? description : {}
                }
                return {}
            }
            ,
            getClassForTitleTab(index) {
                var hasLangError = false;
                for (let cur in this.errors) {
                    if (cur.indexOf(`post_description.${index}`) !== -1) {
                        hasLangError = true;
                        break;
                    }
                }

                if (hasLangError) {
                    return 'has-error'
                }

                return '';
            }
            ,
            publish(e) {
                axios[this.paramsSave.method](this.paramsSave.url, {
                    ...this.post
                }).then(({data}) => {

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message
                        window.notification.error(this.errorMessage);

                    });
            }
            ,
            setDescriptionData(language, data) {
                this.post.post_description[language] = data;

            }
            ,
            setGeneralData($event) {
                for (let property in $event) {
                    this.post[property] = $event[property]
                }
            }
        }
        ,
        watch: {}
    }
</script>


<style>
    .tab-pane {
        outline: none;
    }

    .has-error {
        background: #dc3545;
    }

    .has-error a {
        color: #ffffff;
    }
</style>
