<template>
    <card>
        <h4 slot="header" class="card-title">Профиль</h4>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <base-input type="text"
                                label="Имя пользователя"
                                placeholder="Введите имя пользователя"
                                :inputClass="_.has(errors,'profile.'+lang_id+'.name') ? 'is-invalid' : ''"
                                v-model="name">
                        <template v-slot:error_message>
                            <div class="invalid-feedback" v-if="_.has(errors,'profile.'+lang_id+'.name')">
                                {{_.head(errors['profile.'+lang_id+'.name'])}}
                            </div>
                        </template>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input type="text"
                                label="Описание пользователя (дожлность)"
                                placeholder="Введите описание пользователя"
                                :inputClass="_.has(errors,'profile.'+lang_id+'.info') ? 'is-invalid' : ''"
                                v-model="info">
                        <template v-slot:error_message>
                            <div class="invalid-feedback" v-if="_.has(errors,'profile.'+lang_id+'.info')">
                                {{_.head(errors['profile.'+lang_id+'.info'])}}
                            </div>
                        </template>
                    </base-input>
                </div>
            </div>
            <div class="clearfix"></div>
        </form>
    </card>
</template>

<script>
    import ErrorMixin from '../../mixim/UserMixin'

    export default {
        props: {
            lang_id: {
                type: [String, Number]
            }
        },
        mixins: [ErrorMixin],
        mounted() {
            this.$store.commit('user/setName', {
                lang_id: this.lang_id,
                value: name
            })
        },
        computed: {
            name: {
                get() {
                    return this.$store.getters['user/getName'](this.lang_id)
                },
                set(value) {
                    return this.$store.commit('user/setName', {
                        lang_id: this.lang_id,
                        value: value
                    })
                }
            },
            info: {
                get() {
                    return this.$store.getters['user/getInfo'](this.lang_id)
                },
                set(value) {
                    return this.$store.commit('user/setInfo', {
                            lang_id: this.lang_id,
                            value: value
                        }
                    )
                }
            }
        }
    }
</script>

<style scoped>

</style>
