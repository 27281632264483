<template>
    <form action="">
        <div class="d-flex">
            <input name="name" class="comments-section__input-name" v-model="enterName" type="text" placeholder="имя">
            <input name="email" class="comments-section__input-email" v-model="enterEmail" type="email"
                   placeholder="email">
        </div>
        <textarea name="comment" id="" v-model="body" placeholder="комментарий"></textarea>
        <div class="comments-section__submit">
            <span class="message-container mb-0 mr-5" ref="errorContainer">Lorem ipsum dolor sit amet.</span>
            <button class="cta-btn cta-btn--red" :disabled="hasErrors" :disable="sending" @click.prevent="submit()"
                    type="submit">
                публиковать
            </button>
            <button class="reset-btn" type="reset">
                отменить
            </button>
        </div>
    </form>
</template>

<script>
    export default {
        props: [
            'name', 'email', 'post_id'
        ],
        mounted() {
            this.enterEmail = this.email;
            this.enterName = this.name;
        },
        data() {
            return {
                sending: false,
                enterName: '',
                enterEmail: '',
                body: '',
                hasErrors: false
            }
        },
        methods: {
            submit() {
                if (this.sending) return;

                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.enterEmail)) {
                    this.$refs.errorContainer.textContent = "Некорректный Email";
                    this.$refs.errorContainer.classList.add("active", "error-container");
                    return
                } else if (this.enterName.length < 3) {
                    this.$refs.errorContainer.textContent = "Имя должно быть не меньше 3 символов";
                    this.$refs.errorContainer.classList.add("active", "error-container");
                    return
                } else {
                    this.$refs.errorContainer.classList.remove("active", "error-container");
                }

                this.sending = true;

                axios.post(`${window.location.origin}/api/v1/frontend/comments/` + this.post_id, {
                // axios.post('http://wits.loc/api/v1/frontend/comments/' + this.post_id, {
                // axios.post('/comments/' + this.post_id, {
                    'user_name': this.enterName,
                    'email': this.enterEmail,
                    'body': this.body
                })
                    .then(({data}) => {
                        this.$refs.errorContainer.textContent = "Комментарий отправлен";
                        this.$refs.errorContainer.classList.add("active", "success-container");
                        setTimeout(() => {
                            this.$refs.errorContainer.classList.remove("active", "success-container");
                        }, 1000);
                        bus.$emit('newComment-' + this.post_id, data);
                        this.sending = false;
                        // Object.assign(this.$data, this.$options.$data())
                        Object.assign(this.$data, this.$options.data())
                    })
                    .catch(error => {
                        console.log(error);
                        this.sending = false;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
