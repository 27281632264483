
import List from '../../admin/pages/Vacancy/Index'
import ListResponse from '../../admin/pages/Vacancy/Response/List'
import Form from '../../admin/pages/Vacancy/Form'
export default [
    {
        path: 'vacancy/',
        name: 'vacancy-index',
        component: List,
        props : {
            edit : true
        }
    },
    {
        path: 'vacancy/response',
        name: 'vacancy-response',
        component: ListResponse,
        props : {
            edit : true
        }
    },

    {
        path: 'vacancy/create',
        name: 'vacancy-create',
        component: Form
    },
    {
        path: 'vacancy/:id/edit',
        name: 'vacancy-edit',
        component: Form,
        props:true
    },
]