import { render, staticRenderFns } from "./PostBanner.vue?vue&type=template&id=03f2c268&scoped=true&"
import script from "./PostBanner.vue?vue&type=script&lang=js&"
export * from "./PostBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f2c268",
  null
  
)

export default component.exports