<template>
    <card>
        <h4 slot="header" class="card-title">Данные пользователя</h4>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <input type="text" :class="{'is-invalid': _.has(errors,'avatar') }" class="form-control"
                               v-model="avatar">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager">
                                <i class="fa fa-picture-o"></i> Изобажение
                            </a>
                        </div>
                    </div>


                </div>
                <div class="col-12">
                    <b-img-lazy v-if="avatar" thumbnail :src="avatar" alt="Image 1"></b-img-lazy>
                </div>
                <div class="col-md-12">
                    <base-input type="text"
                                label="Email"
                                placeholder="Enter User Mail"
                                :inputClass="_.has(errors,'email') ? 'is-invalid' : ''"
                                v-model="email">
                        <template v-slot:error_message>
                            <div class="invalid-feedback" v-if="_.has(errors,'email')">
                                {{_.head(errors['email'])}}
                            </div>
                        </template>
                    </base-input>
                </div>
            </div>
            <div class="clearfix"></div>
        </form>
    </card>
</template>
<script>
    import Card from './../../../components/Cards/Card.vue'
    import {mapGetters} from 'vuex'
    import ErrorMixin from '../../mixim/UserMixin'

    export default {
        mixins: [ErrorMixin],
        components: {
            Card
        },
        data() {
            return {}
        },
        methods: {
            openFileManager() {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                var self = this
                window.SetUrl = function (items) {
                    self.avatar = items.replace(window.location.origin, '')
                }
                return false
            },
        },
        computed: {
            avatar: {
                get() {
                    return this.$store.getters['user/getAvatar']
                },
                set(value) {
                    return this.$store.commit('user/setAvatar', value)
                }
            },
            name: {
                get() {
                    return this.$store.getters['user/getName']
                },
                set(value) {
                    return this.$store.commit('user/setName', value)
                }
            },
            email: {
                get() {
                    return this.$store.getters['user/getEmail']
                },
                set(value) {
                    return this.$store.commit('user/setEmail', value)
                }
            }
        }
    }

</script>
<style>

</style>
