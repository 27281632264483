import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import roles from './modules/roles'
import errors from './modules/errors'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth,roles,user,errors
    }
})
