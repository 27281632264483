export default {
    data() {
        return {
            lang:'',
            email: '',
            phone: '',
            text: '',
            response: {
                message: '',
                errors: {}
            }
        }
    },
    computed: {
        hasErrors() {
            return Object.keys(this.response.errors).length > 0
        }
    },
    methods: {
        hasError(name) {
            return _.has(this.response.errors, name)
        },
        getFirstError(name) {
            return _.head(this.response.errors[name])
        },
        setSending() {
            this.sending = true;
        },
        resetSending() {
            this.sending = false;
        },
        flushForm() {
            Object.assign(this.$data, this.$options.data())
        },
        flushErrors() {
            this.response.message = '';
            this.response.errors = {};
        },
        getFormData() {
            let formData = new FormData();
            formData.append('phone', this.phone)
            formData.append('email', this.email)
            formData.append('text', this.text)
            // formData.append('lang', this.lang)
            return formData;
        },
        send(formId) {
            if (this.sending) return;
            let link_success_t = location.origin + '/' + this.lang + '/success';
            this.setSending();
            this.flushErrors();
            axios.post(this.url_send, this.getFormData())
                .then(({data}) => {
                    if(formId){
                        $('form.lirax-custom-form#' + formId).trigger('submit');
                    }
                    location.href = link_success_t;
                    this.flushForm();
                    this.resetSending();
                    this.response.message = data.success_message;
                }).catch(e => {
                let response = e.response || {};
                this.response.message = response.data.message || ''
                this.response.errors = response.data.errors || {}
                this.resetSending();
            })
        }
    }
}
