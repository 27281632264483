<template>
    <div class="content" v-if="startLoading">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8">
                    <div>
                        <b-tabs>
                            <b-tab v-for="language in languages"
                                   :key="language.id" :title="language.name">
                                <post-description :enable-title="false" :enable-seo="false" :enable-slug="false"
                                                  card-title="Информация"
                                                  :enablePartPost="[]"
                                                  :description="getPropsByKey(language.id)" :lang_id="language.id">
                                    <template v-slot:meta_info="slotProps">
                                        <contact-description
                                                :editDescription="getDescriptionByKey(language.id,'contacts')"
                                                @updateInfo="updateInfo(language.id,$event)"></contact-description>
                                        <social-description
                                                :editDescription="getDescriptionByKey(language.id,'social_description')"
                                                @updateInfo="updateInfo(language.id,$event)"></social-description>
                                        <write-us @updateInfo="updateInfo(language.id,$event)"
                                                  :editDescription="getDescriptionByKey(language.id,'write_us')"
                                        ></write-us>
                                        <services @updateInfo="updateInfo(language.id,$event)"
                                                  :editDescription="getDescriptionByKey(language.id,'services')"
                                        ></services>
                                    </template>
                                </post-description>
                            </b-tab>
                        </b-tabs>
                    </div>

                </div>
                <div class="col-4">
                    <post-data :date-pub="false" :logoImage="false" :editData="postForEditing"
                               @publish="publish">
                    </post-data>
                    <contacts-form :infoForEdit="editInfo.info" @setInfo="setInfo($event)"></contacts-form>
                    <socials :infoForEdit="editInfo.info" @setInfo="setInfo($event)"></socials>
                    <!--<block-relink :infoForEdit="editInfo.info" @setInfo="setInfo($event)"></block-relink>-->
                </div>

            </div>
        </div>
    </div>
</template>


<script>
    import PostData from './../Post/PostData'
    import PostDescription from './../Post/PostDescription'
    import ContactsForm from './Components/ContactsForm'
    import Socials from './Components/Socials'
    import ContactDescription from './Components/ContactDescription'
    import SocialDescription from './Components/SocialDescription'
    import WriteUs from './Components/WriteUs'
    import Services from './Components/Services'
    import BlockRelink from './Components/BlockRelink'


    import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

    export default {
        props: {
            edit: {
                type: Boolean,
                default: false
            }
        },
        components: {
            PostData,
            PostDescription,
            ContactsForm,
            Socials,
            ContactDescription,
            SocialDescription,
            WriteUs,
            Services,
            BlockRelink
        },
        data() {
            return {
                editInfo: {
                    info: {},
                    description: {}
                },
                info: {},
                description: {},
                startLoading: false,
                languages: [],

            }
        },
        async beforeCreate() {
            await axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                });

            await axios.get('admin/setting/footer')
                .then(({data}) => {
                    try {
                        this.editInfo.info = data.data.value.info || {};
                        this.editInfo.description = data.data.value.description || {};
                    }catch (e) {

                    }
                    this.startLoading = true;
                })
        },
        computed: {}
        ,
        methods: {
            getDescriptionByKey(langID, key) {
                langID = "lang_" + langID;
                var response = {};
                try {
                    response = this.editInfo.description[langID][key];
                } catch (e) {
                    response = {};
                }

                return response;
            },
            publish(e) {
                addBtnLoading(e)
                this.errors = [];
                axios.put('admin/setting/footer', {
                        key: 'footer',
                        value: {
                            info: this.info,
                            description: this.description
                        }
                    }
                ).then(({data}) => {

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)
                        this.loading = false;
                        this.errors = error.response.data.errors
                        window.notification.error(this.errorMessage);
                        console.log('--------------------------');
                        console.log(error.response);
                        console.log('--------------------------');
                    });
            },
            setInfo(e) {
                this.info[e.key] = this.info[e.key] || {};
                this.info[e.key] = e.value;
            },
            updateInfo(lang_id, e) {
                lang_id = "lang_" + lang_id;
                this.description[lang_id] = this.description[lang_id] || {};
                this.description[lang_id][e.key] = this.description[lang_id][e.key] || {};
                this.description[lang_id][e.key] = e.value;
            },
            getPropsByKey(key) {
                if (this.postForEditing && this.postForEditing.hasOwnProperty('description')) {
                    let description = _.find(this.postForEditing.description, (o) => o.language_id == key);
                    return description ? description : {}
                }
                return {}
            }
            ,
            getClassForTitleTab(index) {
                var hasLangError = false;
                for (let cur in this.errors) {
                    if (cur.indexOf(`post_description.${index}`) !== -1) {
                        hasLangError = true;
                        break;
                    }
                }

                if (hasLangError) {
                    return 'has-error'
                }

                return '';
            },
            setGeneralData($event) {
                for (let property in $event) {
                    this.post[property] = $event[property]
                }
            }
        }
        ,
        watch: {}
    }
</script>


<style>
    .tab-pane {
        outline: none;
    }

    .has-error {
        background: #dc3545;
    }

    .has-error a {
        color: #ffffff;
    }
</style>
