<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">КОМАНДА</h5>
            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Заголовок</label>
                            <b-form-input
                                    v-model="heading"
                                    :state="localErrors('heading').length == 0 ? null : false"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('heading'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Текст</label>
                            <b-form-textarea
                                    v-model="text"
                                    :state="localErrors('text').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('text'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <div role="group">
                        <div role="group">
                            <label>Должности (каждая должность с новой строки)</label>
                            <b-form-textarea
                                    v-model="categories"
                                    :state="localErrors('categories').length == 0 ? null : false"
                                    rows="5"
                                    max-rows="6"
                            ></b-form-textarea>
                            <b-form-invalid-feedback id="input-live-quotes">
                                {{_.head(localErrors('categories'))}}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <h5 slot="header" class="card-title">Команда</h5>
                </div>
                <div class="col-md-12">
                    <p style="display: block" class="invalid-feedback" v-if="localErrors('commands').length">
                        {{_.head(localErrors('commands'))}}</p>
                    <table class="table table-hover table-bordered table-striper">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tr v-for="(element,index) in commands" :key="element.id">
                            <td>{{index+1}}</td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Имя</label>
                                        <b-form-input
                                                v-model="commands[index].name"
                                                :state="localErrors(`commands.${index}.name`).length == 0 ? null : false"
                                                rows="5"
                                                max-rows="6"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-live-quotes">
                                            {{_.head(localErrors('commands.'+index+'.name'))}}
                                        </b-form-invalid-feedback>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Должность</label>
                                        <b-form-input
                                                v-model="commands[index].position"
                                                :state="localErrors(`commands.${index}.position`).length == 0 ? null : false"
                                                rows="5"
                                                max-rows="6"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-live-quotes">
                                            {{_.head(localErrors('commands.'+index+'.position'))}}
                                        </b-form-invalid-feedback>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Отделы</label>
                                        <b-form-select multiple v-model="commands[index].categories"
                                                       :options="categories.split('\n')"></b-form-select>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="">Изображение</label>
                                        <div class="input-group">
                                            <div class="input-group-btn">
                                                <a class="btn btn-info" @click="openFileManager('',index)">
                                                    <i class="fa fa-picture-o"></i> Choose
                                                </a>
                                            </div>
                                            <input readonly type="text"
                                                   :class="{'is-invalid':localErrors(`commands.${index}.image`).length > 0 }"
                                                   class="form-control" v-model="commands[index].image">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <img style="max-width: 100%" class="mt-3" v-if="commands[index].image"
                                             :src="commands[index].image"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button class="btn-sm btn btn-fill btn-warning pull-right"
                                        @click="removeFormCommand(index)">-
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <button class="btn-sm btn btn-fill btn-success pull-right" @click="addToCommand">+</button>
                </div>
            </div>


        </card>
    </div>

</template>

<script>
    import MetaDescriptionMixin from './../../Clients/mixins/MetaDescriptionMixin'

    export default {
        mixins: [MetaDescriptionMixin],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            var command_screen = (_.find(this.relationMeta, (o) => o.meta_key == 'command_screen')).meta_value || '';

            this.heading = command_screen.heading || '';
            this.text = command_screen.text || '';
            this.categories = command_screen.categories
            this.commands = command_screen.commands || []
        },
        watch: {
            categories() {
                _.map(this.commands, (item, index) => {
                    _.map(item.categories, (i, ind) => {
                        if (this.categories.split('\n').indexOf(i) === -1) {
                            this.commands[index].categories.splice(ind, 1);
                        }
                    })
                });
            },
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                heading: '',
                text: '',
                categories: '',
                commands: []
            }
        },
        methods: {
            addToCommand() {
                this.commands.push({
                    id: (new Date).getTime(),
                    name: '',
                    position: '',
                    categories: [],
                    image: ''
                })
            },
            removeFormCommand(id) {

            },
            openFileManager(param, id) {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                window.SetUrl = (items) => {
                    if (id !== undefined) {
                        this.commands[id].image = items.replace(window.location.origin, '')
                    } else {
                        this[param] = items.replace(window.location.origin, '')
                    }

                }

                return false
            },
            localErrors(key) {
                return this.errors(`command_screen.${key}`);
            },
            passToParrentData(value) {
                this.$emit('updateMeta', {
                    key: 'command_screen',
                    value: {
                        heading: this.heading,
                        text: this.text,
                        categories: this.categories,
                        commands : this.commands
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
