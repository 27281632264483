<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-md-12" style="text-align: right">
                    <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
                                                            <span class="spinner-border d-none spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                        <span class="">Сохранить</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <b-tabs>
                            <b-tab v-for="language in languages"
                                   :key="language.id" :title="language.name">

                                <card>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <base-input type="text"
                                                            label="Название категории"
                                                            placeholder="Введите Название"
                                                            :inputClass="_.has(errors,'description.'+language.id+'.name') ? 'is-invalid' : ''"
                                                            v-model="category[language.id].name">
                                                    <template v-slot:error_message>
                                                        <div class="invalid-feedback"
                                                             v-if="_.has(errors,'description.'+language.id+'.name')">
                                                            {{_.head(errors['description.'+language.id+'.name'])}}
                                                        </div>
                                                    </template>
                                                </base-input>
                                            </div>
                                        </div>
                                    </form>
                                </card>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Card from './../../../../components/Cards/Card.vue'
    import ErrorMixin from '../../../mixim/UserMixin'
    import {mapGetters} from 'vuex'
    import {addBtnLoading, removeBtnLoading} from './../../../../helpers/btnloading'

    export default {
        props: ['id'],
        mixins: [ErrorMixin],
        data() {
            return {
                paramsSave: {
                    method: 'post',
                    url: 'admin/vacancy/category'
                },
                languages: [],
                isAdmin: false,
                category: {},
                errors: []
            }
        },
        computed: {

        },
        components: {
            Card
        },
        methods: {
            setEditFunc() {
                this.paramsSave = {
                    method: 'put',
                    url: `admin/vacancy/category/${this.id}`
                };

                axios.get(`admin/vacancy/category/${this.id}`)
                    .then(({data}) => {
                        if (data.data.description.length || typeof data.data.description == 'object') {
                            _.map( data.data.description,(o) => {
                                if(o.language_id){
                                    this.category[o.language_id].name = o.name;
                                }
                            })

                        }
                    })
            },
            save(e) {
                addBtnLoading(e)
                this.errors = [];
                axios[this.paramsSave.method](this.paramsSave.url, {
                        description: this.category
                    }
                ).then(({data}) => {


                    if(data.data.id && this.paramsSave.method == 'post'){
                        this.paramsSave.method = 'put'
                        this.paramsSave.url = this.paramsSave.url + this.id || data.data.id
                        this.$router.push({name : 'vacancy-category-edit',params : {id : data.data.id}})
                    }

                    removeBtnLoading(e)
                    window.notification.success('Сохранение прошло успешно');

                })
                    .catch(error => {
                        removeBtnLoading(e)

                        this.loading = false;
                        this.errorMessage = error.response.data.message
                        this.errors = error.response.data.errors
                        window.notification.error(this.errorMessage);

                    });
            }
        },
        mounted() {
            if (this.id > 0) {
                this.setEditFunc();
            }
        },
        beforeCreate() {
            axios.get('/languages',)
                .then(({data}) => {
                    this.languages = data.data;
                    _.map(data.data, (i) => {
                        this.$set(this.category, i.id, {
                            name: '',
                        })
                    })
                });

        }

    }

</script>
<style>

</style>
