<template>
    <div>
        <card>
            <h5 slot="header" class="card-title">Баннер</h5>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <a class="btn btn-info" @click="openFileManager">
                                <i class="fa fa-picture-o"></i> Choose
                            </a>
                        </div>
                        <input type="text" readonly :class="{'is-invalid':errors('banner').length > 0 }"
                               class="form-control" v-model="banner">
                    </div>
                    <img style="width: 100%" class="mt-3" :src="banner"/>
                </div>
                <div class="col-md-12">
                    <div role="group">
                        <label for="position">Подпись баннера:</label>
                        <b-form-input
                            id="position"
                            v-model="inscript"
                            :state="errors('inscript').length == 0 ? null : false"
                            aria-describedby="input-help input-live-position"
                            placeholder="Введите подпись фотографии"
                            trim
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-quotes">
                            {{_.head(errors('inscript'))}}
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    export default {
        mixins: [],
        props: {
            relationMeta: {
                type: [Object]
            }
        },
        created() {
            let banner  = (_.find(this.relationMeta,(o) => o.meta_key == 'banner')).meta_value || {}
            this.banner = banner.banner || ''
            this.inscript = banner.inscript || ''
        },
        watch: {
            '$data': {
                handler(value) {
                    this.passToParrentData(value);
                },
                deep: true
            }
        },
        data() {
            return {
                banner: null,
                inscript: null,
                passible: [
                    'main_image', 'inscript'
                ]
            }
        },
        methods: {
            errors(e){
                return ''
            },
            passToParrentData(value) {
                let pass = {};
                for (var cur in value) {
                    if (this.passible.indexOf(cur) !== -1) {
                        pass[cur] = value[cur];
                    }
                }
                this.$emit('updateMeta', {
                    key: 'banner',
                    value: {
                        banner: this.banner,
                        inscript: this.inscript
                    }
                })
            },
            openFileManager() {
                var params = {
                    type: 'Images'
                };

                var url = ['/laravel-filemanager', $.param(params)].join('?');

                window.open(
                    url,
                    "DescriptiveWindowName",
                    "width=700,height=700,resizable,scrollbars=yes,status=1"
                );

                var self = this
                window.SetUrl = function (items) {
                    self.banner = items.replace(window.location.origin, '')
                }
                return false
            }
        }
    }
</script>

<style scoped>

</style>
